import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {AssetHuman} from '../../interfaces/AssetHuman';
import AdminAssetHumanItemUpdate from '../admin/AdminAssetHumanItemUpdate';
import AssetHumanItemUpdate from './AssetHumanItemUpdate';
import AssetHumanItemView from './AssetHumanItemView';

interface Props {
  pk: number;
  item?: AssetHuman;
  mode?: 'view' | 'update' | 'chat' | 'update_from_info';
  prefetch?: boolean;
  isAdminSection?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (item: AssetHuman) => void;
}

const AssetHumanItem = ({
  pk,
  item,
  mode: initialMode = 'view',
  prefetch,
  isAdminSection,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  const [mode, setMode] = useState(initialMode);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<AssetHuman | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<AssetHuman>(`${apiBaseUrl}/asset-human/${pk}`);
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (item && !isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <PersonIcon sx={{color: 'primary.main'}} />
          {fetchedData ? <Box fontSize={24}>{fetchedData.nickname}</Box> : null}
        </Box>
        <Box display="flex" gap={0.5}>
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
          {onClose ? (
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      {mode === 'update' || mode === 'update_from_info' ? (
        isAdminSection
          ? <AdminAssetHumanItemUpdate
              mode={mode}
              pk={pk}
              item={fetchedData}
              prefetch={!prefetch}
              onCancel={() => {
                mode === 'update' ? onClose?.() : setMode('view');
              }}
              onSubmitted={() => {
                onSubmitted?.();
                mode === 'update' ? onClose?.() : setMode('view');
                fetchData();
              }}
            />
          : <AssetHumanItemUpdate
              mode={mode}
              pk={pk}
              item={fetchedData}
              prefetch={!prefetch}
              onCancel={() => {
                mode === 'update' ? onClose?.() : setMode('view');
              }}
              onSubmitted={() => {
                onSubmitted?.();
                mode === 'update' ? onClose?.() : setMode('view');
                fetchData();
              }}
            />
      ) : (
        <AssetHumanItemView
          pk={pk}
          item={fetchedData}
          tab={mode === 'chat' ? 'chat' : 'general'}
          prefetch={!prefetch}
          onCancel={onClose}
          onEdit={() => setMode('update_from_info')}
          onPurged={onPurged}
          onFetched={setFetchedData}
          onOpenHistory={onOpenHistory}
          onSubmitted={() => {
            onSubmitted?.();
            fetchData();
          }}
        />
      )}
    </Box>
  );
};

export default AssetHumanItem;
