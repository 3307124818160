
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useImperativeHandle,
} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';

export interface BeltStatusProps {
  onSubmitted?: () => void;
}

export interface BeltStatusRef {
  submit: (pk: number, status: string, miscNum: number) => void;
}

export const BeltNodeItemUpdateStatus = forwardRef<
  BeltStatusRef,
  React.PropsWithChildren<BeltStatusProps>
>(({onSubmitted}, ref) => {
  const {enqueueSnackbar} = useSnackbar();

  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    submit: (pk, status, miscNum) => submitData(pk, status, miscNum)
  }));

  /**********/
  /* submit */
  /**********/
  const submitData = async (pk: number, status: string, miscNum: number) => {
    try {
      const endpoint = `${apiBaseUrl}/belt/${pk}/misc-output`;
      const data = {
        index: miscNum,
        enable: status === 'Start'
      }
      await API.patch(endpoint, data);
      const message = `Belt Misc Output${miscNum} successfully ${status === 'Start' ? 'started.' : 'stopped.'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
  };

  return null;
});
