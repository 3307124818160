import * as yup from 'yup';

import { container_name, name } from '../../utils/regex';

export const gatewayInputSchema = yup.object().shape({
  name: yup
    .string()
    .required('Field is required')
    .min(1, 'The minimum value for this field is 1')
    .matches(name.regex, name.message),
  container_name: yup
    .string()
    .required('Field is required')
    .matches(container_name.regex, container_name.message),
  period: yup.number().nullable().required('Field is required'),
  mqtt_port: yup.number().nullable().required('Field is required'),
  mqtt_protocol: yup.string().nullable().required('Field is required'),
  mqtt_host: yup.string().nullable().required('Field is required'),
  sensors: yup.array().of(
    yup.object().shape({
      port_1: yup.string().required('Required'),
      ip_address: yup.string().required('Required'),
      port_2: yup.string().required('Required')
    })
  )
});
