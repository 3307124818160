import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {AlarmLogNode} from '../../interfaces/AlarmLogNode';
import AccessControl from '../common/AccessControl';
import AlarmItemActivationModal from './AlarmItemActivationModal';
import AlarmItemPurgeModal from './AlarmItemPurgeModal';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item?: AlarmLogNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
}
const PurgeActionTypes = {
  purgeDevice: 'device',
  purgeData: 'data',
} as const;
type Action = 'activation' | 'purgeDevice' | 'purgeData';

const AlarmItemActionsButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [action, setAction] = useState<Action>();
  const isPurgeAction = action === 'purgeDevice' || action === 'purgeData';
  const purgeType = isPurgeAction ? PurgeActionTypes[action] : null;

  const checkMenuContent = () => {
    const menuItemCount = document.querySelectorAll('.alarm_menu_list li').length;
    if (menuItemCount === 0) {
      const menuElements = document.getElementsByClassName('alarm_menu_list');
      for (let i = 0; i < menuElements.length; i++) {
        const element = menuElements[i] as HTMLElement;
        element.style.display = 'none';
      }
    }
  }

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
          setTimeout(checkMenuContent, 0);
        }}
      >
        {children}
      </Component>

      <Menu
        className="alarm_menu_list"
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <AccessControl roles={[1,2,3]}>
          <MenuItem
            onClick={() => {
              setAction('activation');
              setAnchorEl(null);
            }}
          >
            {item?.status === 'active' ? (
              <>
                <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                DeActivate
              </>
            ) : (
              <>
                <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                Activate
              </>
            )}
          </MenuItem>
        </AccessControl>
        {item?.id && (
          <AccessControl permissions={['post::/purge']}>
            <MenuItem
              onClick={() => {
                setAction('purgeDevice');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Device
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAction('purgeData');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Transaction
            </MenuItem>
          </AccessControl>
        )}
      </Menu>

      {action === 'activation' && item ? (
        <AlarmItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : purgeType ? (
        <AlarmItemPurgeModal
          item={item}
          action={purgeType}
          open={!!purgeType}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}
    </>
  );
};

export default AlarmItemActionsButton;
