import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {LoadingButton} from '@mui/lab';
import {
  IconButton,
  Tooltip
} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useConfiguration} from '../../hooks/configuration';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ConfigurationResponse} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {getMergedConfigurations} from '../../utils/configuration';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

const soundConfigSection = 'global';
const soundConfigName = 'sound';

const SoundManager = () => {
  const reduxDispatch = useAppDispatch();
  const myConfigurations = useAppSelector(
    (state) => state.app.myConfigurations
  );
  const [soundConfiguration, setSoundConfiguratoin] = useState<any>(useConfiguration(
    soundConfigSection,
    soundConfigName
  ));

  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const handleToggle = async () => {
    setSubmittedInProgress(true);
    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        {
          section: ['global'],
          configuration: [
            {
              name: soundConfigName,
              section: soundConfigSection,
              value: soundConfiguration?.value === "1" ? "0" : "1",
            },
          ],
        }
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
      reduxActions.app.setMuteSounds(reduxDispatch, resp.data[resp.data.length - 1].value === "0");
      setSoundConfiguratoin(resp.data.find((it) => it.name === soundConfigName));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  return (
    <LoadingButton
      color="primary"
      loading={submittedInProgress}
      sx={{minWidth: 'auto', borderRadius: '50%', aspectRatio: '1'}}
      onClick={handleToggle}
    >
      {soundConfiguration?.value === "0" ? (
        <Tooltip title="Sounds enable">
          <IconButton color="error">
            <VolumeOffIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Sounds disable">
          <IconButton color="success">
            <VolumeUpIcon />
          </IconButton>
        </Tooltip>
      )}
    </LoadingButton>
  );
};

export default SoundManager;
