import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useMemo} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  BeltLocationHistory,
  getBeltLocationHistoryReportData,
} from './BeltLocationHistoryReport';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

export interface BeltLocationTrackingReportData {
  viewType: 'beltLocationHistoryReport';
  title: string;
  beltLocationHistoryReport: any;
}

const getBeltLocationTrackingReports = (): BeltLocationTrackingReportData => ({
  viewType: 'beltLocationHistoryReport',
  title: 'Belt Location History Report',
  beltLocationHistoryReport: getBeltLocationHistoryReportData(),
});

export const BeltLocationHistoryReport = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as BeltLocationTrackingReportData)
        : getBeltLocationTrackingReports(),
    [value]
  );

  return (
    <BeltLocationHistory
      value={config.beltLocationHistoryReport}
      onUpdate={(v) => {
        onUpdate?.(
          update(value ?? getBeltLocationTrackingReports(), {
            beltLocationHistoryReport: {
              $set: v ?? getBeltLocationHistoryReportData(),
            },
          })
        );
      }}
    />
  );
};
