import * as yup from 'yup';

export const beltBaseInputSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required').min(1).max(50),
  status: yup.string().nullable().required('Field is required'),
  commtrac_external_id: yup.number().nullable().required('Field is required'),
  zone_id: yup.number().nullable().required('Field is required'),
  latitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
});

export const beltAckInputSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required').min(1).max(50),
  status: yup.string().nullable().required('Field is required'),
  network_id: yup.number().nullable().required('Field is required'),
  zone_id: yup.number().nullable().required('Field is required'),
  latitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
});

export const beltNetworkdIDInputSchema = yup.object().shape({
  commtrac_external_id: yup.number().nullable().required('Field is required'),
});

export const beltInputSchema = yup.object().shape({
  serial_number: yup.number().nullable().required('Field is required'),
  date_calibration: yup.string().nullable().required('Field is required'),
  calibration_value: yup.string().nullable().required('Field is required'),
  // commtrac_external_id: yup.number().nullable().required('Field is required'),
  // date_installation: yup.string().nullable().required('Field is required'),
  type: yup.string().nullable().required('Field is required'),
  status: yup.string().nullable().required('Field is required'),
});

export const beltEditSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required').min(1).max(50),
  status: yup.string().nullable().required('Field is required'),
  network_id: yup.number().nullable().required('Field is required'),
  zone_id: yup.number().nullable().required('Field is required'),
  reporting_frequency: yup
    .number()
    .nullable(),
  location_frequency: yup
    .number()
    .nullable(),
  latitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
});
