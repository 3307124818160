import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {GasMonitoringNode} from '../../../interfaces/GasMonitoringNode';
import AccessControl from '../../common/AccessControl';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  setViewType?: Function;
  onOpenItem?: Function;
  onOpenHistory?: Function;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  item?: GasMonitoringNode;
}

const AMSSensorItemEditButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  onOpenItem,
  onOpenHistory,
  item,
}: // onPurged,
Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onOpenHistory?.(item?.id, 'amsShortTerm');
            setAnchorEl(null);
          }}
        >
          Short Term History Report
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenHistory?.(item?.id, 'amsLongTerm');
            setAnchorEl(null);
          }}
        >
          Long Term History Report
        </MenuItem>
        <AccessControl permissions={['patch::/ams/:id(\\d+)']}>
          <MenuItem
            onClick={() => {
              onOpenItem?.(item?.id, 'ams', 'update');
              setAnchorEl(null);
            }}
          >
            Edit Sensor
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              onOpenItem?.(item?.id, 'ams', 'update_networkd_id');
              setAnchorEl(null);
            }}
          >
            Edit Network ID
          </MenuItem> */}
        </AccessControl>
        <MenuItem
          onClick={() => {
            onOpenItem?.(item?.id, 'ams', 'view');
            setAnchorEl(null);
          }}
        >
          Info & Actions
        </MenuItem>
      </Menu>
    </>
  );
};

export default AMSSensorItemEditButton;
