import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {saveFile} from '../../utils/file';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

const BeltExport = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);
  const fetchData = async () => {
    setIsFetching(true);
    try {
      const resp = await API.get(`${apiBaseUrl}/belt.csv`, {
        responseType: 'blob',
      });
      saveFile(resp.data, `belt-${dayjs().format('YYYY-MM-DD')}.csv`);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => fetchData()}
        >
          Export as CSV
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default BeltExport;
