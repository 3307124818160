import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import {closeSnackbar, enqueueSnackbar, SnackbarProvider} from 'notistack';
import {useCallback, useEffect, useMemo} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {CloseSnackbarButton} from './components/common/CloseSnackbarButton';
import SnackbarMessages from './components/common/SnackbarMessages';
import {useAppDispatch, useAppSelector} from './hooks/redux';
import {useRefreshInterval} from './hooks/refreshInterval';
import {AdminCompanySettingsPage} from './pages/admin/AdminCompanySettingsPage';
import {AdminGeoFencePage} from './pages/admin/AdminGeoFencePage';
import {AdminHelpPage} from './pages/admin/AdminHelpPage';
import {AdminProductsPage} from './pages/admin/AdminProductsPage';
import AdminProximityImportListPage from './pages/admin/AdminProximityImportListPage';
import AdminUserListPage from './pages/admin/AdminUserListPage';
import {LicenseCheckPage} from './pages/auth/LicenseCheckPage';
import {PasswordResetPage} from './pages/auth/PasswordResetPage';
import {PasswordResetRequestPage} from './pages/auth/PasswordResetRequestPage';
import {SigninPage} from './pages/auth/SigninPage';
import DashboardItemPage from './pages/dashboards/item';
import DashboardListPage from './pages/dashboards/list';
import {IndicatorPanelContextProvider} from './pages/indicator-panel/contexts';
import {IndicatorPanel} from './pages/indicator-panel/IndicatorPanel';
import {IndicatorWidgetEditor} from './pages/indicator-panel/IndicatorWidgetEditor';
import DashboardPanelItemPage from './pages/panels/item';
import DashboardPanelListPage from './pages/panels/list';
import SubscriptionListPage from './pages/subscriptions/list';
import reduxActions from './redux/actions';
import reduxSelectors from './redux/selectors';

export const App = () => {
  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          h2: {
            fontSize: isCompactMode ? 20 : 40,
            fontWeight: isCompactMode ? 400 : 600,
          },
          body2: {
            //fontSize: isCompactMode ? 12 : 14,
          },
        },
        palette: {
          mode: isDarkMode ? 'dark' : 'light',
          primary: {
            main: isDarkMode ? '#90caf9' : '#185A7D',
            // menuBg: isDarkMode ? 'inherit' : '#185A7D',
          },
          secondary: {
            main: '#666666',
          },
        },
        components: {
          MuiBackdrop: {
            styleOverrides: {
              root: {
                zIndex: 3,
                backgroundColor: isDarkMode ? '#44444490' : '#00000080',
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                '&.MuiInputLabel-shrink': {
                  fontWeight: 700,
                },
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              root: {
                backgroundColor: isDarkMode ? '#44444490' : '#00000080',
              },
              paper: ({theme}) => ({
                border: '1px solid #9b9b9b',
                backgroundColor: theme.palette.background.paper,
                backgroundImage: 'none',
              }),
            },
          },
          MuiToolbar: {
            defaultProps: {
              variant: isCompactMode ? 'dense' : 'regular',
            },
          },
          MuiTable: {
            defaultProps: {
              size: isCompactMode ? 'small' : 'medium',
            },
            styleOverrides: {
              root: {
                '&.MuiTable-stickyHeader': {
                  thead: {
                    th: {
                      '&.MuiTableCell-stickyHeader': {
                        top: -1,
                      },
                      p: {
                        marginTop: isCompactMode ? 0 : 10,
                        marginBottom: isCompactMode ? 0 : 10,
                      },
                      svg: {
                        height: isCompactMode ? 16 : 24,
                      },
                    },
                  },
                  tbody: {
                    tr: {
                      td: {
                        p: {
                          marginTop: isCompactMode ? 0 : 10,
                          marginBottom: isCompactMode ? 0 : 10,
                        },
                        svg: {
                          height: isCompactMode ? 16 : 24,
                          fontSize: isCompactMode ? 16 : 24,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }),
    [isDarkMode, isCompactMode]
  );

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboards/primary');
    }
  }, [location.pathname]);

  const reduxDispatch = useAppDispatch();
  useEffect(() => {
    reduxDispatch(reduxActions.app.pollBackend);
  }, []);

  /****************/
  /* auto refresh */
  /****************/
  const pollBackend = useCallback(() => {
    reduxDispatch(reduxActions.app.pollBackend);
  }, []);
  useRefreshInterval(pollBackend, 10000);

  const backend = useAppSelector((app) => app.app.backend);
  useEffect(() => {
    if (backend?.messages?.length) {
      backend?.messages.forEach((el) => {
        enqueueSnackbar(<SnackbarMessages messages={[el.message]} />, {
          variant: el.type,
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
      });
    }
  }, [backend?.messages]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes>
          <Route path="/auth/signin" element={<SigninPage />} />
          <Route
            path="/auth/password-reset"
            element={<PasswordResetRequestPage />}
          />
          <Route
            path="/auth/password-reset/:token"
            element={<PasswordResetPage />}
          />
          <Route path="/dashboards" element={<DashboardListPage />} />
          <Route path="/dashboards/:id" element={<DashboardItemPage />} />
          <Route path="/panels" element={<DashboardPanelListPage />} />
          <Route path="/panels/:code" element={<DashboardPanelItemPage />} />
          <Route path="/indicator" element={<IndicatorPanelContextProvider />}>
            <Route path="panel" element={<IndicatorPanel />} />
            <Route path="widget" element={<IndicatorWidgetEditor />} />
          </Route>
          <Route path="/subscriptions" element={<SubscriptionListPage />} />
          <Route path="/admin/products" element={<AdminProductsPage />} />
          <Route path="/admin/users" element={<AdminUserListPage />} />
          <Route
            path="/admin/company-settings"
            element={<AdminCompanySettingsPage />}
          />
          <Route
            path="/admin/proximity-import"
            element={<AdminProximityImportListPage />}
          />
          <Route path="/admin/geo-fence" element={<AdminGeoFencePage />} />
          <Route path="/admin/help" element={<AdminHelpPage />} />
          <Route path="/license-check" element={<LicenseCheckPage />} />
        </Routes>
      </ThemeProvider>
    </SnackbarProvider>
  );
};
