import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';
import {BeltLocationHistoryReportDataTab} from './BeltLocationHistoryReport';

interface Props {
  value?: BeltLocationHistoryReportDataTab | null;
  commtrac_external_id?: number;
}

const BeltLocationHistoryReportExportExcel = ({
  value,
  commtrac_external_id,
}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const exportFieldOptions = [
    {field: 'date', label: 'Timestamp', hidden: false},
    {field: 'pos_lon', label: 'Lon', hidden: false},
    {field: 'pos_lat', label: 'Lat', hidden: false},
    {field: 'beacon_data', label: 'Beacon Data', hidden: false},
    {
      field: 'error_ellipse_major_radius',
      label: 'Error Ellipse Major Radius',
      hidden: false,
    },
    {
      field: 'error_ellipse_minor_radius',
      label: 'Error Ellipse Minor Radius',
      hidden: false,
    },
    {
      field: 'error_ellipse_orientation',
      label: 'Error Ellipse Orientation',
      hidden: false,
    },
  ];

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);

    try {
      const params = {
        export: 'excel',
        fields,
        date_start: value?.params?.date_start,
        date_end: value?.params?.date_end,
        order: value?.params?.order,
        dir: value?.params?.dir,
      };

      const resp = await API.get(
        `${apiBaseUrl}/belt/${commtrac_external_id}/location`,
        {
          params,
          responseType: 'blob',
        }
      );
      saveFile(
        resp.data,
        `belt_${commtrac_external_id}_location_history_${params.date_start ?? dayjs().format('YYYY-MM-DD')}_${params.date_end ?? dayjs().format('YYYY-MM-DD')}.xlsx`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <FileDownloadIcon onClick={() => checkFields()}></FileDownloadIcon>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={exportFieldOptions}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={3}
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default BeltLocationHistoryReportExportExcel;
