import {GasMonitoringNode} from '../../../interfaces/GasMonitoringNode';
import {MapLatLangCoordinates} from '../../common/Map';
import AMSSensorItemCreate from './AMSSensorItemCreate';
import AMSSensorNodeItem from './AMSSensorNodeItem';

interface Props {
  pk?: number;
  item?: GasMonitoringNode;
  mode?: 'view' | 'update' | 'ack' | 'active';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'amsShortTerm' | 'amsLongTerm' | 'amsLocation'
  ) => void;
}

const AMSSensorItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  locationCoordinates,
  isActiveModal,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <AMSSensorNodeItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      locationCoordinates={locationCoordinates}
      isActiveModal={isActiveModal}
      onClose={onClose}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
      onOpenHistory={onOpenHistory}
    />
  ) : (
    <AMSSensorItemCreate
      locationCoordinates={locationCoordinates}
      isActiveModal={isActiveModal}
      onCancel={onClose}
      onSubmitted={onSubmitted}
    />
  );
};

export default AMSSensorItemUpsert;
