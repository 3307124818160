import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';
import { useMemo } from 'react';

import { CompanyProduct } from '../../interfaces/Company';
import { Products } from './CompanyItemUpsert';

interface Props {
  formik: any,
  productsSelected: Products
  onSelectProduct: (selected: boolean, product: CompanyProduct) => void,
  onChangeProduct: (selected: boolean, product: CompanyProduct) => void,
}

export const CompanyProducts = ({
  formik,
  productsSelected,
  onSelectProduct,
  onChangeProduct
}: Props) => {
  const memoizedProductsSelected = useMemo(() => productsSelected, [productsSelected]);
  return (
    <Box my={4}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Connect enabled"
                disabled={
                  !!formik.values.hazard_ai_enabled &&
                  !formik.values.commtrac_enabled
                }
                control={
                  <Switch
                    checked={!!formik.values.commtrac_enabled}
                    onChange={(event, value) => {
                      if (value === true) {
                        formik.setFieldValue('hazard_ai_enabled', false);
                      }
                      if (value === false) {
                        formik.setFieldValue('alarm_enabled', false);
                        formik.setFieldValue('ams_enabled', false);
                        formik.setFieldValue('belt_enabled', false);
                      }
                      formik.setFieldValue('commtrac_enabled', value);
                      onChangeProduct(event.target.checked, "commtrac_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.commtrac_enabled  && !memoizedProductsSelected["commtrac_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["commtrac_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "commtrac_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="HazardAI enabled"
                disabled={
                  !!formik.values.commtrac_enabled &&
                  !formik.values.hazard_ai_enabled
                }
                control={
                  <Switch
                    checked={!!formik.values.hazard_ai_enabled}
                    onChange={(event, value) => {
                      if (value === true) {
                        formik.setFieldValue('commtrac_enabled', false);
                        formik.setFieldValue('ventilation_enabled', false);
                        formik.setFieldValue('shaft_enabled', false);
                      }
                      formik.setFieldValue('hazard_ai_enabled', value);
                      onChangeProduct(event.target.checked, "hazard_ai_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.hazard_ai_enabled  && !memoizedProductsSelected["hazard_ai_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["hazard_ai_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "hazard_ai_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Gas Monitoring enabled"
                disabled={!formik.values.commtrac_enabled}
                control={
                  <Switch
                    checked={!!formik.values.ams_enabled}
                    onChange={(event, value) => {
                      formik.setFieldValue('ams_enabled', value);
                      onChangeProduct(event.target.checked, "ams_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.ams_enabled  && !memoizedProductsSelected["ams_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["ams_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "ams_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Alarm Module enabled"
                disabled={!formik.values.commtrac_enabled}
                control={
                  <Switch
                    checked={!!formik.values.alarm_enabled}
                    onChange={(event, value) => {
                      formik.setFieldValue('alarm_enabled', value);
                      onChangeProduct(event.target.checked, "alarm_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.alarm_enabled  && !memoizedProductsSelected["alarm_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["alarm_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "alarm_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Proximity enabled"
                control={
                  <Switch
                    checked={!!formik.values.proximity_enabled}
                    onChange={(event, value) => {
                      formik.setFieldValue('proximity_enabled', value);
                      onChangeProduct(event.target.checked, "proximity_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.proximity_enabled  && !memoizedProductsSelected["proximity_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["proximity_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "proximity_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Shaft Clearence enabled"
                disabled={formik.values.hazard_ai_enabled || !formik.values.commtrac_enabled}
                control={
                  <Switch
                    checked={!!formik.values.shaft_enabled}
                    onChange={(event, value) => {
                      formik.setFieldValue('shaft_enabled', value);
                      onChangeProduct(event.target.checked, "shaft_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.shaft_enabled  && !memoizedProductsSelected["shaft_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["shaft_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "shaft_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Belt Monitoring enabled"
                disabled={!formik.values.commtrac_enabled}
                control={
                  <Switch
                    checked={!!formik.values.belt_enabled}
                    onChange={(event, value) => {
                      formik.setFieldValue('belt_enabled', value);
                      onChangeProduct(event.target.checked, "belt_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.belt_enabled  && !memoizedProductsSelected["belt_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["belt_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "belt_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Ventilation enabled"
                disabled={formik.values.hazard_ai_enabled}
                control={
                  <Switch
                    checked={!!formik.values.ventilation_enabled}
                    onChange={(event, value) => {
                      formik.setFieldValue('ventilation_enabled', value);
                      onChangeProduct(event.target.checked, "ventilation_enabled");
                    }}
                  />
                }
              />
            </Grid>
            {(!!formik.values.ventilation_enabled  && !memoizedProductsSelected["ventilation_enabled"]) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox checked={memoizedProductsSelected["ventilation_enabled"]} onChange={(e) => onSelectProduct(e.target.checked, "ventilation_enabled")}/>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
