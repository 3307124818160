import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  open: boolean,
  pk: number,
  status: string;
  onSubmitted?: () => void;
  onClose?: () => void;
}


const BeltNodeItemUpdateRunning = ({
  pk,
  status,
  onSubmitted,
  onClose,
  ...props
}: Props) => {

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async () => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/belt/${pk}/${status.toLowerCase()}`;
      await API.patch<BeltMonitoringNode>(endpoint);
      const message = `Belt successfully ${status === 'Start' ? 'started.' : 'stopped.'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });

      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{status} this Belt</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {status.toLowerCase()} selected belt?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={submittedInProgress}
          autoFocus
          onClick={() => submitData()}
          color={status === 'Start' ? 'success' : 'warning'}
        >
          {status}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BeltNodeItemUpdateRunning;
