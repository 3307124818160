/* eslint-disable complexity */
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Image from 'mui-image';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import EModuleIcon from '../../../images/ams/emodule/icon_emodule.svg';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import TruncatedText from '../../common/TruncantedText';
import AMSSensorNodeItemUpdate from './AMSEModuleNodeItemUpdate';
import AMSSensorNodeItemView from './AMSEModuleNodeItemView';

interface Props {
  pk: number;
  item?: AMSEModuleNode;
  mode?: 'view' | 'update' | 'ack' | 'update_from_info' | 'active';
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (
    externalId: number,
    type: 'amsEmoduleSensorHistory' | 'amsEmoduleCalibration'
  ) => void;
}

const AMSEModuleNodeItem = ({
  pk,
  item,
  mode: initialMode = 'view',
  prefetch,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  const [mode, setMode] = useState(initialMode);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<AMSEModuleNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<AMSEModuleNode>(
        `${apiBaseUrl}/ams/emodule/${pk}`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (item && !isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          overflow="hidden"
          sx={{cursor: 'grab'}}
        >
          <Image src={EModuleIcon} alt="heat map" width={30} height={30} />

          {fetchedData ? (
            <TruncatedText fontSize={24}>
              e-Module [{fetchedData.serial_number}]
            </TruncatedText>
          ) : null}
        </Box>
        <Box display="flex" gap={0.5}>
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
          {onClose ? (
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      {mode === 'view' ? (
        <AMSSensorNodeItemView
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={onClose}
          onEdit={() => setMode('update_from_info')}
          onPurged={onPurged}
          onFetched={setFetchedData}
          onOpenHistory={onOpenHistory}
          onSubmitted={onSubmitted}
        />
      ) : mode === 'update' || mode === 'update_from_info' ? (
        <AMSSensorNodeItemUpdate
          mode={mode}
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={() => {
            mode === 'update' ? onClose?.() : setMode('view');
          }}
          onSubmitted={() => {
            onSubmitted?.();
            mode === 'update' ? onClose?.() : setMode('view');
            fetchData();
          }}
        />
      ) : mode === 'ack' ? (
        <>ACK</>
      ) : null}
    </Box>
  );
};

export default AMSEModuleNodeItem;
