import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {MapLatLangCoordinates} from '../../common/Map';
import BeltNodeItem from './BeltNodeItem';
import BeltNodeItemCreate from './BeltNodeItemCreate';

interface Props {
  pk?: number;
  item?: BeltMonitoringNode;
  mode?: 'view' | 'update' | 'ack' | 'active';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'beltHistoryReport' | 'beltLocationHistoryReport'
  ) => void;
}

const BeltNodeItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  locationCoordinates,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <BeltNodeItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      locationCoordinates={locationCoordinates}
      onClose={onClose}
      onOpenHistory={onOpenHistory}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
    />
  ) : (
    <BeltNodeItemCreate
      locationCoordinates={locationCoordinates}
      onCancel={onClose}
      onSubmitted={onSubmitted} />
  );
};

export default BeltNodeItemUpsert;
