import {Box} from '@mui/material';
import Image from 'mui-image';

import spannerR from './spanner_r.svg'
import spannerW from './spanner_w.svg'

interface Props {
  color?: 'red' | 'white';
  size?: number;
}


const Spanner = ({ color = 'red', size = 18 }: Props) => {
  return (
    <Box
        sx={{
          '& img': {transition: 'none !important', animation: 'none !important'},
          transition: 'none !important',
        }}
      >
        {color === 'red' && (
          <Image
            src={spannerR}
            width={size}
            height={size}
          />
        )}
        {color === 'white' && (
          <Image
            src={spannerW}
            width={size}
            height={size}
          />
        )}
      </Box>
  );
};

export default Spanner;
