/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-bitwise */
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Tab,
  TextField,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import { useAppSelector } from '../../../hooks/redux';
import {beltAddressMask, BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {ConfigurationResponse} from '../../../interfaces/Configuration';
import { getBeltNodeStatus } from '../../../utils/belt';
import StatusSelect from '../../selectors/StatusSelect';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import BeltNodeItemActionsButton from './BeltNodeItemActionsButton';

interface Props {
  pk: number;
  item?: BeltMonitoringNode;
  prefetch?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onPurged?: () => void;
  onSubmitted?: () => void;
  onFetched?: (item: BeltMonitoringNode) => void;
  onOpenHistory?: (
    id: number,
    type: 'beltHistoryReport' | 'beltLocationHistoryReport'
  ) => void;
}

type TabValue = 'general' | 'sensing' | 'status';
interface TabItem {
  value: TabValue;
  label: string;
}

const tabs: TabItem[] = [
  {
    value: 'general',
    label: 'General',
  },

  {
    value: 'status',
    label: 'Status',
  },
];

const units = ['%', 'LEL', 'PPM'];

const BeltNodeItemView = ({
  pk,
  item,
  prefetch,
  onCancel,
  onEdit,
  onPurged,
  onFetched,
  onSubmitted,
  onOpenHistory,
}: Props) => {
  const beltData = useAppSelector(({assets}) => assets.belt);

  /*********/
  /* fetch */
  /*********/

  const [activeTab, setActiveTab] = useState('general');

  const [fetchedData, setFetchedData] = useState<
    BeltMonitoringNode | undefined
  >(cloneDeep(item));

  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<BeltMonitoringNode>(
        `${apiBaseUrl}/belt/${pk}`
      );
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  const [fetchedAMSConfiguration, setFetchedAMSConfiguration] =
    useState<ConfigurationResponse>();
  const [fetchedConfigInProgress, setFetchedConfigInProgress] = useState(false);

  const fetchAMSConfiguration = async () => {
    setFetchedConfigInProgress(true);
    try {
      const params = {section: JSON.stringify(['ams', 'ams-color'])};
      const configurationResp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        {params}
      );
      setFetchedAMSConfiguration(configurationResp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    } finally {
      setFetchedConfigInProgress(false);
    }
  };

  useEffect(() => {
    fetchAMSConfiguration();
  }, [pk]);

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}

      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.name ?? ''}
              label="Name"
              name="name"
              size="small"
              fullWidth
              disabled
            />
            <TextField
              value={fetchedData?.commtrac_external_id ? fetchedData?.commtrac_external_id & beltAddressMask : ''}
              label="Network Id"
              size="small"
              disabled
              fullWidth
            />
            <StatusSelect
              value={fetchedData?.status === 'active' ? 'active' : 'inactive'}
              fullWidth
              name="status"
              label="Status"
              size="small"
              disabled
            />
            <ZoneSelect
              value={fetchedData?.zone_id}
              label="Section"
              size="small"
              fullWidth
              disabled
            />
            <TextField
              value={fetchedData?.reporting_frequency ?? ''}
              label="Report Frequency"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.location_frequency ?? ''}
              label="Location Frequency"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.latitude ?? ''}
              label="Latitude"
              size="small"
              fullWidth
              disabled
            />
            <TextField
              value={fetchedData?.longitude ?? ''}
              label="Longitude"
              size="small"
              fullWidth
              disabled
            />
          </Box>
        </TabPanel>
        <TabPanel value="status" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Grid spacing={3} container>
              <Grid item xs={12} lg={12} xl={12}>
                Misc Output Status
              </Grid>
              <Grid item xs={12} lg={6} xl={3}>
                <FormControlLabel
                  sx={{m: 0}}
                  control={
                    <Checkbox
                      disabled
                      name="status"
                      checked={getBeltNodeStatus("Misc Output 1", beltData?.output_bit_label, fetchedData?.output_bits) === '1'}
                    />
                  }
                  label="Output1 :"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} lg={6} xl={3}>
                <FormControlLabel
                  sx={{m: 0}}
                  control={
                    <Checkbox
                      disabled
                      name="status"
                      checked={getBeltNodeStatus("Misc Output 2", beltData?.output_bit_label, item?.output_bits) === '1'}
                    />
                  }
                  label="Output2 :"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} lg={6} xl={3}>
                <FormControlLabel
                  sx={{m: 0}}
                  control={
                    <Checkbox
                      disabled
                      name="status"
                      checked={getBeltNodeStatus("Misc Output 3", beltData?.output_bit_label, item?.output_bits) === '1'}
                    />
                  }
                  label="Output3 :"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} lg={12} xl={3}>
                <FormControlLabel
                  sx={{m: 0}}
                  control={
                    <Checkbox
                      disabled
                      name="status"
                      checked={getBeltNodeStatus("Misc Output 4", beltData?.output_bit_label, item?.output_bits) === '1'}
                    />
                  }
                  label="Output4 :"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </TabContext>

      <Box display="flex" justifyContent="space-between">
        {item && (
          <BeltNodeItemActionsButton
            item={item}
            onPurged={onPurged}
            onOpenHistory={onOpenHistory}
            onClose={onCancel}
            onSubmitted={() => {
              fetchData();
              onSubmitted?.();
            }}
          />
        )}

        <Box display="flex" justifyContent="end" gap={0.5} marginLeft="auto">
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? <Button onClick={() => onEdit()}>Edit Belt</Button> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default BeltNodeItemView;
