import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {AssetMachine} from '../../interfaces/AssetMachine';
import AdminAssetMachineItemUpdate from '../admin/AdminAssetMachineItemUpdate';
import AssetMachineItemUpdate from './AssetMachineItemUpdate';
import AssetMachineItemView from './AssetMachineItemView';

interface Props {
  pk: number;
  item?: AssetMachine;
  mode?: 'view' | 'update' | 'chat' | 'update_from_info';
  isAdminSection?: boolean;
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (item: AssetMachine) => void;
}

const AssetMachineItem = ({
  pk,
  item,
  mode: initialMode = 'view',
  isAdminSection,
  prefetch,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  const [mode, setMode] = useState(initialMode);
  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<AssetMachine>(
        `${apiBaseUrl}/asset-machine/${pk}`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (item && !isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <LocalShippingIcon sx={{color: 'primary.main'}} />
          {fetchedData ? <Box fontSize={24}>{fetchedData.name}</Box> : null}
        </Box>
        <Box display="flex" gap={0.5}>
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
          {onClose ? (
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      {mode === 'view' ? (
        <AssetMachineItemView
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={onClose}
          onEdit={() => setMode('update_from_info')}
          onPurged={onPurged}
          onFetched={setFetchedData}
          onOpenHistory={onOpenHistory}
          onSubmitted={() => {
            onSubmitted?.();
            fetchData();
          }}
        />
      ) : mode === 'update' || mode === 'update_from_info' ? (
        isAdminSection
        ? <AdminAssetMachineItemUpdate
            pk={pk}
            mode={mode}
            siteId={item?.site_id}
            item={fetchedData}
            prefetch={!prefetch}
            onCancel={() => {
              mode === 'update_from_info' ? setMode('view') : onClose?.();
            }}
            onSubmitted={() => {
              onSubmitted?.();
              mode === 'update_from_info' ? setMode('view') : onClose?.();
              fetchData();
            }}
          />
        : <AssetMachineItemUpdate
            pk={pk}
            mode={mode}
            siteId={item?.site_id}
            item={fetchedData}
            prefetch={!prefetch}
            onCancel={() => {
              mode === 'update_from_info' ? setMode('view') : onClose?.();
            }}
            onSubmitted={() => {
              onSubmitted?.();
              mode === 'update_from_info' ? setMode('view') : onClose?.();
              fetchData();
            }}
          />
        ) : null}
    </Box>
  );
};

export default AssetMachineItem;
