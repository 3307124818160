import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {AssetHuman} from '../../interfaces/AssetHuman';
import AccessControl from '../common/AccessControl';
import CommtracNodeItemMinerCheckInOutModal from '../commtrac-nodes/CommtracNodeItemMinerCheckInOutModal';
import EmployeeItemsPurgeChatEventsButton from '../employees/buttons/EmployeeItemsPurgeChatEventsButton';
import AssetHumanItemActivationModal from './AssetHumanItemActivationModal';
import AssetHumanItemPurgeModal from './AssetHumanItemPurgeModal';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: AssetHuman;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
}

const PurgeActionTypes = {
  purgeDevice: 'device',
  purgeData: 'data',
  purgeChat: 'chat',
} as const;

type Action =
  | 'checkin'
  | 'activation'
  | 'purgeDevice'
  | 'purgeData'
  | 'purgeChat'
  | 'checkInOut';

const AssetHumanItemActionsButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [action, setAction] = useState<Action>();
  const isPurgeAction =
    action === 'purgeDevice' ||
    action === 'purgeData' ||
    action === 'purgeChat';
  const purgeType = isPurgeAction ? PurgeActionTypes[action] : null;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        {item.checked_in && item.commtrac_ack !== "0" ? (
            <AccessControl
              roles={[1,2,3]}
            >
              <MenuItem
                onClick={() => {
                  setAction('checkInOut');
                  setAnchorEl(null);
                }}
              >
                <RadioButtonUncheckedIcon fontSize="small" sx={{mr: 1.5}} />
                Force Check Out
              </MenuItem>
            </AccessControl>
          ) : (
            <AccessControl
              roles={[1,2,3]}
            >
              <MenuItem
                onClick={() => {
                  setAction('checkInOut');
                  setAnchorEl(null);
                }}
              >
                <RadioButtonCheckedIcon fontSize="small" sx={{mr: 1.5}} />
                Force Check In
              </MenuItem>
            </AccessControl>
        )}
        <AccessControl permissions={['patch::/asset-human/:id']}>
          <MenuItem
            onClick={() => {
              setAction('activation');
              setAnchorEl(null);
            }}
          >
            {item.status === 'active' ? (
              <>
                <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                DeActivate
              </>
            ) : (
              <>
                <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                Activate
              </>
            )}
          </MenuItem>
        </AccessControl>
        {item.commtrac_node_id ? (
          <AccessControl permissions={['post::/purge']}>
            <MenuItem divider />
            <MenuItem disabled>
              Purge
            </MenuItem>
            <MenuItem divider sx={{ padding: '0px' }}/>
            <MenuItem
              onClick={() => {
                setAction('purgeDevice');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              All
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAction('purgeData');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Transactions
            </MenuItem>
            <MenuItem sx={{'& li': {
              padding: '0px'
            }}}>
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              <EmployeeItemsPurgeChatEventsButton
                ids={[item.id]}
                component={MenuItem}
              >
                Chat
              </EmployeeItemsPurgeChatEventsButton>
            </MenuItem>
          </AccessControl>
        ) : null}
      </Menu>

      {action === 'activation' ? (
        <AssetHumanItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : action === 'checkInOut' && item.commtrac_node_id ? (
        <CommtracNodeItemMinerCheckInOutModal
          ids={[{
            id: item.commtrac_node_id,
            checked_in: item.checked_in
          }]}
          open={action === 'checkInOut'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : purgeType ? (
        <AssetHumanItemPurgeModal
          item={item}
          action={purgeType}
          open={!!purgeType}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}
    </>
  );
};

export default AssetHumanItemActionsButton;
