export interface BeltInputBody {
  name: string | null,
  status: 'active' | 'inactive' | null;
  commtrac_external_id: number | null;
  zone_id: number | null;
  latitude: number | null;
  longitude: number | null;
}

export interface BeltEditInputBody {
  name: string | null;
  status: 'active' | 'inactive' | null;
  network_id: number | null;
  zone_id: number | null;
  reporting_frequency: number | null;
  location_frequency: number | null;
  latitude: number | null;
  longitude: number | null;
}

export const BELT_STATUS_LABEL: { [key: string]: string } = {
  true: 'Stop',
  false: 'Start',
  "1": 'Stop',
  "0": 'Start',
  "undefined": ''
}

export interface BELT_STATUS {
  pk: number | -1,
  status: string | 'stopped',
}

export const BELT_RUN_STATUS: { [key: string]: string } = {
  0: "Manual Stopped",
  1: "Auto Stopped",
  2: "Manual Running",
  3: "Auto Running"
}
