import ApartmentIcon from '@mui/icons-material/Apartment';
import {
  IconButton,
  Tooltip
} from '@mui/material';
import { useState } from 'react';

import { CompanySelectModal } from '../company/CompanySelectModal';


const CompanySelectManager = () => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <Tooltip title="Company">
        <IconButton onClick={() => setIsOpened(true)}>
          <ApartmentIcon color="primary"/>
        </IconButton>
      </Tooltip>
      <CompanySelectModal open={isOpened} onClose={() => setIsOpened(false)} />
    </>
  );
};

export default CompanySelectManager;
