import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useGetAssetHumanType} from '../../hooks/get-commtrac-node-type';
import {useGetMinerNetworkId} from '../../hooks/get-miner-network-id';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  AssetHuman,
  AssetHumanUpdateInputBody,
} from '../../interfaces/AssetHuman';
import {
  CommtracNode
} from '../../interfaces/CommtracNode';
import reduxActions from '../../redux/actions';
import {assetHumanUpdateInputSchema} from '../../scheme/yup/asset-human';
import {maxPersonIdLength, maxProximityId} from '../../scheme/yup/utils';
import {
  getIsEmployeeHasMotionSensorVisible,
  getIsEmployeeMc2FlagVisible,
  getIsMacAddressVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsReportFreqIdVisible
} from '../../utils/commtrac-nodes';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import {AssetHumanTypeSelect} from '../selectors/AssetHumanTypeSelect';
import {DateSelect} from '../selectors/DateSelect';
import {ShiftSelect} from '../selectors/ShiftSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  pk?: number;
  mode?: 'view' | 'update' | 'chat' | 'update_from_info';
  item?: AssetHuman;
  prefetch?: boolean;
  onCancel?: () => void;
  onSubmitted?: (item: AssetHuman) => void;
}

type UpdateInputBody = AssetHumanUpdateInputBody & {
  is_commtrac: boolean | null;
  is_proximity: boolean;
  wifi_enabled: boolean | null;
  proximity_enabled: boolean;
  commtrac_external_id: number | null;
  commtrac_report_frequency: number | null;
  mac_address: string | null;
};

// eslint-disable-next-line complexity
const AdminAssetHumanItemUpdate = ({
  pk,
  mode,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  const reduxDispatch = useAppDispatch();
  const company = useAppSelector(({assets}) => assets.company);
  const [connect_enabled, setConnect_enabled] = useState<boolean>(!!company?.commtrac_enabled);
  const [proximity_enabled, setProximity_enabled] = useState<boolean>(!!company?.proximity_enabled);
  const [isConnectenabled, setIsConnectenabled] = useState<boolean>(!!item?.commtrac_external_id || !!item?.mac_address);
  const isInitialConnectenabled = !!item?.commtrac_external_id || !!item?.mac_address;

  useEffect(() => {
    setProximity_enabled(!!company?.proximity_enabled);
    setConnect_enabled(!!company?.commtrac_enabled);
  }, [company?.proximity_enabled, company?.commtrac_enabled]);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AssetHuman>(`${apiBaseUrl}/asset-human/${pk}`);
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: UpdateInputBody) => {
    setSubmittedInProgress(true);
    try {

      let respNetworkId = null;
      if (getMinerNetworkId(item?.commtrac_external_id) !== data.commtrac_external_id || item?.mac_address !== data.mac_address) {
        const endpointNetworkId = `${apiBaseUrl}/asset-human/${pk}/update_network_id`;
        respNetworkId = await API.patch<AssetHuman>(endpointNetworkId, {
          commtrac_external_id: data.wifi_enabled ? undefined : data.commtrac_external_id,
          mac_address: getIsMacAddressVisible(isConnectenabled, data.wifi_enabled) ? data.mac_address : undefined,
        });
        const message = `Employee's network has been updated`;
        enqueueSnackbar(message, {
          variant: 'success',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
      }

      const endpoint = `${apiBaseUrl}/asset-human/${pk}`;
      const resp = await API.patch<AssetHuman>(endpoint, {
        ...omit(data, ['mac_address', 'commtrac_external_id', 'commtrac_report_frequency']),
        birth_date: data.birth_date
          ? dayjs(data.birth_date).format('YYYY-MM-DD')
          : null,
        wifi_enabled: data.wifi_enabled,
        mc2_flag: getIsEmployeeMc2FlagVisible(
          isConnectenabled,
          data.wifi_enabled
        )
          ? data.mc2_flag
          : undefined,
        has_motion_sensor: getIsEmployeeHasMotionSensorVisible(
          isConnectenabled,
          data.wifi_enabled
        )
          ? data.has_motion_sensor
          : undefined,
        min_voltage: getIsMinVoltageVisible(isConnectenabled, data.wifi_enabled)
        ? data.min_voltage
        : undefined,
        max_voltage: getIsMaxVoltageVisible(isConnectenabled, data.wifi_enabled)
          ? data.max_voltage
          : undefined,
      });
      const message = `Employee has been updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });

      if ((
        getIsReportFreqIdVisible(connect_enabled, !!item?.wifi_enabled) ||
        getIsReportFreqIdVisible(isConnectenabled, !!data.wifi_enabled)
      ) && (item?.commtrac_report_frequency !== data.commtrac_report_frequency)) {
        const endpointReportFreq = `${apiBaseUrl}/commtrac-node/${respNetworkId ? respNetworkId?.data.commtrac_node_id: resp.data.commtrac_node_id}/miner/report_frequency`;
        await API.patch<CommtracNode>(endpointReportFreq, {
          frequency: data.commtrac_report_frequency
        });
        const message = `Report frequency configuration message has been successfully sent`;
        enqueueSnackbar(message, {
          variant: 'success',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
      }

      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAssetHumans);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const shifts = useAppSelector(({assets}) => assets.shifts);
  const zones = useAppSelector(({assets}) => assets.zones);
  const assetHumanTypes = useAppSelector(
    ({assets}) => assets.asset_human_types
  );

  const getMinerNetworkId = useGetMinerNetworkId();
  const getFormikValues = (item?: AssetHuman): UpdateInputBody => ({
    proximity_enabled: !!company?.proximity_enabled,
    is_commtrac: !!item?.commtrac_external_id || !!item?.mac_address,
    is_proximity: !!item?.external_id,
    wifi_enabled: !!item?.wifi_enabled,
    nickname: item?.nickname ?? null,
    first_name: item?.first_name ?? null,
    last_name: item?.last_name ?? null,
    birth_date: item?.birth_date ? dayjs(item?.birth_date).toDate() : null,
    description: item?.description ?? '',
    commtrac_external_id: getMinerNetworkId(item?.commtrac_external_id) ?? null,
    commtrac_report_frequency: item?.commtrac_report_frequency ?? null,
    type_id: item?.type_id
      ? assetHumanTypes.find((i) => i.id === item.type_id)?.id ?? null
      : null,
    zone_id: item?.zone_id
      ? zones.find((i) => i.id === item.zone_id)?.id ?? null
      : null,
    shift_id: item?.shift_id
      ? shifts.find((i) => i.id === item.shift_id)?.id ?? null
      : null,
    status: item?.status ?? null,
    personal_id: item?.personal_id || null,
    external_id: item?.external_id ?? null,
    mc2_flag: item?.mc2_flag ?? 0,
    cam_monitor_battery: item?.cam_monitor_battery ? 1 : 0,
    has_motion_sensor: item?.has_motion_sensor ?? 0,
    min_voltage: item?.min_voltage ?? null,
    max_voltage: item?.max_voltage ?? null,
    mac_address: item?.mac_address ?? null
  });

  const formik = useFormik<UpdateInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: assetHumanUpdateInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  const getAsssetHumanType = useGetAssetHumanType();
  const assetHumanType = getAsssetHumanType({
    commtrac_external_id: item?.commtrac_external_id,
    wifi_enabled: formik.values.wifi_enabled ? 1 : 0,
    mc2_flag: formik.values.mc2_flag,
  });

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.nickname || ''}
          label="Nickname"
          size="small"
          name="nickname"
          fullWidth
          error={!!formik.touched.nickname && !!formik.errors.nickname}
          helperText={formik.touched.nickname && formik.errors.nickname}
          onChange={(e) =>
            formik.setFieldValue(e.target.name, e.target.value || null)
          }
        />

        <TextField
          value={formik.values.first_name || ''}
          label="First Name"
          size="small"
          name="first_name"
          fullWidth
          error={!!formik.touched.first_name && !!formik.errors.first_name}
          helperText={formik.touched.first_name && formik.errors.first_name}
          onChange={(e) =>
            formik.setFieldValue(e.target.name, e.target.value || null)
          }
        />

        <TextField
          value={formik.values.last_name || ''}
          label="Last Name"
          size="small"
          name="last_name"
          fullWidth
          error={!!formik.touched.last_name && !!formik.errors.last_name}
          helperText={formik.touched.last_name && formik.errors.last_name}
          onChange={(e) =>
            formik.setFieldValue(e.target.name, e.target.value || null)
          }
        />

        <DateSelect
          value={formik.values.birth_date}
          renderInput={{
            label: 'Date of Birth',
            size: 'small',
            fullWidth: true,
            error: !!formik.touched.birth_date && !!formik.errors.birth_date,
            helperText: formik.touched.birth_date && formik.errors.birth_date,
          }}
          onChange={(value) => formik.setFieldValue('birth_date', value)}
        />

        <TextField
          value={formik.values.description || ''}
          label="Description"
          size="small"
          name="description"
          multiline
          rows={3}
          fullWidth
          error={!!formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
          onChange={(e) =>
            formik.setFieldValue(e.target.name, e.target.value || null)
          }
        />

        <AssetHumanTypeSelect
          value={formik.values.type_id}
          label="Job Type"
          size="small"
          fullWidth
          error={!!formik.touched.type_id && !!formik.errors.type_id}
          helperText={formik.touched.type_id && formik.errors.type_id}
          onChange={(v) => formik.setFieldValue('type_id', v)}
        />

        <ZoneSelect
          value={formik.values.zone_id}
          label="Assigned Section"
          size="small"
          fullWidth
          error={!!formik.touched.zone_id && !!formik.errors.zone_id}
          helperText={formik.touched.zone_id && formik.errors.zone_id}
          onChange={(v) => formik.setFieldValue('zone_id', v)}
        />

        <ShiftSelect
          value={formik.values.shift_id}
          label="Assigned Shift"
          size="small"
          fullWidth
          error={!!formik.touched.shift_id && !!formik.errors.shift_id}
          helperText={formik.touched.shift_id && formik.errors.shift_id}
          onChange={(v) => formik.setFieldValue('shift_id', v)}
        />

        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />

        <TextField
          value={formik.values.personal_id || ''}
          label="Employee ID"
          size="small"
          name="personal_id"
          fullWidth
          error={!!formik.touched.personal_id && !!formik.errors.personal_id}
          helperText={formik.touched.personal_id && formik.errors.personal_id}
          inputProps={{maxLength: maxPersonIdLength}}
          onChange={(e) =>
            formik.setFieldValue(e.target.name, e.target.value || null)
          }
        />

        {company?.commtrac_enabled && assetHumanType === 'CAM' ? (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">
              Monitor Battery
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.cam_monitor_battery}
                    onChange={() => {
                      formik.setFieldValue('cam_monitor_battery', 1);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.cam_monitor_battery}
                    onChange={() => {
                      formik.setFieldValue('cam_monitor_battery', 0);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        {connect_enabled && (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement-connect">
              Connect Enabled Asset
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement-connect"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    disabled={!!formik.values.commtrac_external_id || !!formik.values.mac_address}
                    checked={isConnectenabled}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                    onChange={() => {
                      setIsConnectenabled(true);
                    }}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    disabled={!!formik.values.commtrac_external_id || !!formik.values.mac_address}
                    checked={!isConnectenabled}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                    onChange={() => {
                      setIsConnectenabled(false);
                      formik.setFieldValue ("is_commtrac", false);
                      formik.setFieldValue ("wifi_enabled", false);
                      formik.setFieldValue ("mac_address", null);
                      formik.setFieldValue ("commtrac_external_id", null);
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        )}

        {connect_enabled
          ? isConnectenabled
            ? formik.values.wifi_enabled
              ? <>
                  <FormControl>
                    <FormLabel id="demo-form-control-label-placement-wifi">
                      Wifi Enabled
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement-wifi"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        label="Yes"
                        control={
                          <Radio
                            checked={formik.values.wifi_enabled}
                            name="radio-buttons"
                            inputProps={{'aria-label': 'Yes'}}
                            onChange={() => {
                              formik.setFieldValue ("is_commtrac", true);
                              formik.setFieldValue ("wifi_enabled", true);
                            }}
                            disabled={isInitialConnectenabled}
                          />
                        }
                      />
                      <FormControlLabel
                        label="No"
                        control={
                          <Radio
                            checked={!formik.values.wifi_enabled}
                            name="radio-buttons"
                            inputProps={{'aria-label': 'Yes'}}
                            onChange={() => {
                              formik.setFieldValue ("wifi_enabled", false);
                              formik.setFieldValue ("is_commtrac", true);
                            }}
                            disabled={isInitialConnectenabled}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    value={formik.values.mac_address}
                    label="Mac Address"
                    size="small"
                    name="mac_address"
                    fullWidth
                    inputProps={{'aria-label': 'Yes'}}
                    InputLabelProps={{
                      shrink: true, // Forces the label to be shrunk even when disabled
                    }}
                    error={!!formik.touched.mac_address && !!formik.errors.mac_address}
                    helperText={formik.touched.mac_address && formik.errors.mac_address}
                    onChange={formik.handleChange}
                  />
                  <NumberTextField
                    value={
                      formik.values.min_voltage
                        ? formik.values.min_voltage / 10
                        : formik.values.min_voltage
                    }
                    decimalPlaces={1}
                    min={0}
                    max={10}
                    step={0.1}
                    label="Min Voltage"
                    size="small"
                    name="min_voltage"
                    fullWidth
                    error={!!formik.touched.min_voltage && !!formik.errors.min_voltage}
                    helperText={formik.touched.min_voltage && formik.errors.min_voltage}
                    onChange={(v) =>
                      formik.setFieldValue('min_voltage', v ? v * 10 : v)
                    }
                  />
                  <NumberTextField
                    value={
                      formik.values.max_voltage
                        ? formik.values.max_voltage / 10
                        : formik.values.max_voltage
                    }
                    decimalPlaces={1}
                    min={0}
                    max={10}
                    step={0.1}
                    label="Max Voltage"
                    size="small"
                    name="max_voltage"
                    fullWidth
                    error={!!formik.touched.max_voltage && !!formik.errors.max_voltage}
                    helperText={formik.touched.max_voltage && formik.errors.max_voltage}
                    onChange={(v) =>
                      formik.setFieldValue('max_voltage', v ? v * 10 : v)
                    }
                  />
                </>
              : <>
                  <FormControl>
                    <FormLabel id="demo-form-control-label-placement-wifi">
                      Wifi Enabled
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement-wifi"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        label="Yes"
                        control={
                          <Radio
                            checked={!!formik.values.wifi_enabled}
                            name="wifi_enabled"
                            inputProps={{'aria-label': 'Yes'}}
                            onChange={() => {
                              formik.setFieldValue ("is_commtrac", true);
                              formik.setFieldValue ("wifi_enabled", true);
                            }}
                            disabled={isInitialConnectenabled}
                          />
                        }
                      />
                      <FormControlLabel
                        label="No"
                        control={
                          <Radio
                            checked={!formik.values.wifi_enabled}
                            name="wifi_enabled"
                            inputProps={{'aria-label': 'No'}}
                            onChange={() => {
                              formik.setFieldValue ("is_commtrac", true);
                              formik.setFieldValue ("wifi_enabled", false);
                            }}
                            disabled={isInitialConnectenabled}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    value={formik.values.commtrac_external_id || ''}
                    label="Network ID"
                    size="small"
                    name="commtrac_external_id"
                    fullWidth
                    error={!!formik.touched.commtrac_external_id && !!formik.errors.commtrac_external_id}
                    helperText={formik.touched.commtrac_external_id && formik.errors.commtrac_external_id}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    value={formik.values.commtrac_report_frequency ?? 60}
                    label="Reporting Frequency"
                    size="small"
                    name="commtrac_report_frequency"
                    fullWidth
                    error={!!formik.touched.commtrac_report_frequency && !!formik.errors.commtrac_report_frequency}
                    helperText={formik.touched.commtrac_report_frequency && formik.errors.commtrac_report_frequency}
                    onChange={formik.handleChange}
                  />
                  <FormControl>
                    <FormLabel id="demo-form-control-label-placement">MC2</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        label="Yes"
                        control={
                          <Radio
                            checked={!!formik.values.mc2_flag}
                            onChange={() => {
                              formik.setFieldValue('mc2_flag', 1);
                            }}
                            value="true"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'Yes'}}
                          />
                        }
                      />
                      <FormControlLabel
                        label="No"
                        control={
                          <Radio
                            checked={!formik.values.mc2_flag}
                            onChange={() => {
                              formik.setFieldValue('mc2_flag', 0);
                            }}
                            value="true"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'No'}}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel id="demo-form-control-label-placement">
                      Has a Motion Sensor?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement"
                      name="position"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        label="Yes"
                        control={
                          <Radio
                            checked={!!formik.values.has_motion_sensor}
                            onChange={() => {
                              formik.setFieldValue('has_motion_sensor', 1);
                            }}
                            value="true"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'Yes'}}
                          />
                        }
                      />
                      <FormControlLabel
                        label="No"
                        control={
                          <Radio
                            checked={!formik.values.has_motion_sensor}
                            onChange={() => {
                              formik.setFieldValue('has_motion_sensor', 0);
                            }}
                            value="true"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'No'}}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </>
            : null
          : null
        }

        {proximity_enabled ? (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">
              Proximity Enabled Asset
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.is_proximity}
                    onChange={() => {
                      formik.setFieldValue('is_proximity', true);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.is_proximity}
                    onChange={() => {
                      formik.setFieldValue('is_proximity', false);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <NumberTextField
            value={formik.values.external_id}
            min={1}
            max={maxProximityId}
            label="Proximity ID"
            size="small"
            name="external_id"
            fullWidth
            error={!!formik.touched.external_id && !!formik.errors.external_id}
            helperText={formik.touched.external_id && formik.errors.external_id}
            onChange={(v) => formik.setFieldValue('external_id', v)}
          />
        ) : null}
      </Box>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          mode === 'update_from_info' ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : (
            <Button onClick={() => onCancel()}>Cancel</Button>
          )
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminAssetHumanItemUpdate;
