import {CheckOutlined, DisabledByDefaultSharp} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Box, Button, IconButton, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import {GasMonitoringNode} from '../../../interfaces/GasMonitoringNode';
import AMSSensorItemPurgeModal from '../../ams-sensor/AMSSensorItemPurgeModal';
import AccessControl from '../../common/AccessControl';
import { getHasPermission } from '../../common/AccessControl/utils';
import AMSSensorItemActivationModal from './AMSSensorItemActivationModal';
import AMSSensorItemDisableEventsModal from './AMSSensorItemDisableEventsModal';
import AMSSensorItemStandownEventsModal from './AMSSensorItemStandownEventsModal';

interface Props {
  item: GasMonitoringNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onCancel?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'amsShortTerm' | 'amsLongTerm' | 'amsLocation'
  ) => void;
}

const PurgeActionTypes = {
  purgeDevice: 'device',
  purgeData: 'data',
} as const;

type Action =
  | 'checkin'
  | 'activation'
  | 'purgeDevice'
  | 'purgeData'
  | 'checkInOut'
  | 'disable_events'
  | 'enable_events'
  | 'standDown';

const AMSSensorItemActionsButton = ({
  item,
  onSubmitted,
  onPurged,
  onCancel,
  onOpenHistory,
}: Props) => {
  const me = useAppSelector(({app}) => app.me);
  const [action, setAction] = useState<Action>();
  const isPurgeAction = action === 'purgeDevice' || action === 'purgeData';
  const purgeType = isPurgeAction ? PurgeActionTypes[action] : null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [anchorElMore, setAnchorElMore] = useState<null | HTMLElement>(null);
  const menuOpenMore = Boolean(anchorElMore);

  const checkMenuContent = () => {
    const menuItemCount = document.querySelectorAll('.sensor_menu_list li').length;
    if (menuItemCount === 0) {
      const menuElements = document.getElementsByClassName('sensor_menu_list');
      for (let i = 0; i < menuElements.length; i++) {
        const element = menuElements[i] as HTMLElement;
        element.style.display = 'none';
      }
    }
  }

  return (
    <>
      <Box display="flex" justifyContent="start" gap={0.5}>
        <Box position="relative">
          <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
            History
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onBackdropClick={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                onOpenHistory?.(item?.id, 'amsLongTerm');
                onCancel?.();
              }}
            >
              Long Term History Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                onOpenHistory?.(item?.id, 'amsShortTerm');
                onCancel?.();
              }}
            >
              Short Term History Reports
            </MenuItem>
          </Menu>
        </Box>
        { (me?.type_id !== 5 ||
          (item.type_id === 40 ||
            item.type_id === 20 ||
            item.type_id === 130 ||
            item.type_id === 44) ||
          getHasPermission(me.permissions, ['post::/purge']) ||
          getHasPermission(me.permissions, ['patch::/ams/:id(\\d+)/stand_down']) ||
          getHasPermission(me.permissions, ['patch::/ams/:id(\\d+)'])) &&
          <IconButton
            onClick={(event) => {
              setAnchorElMore(event.currentTarget);
              setTimeout(checkMenuContent, 0);
            }}
            size="small"
            color="primary"
          >
            <MoreHorizIcon />
          </IconButton>
        }
        <Menu
          className="sensor_menu_list"
          anchorEl={anchorElMore}
          open={menuOpenMore}
          onBackdropClick={() => setAnchorElMore(null)}
        >
          {(item.type_id === 40 ||
            item.type_id === 20 ||
            item.type_id === 130 ||
            item.type_id === 44) && (
            <>
              {item?.disable_event === 0 ? (
                <MenuItem
                  onClick={() => {
                    setAction('disable_events');
                    setAnchorElMore(null);
                  }}
                >
                  <DisabledByDefaultSharp fontSize="small" sx={{mr: 1.5}} />
                  Disable Events
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    setAction('enable_events');
                    setAnchorElMore(null);
                  }}
                >
                  <CheckOutlined fontSize="small" sx={{mr: 1.5}} />
                  Enable Events
                </MenuItem>
              )}
            </>
          )}

          <AccessControl
            permissions={['patch::/ams/:id(\\d+)/stand_down']}
            accessChecker={() => !!item?.ava}
          >
            <MenuItem
              onClick={() => {
                setAction('standDown');
                setAnchorElMore(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Stand Down
            </MenuItem>
          </AccessControl>
          <AccessControl permissions={['patch::/ams/:id(\\d+)']}>
            <MenuItem
              onClick={() => {
                setAction('activation');
                setAnchorEl(null);
              }}
            >
              {item?.status === 'active' ? (
                <>
                  <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                  DeActivate
                </>
              ) : (
                <>
                  <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                  Activate
                </>
              )}
            </MenuItem>
          </AccessControl>
          <AccessControl permissions={['post::/purge']}>
            <MenuItem
              onClick={() => {
                setAction('purgeDevice');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Device
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAction('purgeData');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Transaction
            </MenuItem>
          </AccessControl>
        </Menu>
      </Box>
      {action === 'activation' ? (
        <AMSSensorItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : purgeType ? (
        <AMSSensorItemPurgeModal
          item={item}
          action={purgeType}
          open={!!purgeType}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}

      {(action === 'disable_events' || action === 'enable_events') && (
        <AMSSensorItemDisableEventsModal
          item={item}
          action={action}
          open={action === 'disable_events' || action === 'enable_events'}
          onClose={() => {
            setAction(undefined);
          }}
          onSubmitted={onSubmitted}
        />
      )}

      {action === 'standDown' && (
        <AMSSensorItemStandownEventsModal
          item={item}
          open={action === 'standDown'}
          onClose={() => {
            setAction(undefined);
          }}
          onSubmitted={onSubmitted}
        />
      )}
    </>
  );
};

export default AMSSensorItemActionsButton;
