import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import update from 'immutability-helper';
import {useEffect, useMemo, useRef, useState} from 'react';

import {useConfiguration} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import SpannerIcon from '../../../images/belts/spanner/Spanner';
import { WifiAIcon } from '../../../images/belts/wifi-icons/WifiAIcon';
import { WifiBIcon } from '../../../images/belts/wifi-icons/WifiBIcon';
import { WifiDIcon } from '../../../images/belts/wifi-icons/WifiDIcon';
import {BELT_RUN_STATUS, BELT_STATUS} from '../../../interfaces/BeltMonitoring';
import {
  beltAddressMask,
  BeltMonitoringNode,
  BeltMonitoringNodeListQuery,
  BeltMonitoringNodeListResponse,
} from '../../../interfaces/BeltMonitoringNode';
import {ExportField} from '../../../interfaces/Export';
import { getBeltInputStatus, getBeltNodeStatus } from '../../../utils/belt';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {isPresent} from '../../../utils/type-guards';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import BeltItemsPurgeButton from '../BeltMonitoring/BeltItemsPurgeButton';
import BeltNodeImportExportButton from '../BeltMonitoring/BeltNodeImportExportButton';
import BeltNodeItemAcknowledgeButton from '../BeltMonitoring/BeltNodeItemAcknowledgeButton';
import BeltNodeItemEditButton from '../BeltMonitoring/BeltNodeItemEditButton';
import BeltNodeItemUpdateRunning from '../BeltMonitoring/BeltNodeItemUpdateRunning';
import {
  BeltNodeItemUpdateStatus,
  BeltStatusRef,
} from '../BeltMonitoring/BeltNodeItemUpdateStatus';
import BeltNodeItemUpsertButton from '../BeltMonitoring/BeltNodeItemUpsertButton';
// import BeltNodeRefreshRateButton from '../BeltMonitoring/BeltNodeRefreshRateButton';

export interface BeltMonitoringReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  params: Partial<BeltMonitoringNodeListQuery>;
  selectedIds: string[] | null;
  shownFields: {
    all?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

export interface BeltMonitoringReportDataExportFields {
  exportFields: ExportField[];
  params?: Partial<BeltMonitoringNodeListQuery>;
}


const DEFAULT_SHOWN_FIELDS = [
  'select',
  'id',
  'edit',
  'connection_status',
  'input_faults',
  'output_status',
  'output_bits',
  'msg_conf',
  'commtrac_external_id',
  'name',
  'commtrac_date',
  'belt_status',
  'speed',
  'section',
  'lat',
  'lon',
  'activated_at',
  'deactivated_at',
  'commtrac_report_frequency',
  'motor1',
  'motor2',
];

export const getBeltMonitoringReportData = (): any => ({
  mapLayers: ['street', 'mine', 'belts'],
  mapLevel: null,
  params: {
    page: 0,
    limit: 2000,
    status: 'all',
    section_ids: [],
  },
  selectedIds: [],
  shownFields: {
    all: DEFAULT_SHOWN_FIELDS,
  },
  grid: {
    page: 0,
    pageSize: 25,
  },
  exportFields: [
    {field: "connection_status", hidden: false, label: "Connection Status"},
    {field: "input_fault", hidden: false, label: "Input Fault"},
    {field: "output_status", hidden: false, label: "Output Status"},
    {field: "msg_conf", hidden: false, label: "Msg Conf"},
    {field: "name", hidden: false, label: "Name"},
    {field: "commtrac_external_id", hidden: false, label: "Network ID"},
    {field: "outputs", hidden: false, label: "Output Status"},
    {field: "date", hidden: false, label: "Timestamp"},
    {field: "run_status", hidden: false, label: "Belt Status"},
    {field: "speed", hidden: false, label: "Belt Speed"},
    {field: "motor1", hidden: false, label: "Motor 1 Current"},
    {field: "motor2", hidden: false, label: "Motor 2 Current"},
  ]
});

interface Props {
  value: BeltMonitoringReportData;
  fetchedData?: BeltMonitoringNodeListResponse;
  isLoading?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  onOpenItem?: (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
      | 'amsShortTerm'
      | 'amsLongTerm'
      | 'amsLocation'
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
      | 'beltHistoryReport'
      | 'beltLocationHistoryReport'
  ) => void;
  onChange?: (value?: BeltMonitoringReportData) => void;
  onRefresh?: () => void;
  onAcknowledgeClose: (id: number) => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
}

export const BeltMonitoringReport = ({
  value,
  fetchedData,
  isLoading,
  locationCoordinates,
  onOpenItem,
  onChange,
  onRefresh = () => {},
  onAcknowledgeClose,
  onChangeLocationCoordinates,
  onOpenHistory,
}: Props) => {
  const miscRef = useRef<BeltStatusRef | null>(null);
  const assetsZones = useAppSelector(({assets}) => assets.zones);
  const beltData = useAppSelector(({assets}) => assets.belt);

  const config = useMemo(() => value ?? getBeltMonitoringReportData(), [value]);
  const [openedUpdateBeltRunning, setOpenedUpdateBeltRunning] =
    useState<BELT_STATUS>({pk: -1, status: 'stopped'});

  /**
   * Data Grid
   */
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);
  const columns: DataGridColumn<BeltMonitoringNode>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            disabled={rows.length === 0}
            checked={selectedItems.length > 0 && selectedAll}
            indeterminate={selectedItems.length > 0 && !selectedAll}
            onChange={() => toggleSelectAllItems()}
          />
        </Box>
      ),
      renderCell: ({row}) => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            checked={selectedItems.includes(String(row.id))}
            onChange={() => toggleSelectItem(String(row.id))}
          />
        </Box>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      valueGetter: ({row}) => row.id,
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Tooltip title="Edit">
          <MoreHorizIcon />
        </Tooltip>
      ),
      type: 'actions',
      doNotExport: true,
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) =>
        row.acknowledged === 0 ? (
          <AccessControl permissions={['patch::/belt/:id(\\d+)']}>
            <BeltNodeItemAcknowledgeButton
              item={row}
              pk={row.id}
              locationCoordinates={locationCoordinates}
              component={IconButton}
              onSubmitted={(item) => {
                onRefresh?.();
                onOpenItem?.(item?.id, 'belt', 'ack');
              }}
              onClose={() => {
                onAcknowledgeClose(row.id);
              }}
            >
              <Tooltip title="Acknowledge">
                <WarningAmberIcon color="warning" />
              </Tooltip>
            </BeltNodeItemAcknowledgeButton>
          </AccessControl>
        ) : (
          <BeltNodeItemEditButton
            item={row}
            onOpenItem={onOpenItem}
            onUpdateRun={onUpdateRun}
            onUpdateStatus={onUpdateStatus}
            onOpenHistory={onOpenHistory}
            component={IconButton}
          >
            <MoreHorizIcon />
          </BeltNodeItemEditButton>
        ),
    },
    {
      field: 'connection_status',
      headerName: 'Connection Status',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Tooltip title="Connection Status">
            <WifiIcon />
          </Tooltip>
        </Box>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.heartbeat_type !== '') {
          let heatbeatTitle = '';
          if (row.e_605) {
            heatbeatTitle = 'Belt many ENV messages';
            return <Tooltip title={heatbeatTitle} placement="top">
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <WifiAIcon color="red" size={15}/>
                </Box>
              </Tooltip>
          } else if (row.e_603) {
            heatbeatTitle = 'Belt no ENV messages';
            return <Tooltip title={heatbeatTitle} placement="top">
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <WifiDIcon color="red" size={15}/>
                </Box>
              </Tooltip>
          } else if (row.e_604) {
            heatbeatTitle = 'Belt few ENV messages';
            return <Tooltip title={heatbeatTitle} placement="top">
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <WifiBIcon color="red" size={15}/>
                </Box>
              </Tooltip>
          } else {
            heatbeatTitle = 'Good';
            return <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Tooltip title={heatbeatTitle}>
                <WifiIcon />
              </Tooltip>
            </Box>
          }
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'input_faults',
      headerName: 'Input Fault',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Tooltip title="Input Fault">
            <WarningIcon />
          </Tooltip>
        </Box>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        let title = '';

        if (row.e_602) {
          title = getBeltInputStatus(beltData?.input_fault_bit_label, row.input_bits);
        } else {
          title = 'OK';
        }

        return row.e_602 ? (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Tooltip title={title}>
              <WarningIcon sx={{color: 'red'}} />
            </Tooltip>
          </Box>
        ) : (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Tooltip title={title}>
              <WarningIcon />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'output_status',
      headerName: 'Output Status',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Tooltip title="Output Status">
            <OpenWithIcon />
          </Tooltip>
        </Box>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.e_610 || row.e_611) {
          let outputStatusText = `${getBeltNodeStatus("Misc Output 1", beltData?.output_bit_label, row.output_bits) === '1' ? 'Misc Output 1 On,' : ''}
            ${getBeltNodeStatus("Misc Output 2", beltData?.output_bit_label, row.output_bits) === '1' ? 'Misc Output 2 On, ' : ''}
            ${getBeltNodeStatus("Misc Output 3", beltData?.output_bit_label, row.output_bits) === '1' ? 'Misc Output 3 On, ' : ''}
             ${getBeltNodeStatus("Misc Output 4", beltData?.output_bit_label, row.output_bits) === '1' ? 'Misc Output 4 On' : ''}
          `;
          outputStatusText = outputStatusText.replace(/,\s*$/, '');
          return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              {outputStatusText.trim() === ''
                ? <OpenWithIcon />
                : <Tooltip title={outputStatusText}>
                    <OpenWithIcon />
                  </Tooltip>
              }
            </Box>
          );
        }
      },
    },
    {
      field: 'msg_conf',
      headerName: 'Msg Conf',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Msg Conf">
          <SpannerIcon color="white" size={18}/>
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.e_607) {
          return <SpannerIcon color="red"/>
        } else if (row.configuration_message && 'answer' in row.configuration_message && row.configuration_message.answer === 'FAIL') {
          return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Tooltip title={row.configuration_message.answer}>
                <SpannerIcon color="red"/>
              </Tooltip>
            </Box>
          );
        } else if (row.configuration_message && 'answer' in row.configuration_message && row.configuration_message.answer === 'ACK') {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                '& > svg': {
                  height: '20px !important',
                  fontSize: '20px !important',
                },
              }}
            >
              <Tooltip title={row.configuration_message.answer}>
                <DoneIcon sx={{color: 'green'}} />
              </Tooltip>
            </Box>
          );
        } else if (row.configuration_message && 'answer' in row.configuration_message && row.configuration_message.answer === 'NACK') {
          return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Tooltip title={row.configuration_message.answer}>
                <AutorenewIcon />
              </Tooltip>
            </Box>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => row.name,
    },
    {
      field: 'section',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) => assetsZones.find((i) => i.id === row.zone_id)?.name,
    },
    {
      field: 'lat',
      headerName: 'Lat',
      sortable: true,
      valueGetter: ({row}) => row.latitude,
    },
    {
      field: 'lon',
      headerName: 'Lon',
      sortable: true,
      valueGetter: ({row}) => row?.longitude ?? '',
    },
    {
      field: 'activated_at',
      headerName: 'Activated Date',
      sortable: true,
      valueGetter: ({row}) => row.activated_at,
    },
    {
      field: 'deactivated_at',
      headerName: 'Deactivated Date',
      sortable: true,
      valueGetter: ({row}) => row.deactivated_at,
    },
    {
      field: 'commtrac_report_frequency',
      headerName: 'Reporting Frequency',
      sortable: true,
      valueGetter: ({row}) => row.reporting_frequency,
    },
    {
      field: 'location_frequency',
      headerName: 'Location Frequency',
      sortable: true,
      valueGetter: ({row}) => row.location_frequency,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      // eslint-disable-next-line no-bitwise
      valueGetter: ({row}) => row.commtrac_external_id & beltAddressMask,
    },
    {
      field: 'output',
      headerName: 'Output Status',
      sortable: true,
      renderCell: ({row}) => {
        const outputStatusText =
          parseInt(getBeltNodeStatus("Misc Output 1", beltData?.output_bit_label, row.output_bits)) +
          parseInt(getBeltNodeStatus("Misc Output 2", beltData?.output_bit_label, row.output_bits)) +
          parseInt(getBeltNodeStatus("Misc Output 3", beltData?.output_bit_label, row.output_bits))+
          parseInt(getBeltNodeStatus("Misc Output 4", beltData?.output_bit_label, row.output_bits))

        return (
          <Box sx={{whiteSpace: 'nowrap'}}>
            {outputStatusText > 0 ? 'Output(s) On' : ''}
          </Box>
        );
      },
    },
    {
      field: 'commtrac_date',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => row.commtrac_date,
    },
    {
      field: 'belt_status',
      headerName: 'Belt Status',
      sortable: true,
      valueGetter: ({row}) => row.run_status ? BELT_RUN_STATUS[row.run_status] : '',
    },
    {
      field: 'speed',
      headerName: 'Belt Speed',
      sortable: true,
      valueGetter: ({row}) => row.speed,
    },
    {
      field: 'motor1',
      headerName: 'Motor 1 Current',
      sortable: true,
      valueGetter: ({row}) => row.motor1,
    },
    {
      field: 'motor2',
      headerName: 'Motor 2 Current',
      sortable: true,
      valueGetter: ({row}) => row.motor2,
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields.all;
  }, [config]);

  useEffect(() => {
    const excludedFields = [
      'id',
      'name',
      'commtrac_date',
      'belt_sataus',
      'speed',
      'section',
      'lat',
      'lon',
    ];
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter(
              (col) => !!col.headerName && !excludedFields.includes(col.field)
            )
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: !shownFields?.includes(col.field),
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    onChange?.(
      update(config, {
        shownFields: {
          all: {$set: fields},
        },
      })
    );
  };

  // Multiple Select
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((item) => config.selectedIds?.includes(String(item.id))),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((item) => String(item.id)) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectItem = (id: string) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((item) => String(item.id)),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  const mapData = useMemo(() => {
    if (fetchedData?.items && config.selectedIds?.length) {
      return fetchedData?.items.filter((i) =>
        config.selectedIds?.includes(`${i.id}`)
      );
    }
    return [];
  }, [fetchedData, config.selectedIds, selectedItems]);

  const onUpdateRun = (pk: number, status: string) => {
    setOpenedUpdateBeltRunning({pk, status: status});
  };

  const onUpdateStatus = (pk: number, status: string, miscNum: number) => {
    miscRef.current?.submit(pk, status, miscNum);
  };

  // Refresh Interval
  const refreshPeriod = useConfiguration(
    'auto-refresh',
    'belt_grid_refresh'
  );

  return (
    <Box height="100%" width="100%">
      <ResizableColumns
        left={
          <>
            <Box
              display="flex"
              alignItems="center"
              height={70}
              minWidth={520}
              gap={2}
              p={2}
            />
            <Box height="100%">
              <Map
                panel="belt_nodes_report"
                beltsNodes={mapData}
                selectedMapLayers={config.mapLayers}
                selectedLevel={config.mapLevel}
                height="calc(100% - 70px)"
                minWidth={200}
                availableMapLayers={[
                  'employees',
                  'assets',
                  'street',
                  'mine',
                  'belts',
                ]}
                onSelectMapLayers={(v) => {
                  onChange?.(
                    update(config, {
                      mapLayers: {
                        $set: v,
                      },
                    })
                  );
                }}
                onSelectLevel={(v) => {
                  onChange?.(
                    update(config, {
                      mapLevel: {
                        $set: v,
                      },
                    })
                  );
                }}
                onOpenItem={(e) => {
                  const newBelt =
                    mapData.filter((it) => it.acknowledged === 0 && it.id === e)
                      ?.length === 1;
                  onOpenItem?.(e, 'belt', newBelt ? 'ack' : 'view');
                }}
                onOpenHistory={onOpenHistory}
                onGetClickCoordinates={onChangeLocationCoordinates}
              />
            </Box>
          </>
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box p={2} minWidth={800}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height={40}
              gap={2}
            >
              <AccessControl roles={[1, 2, 3]}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  width="100%"
                  height="100%"
                >
                  <ButtonGroup>
                    <BeltNodeItemUpsertButton
                      onSubmitted={() => {
                        onRefresh?.();
                        onChangeLocationCoordinates?.(undefined);
                      }}
                      locationCoordinates={locationCoordinates}
                      componentProps={{
                        color: 'primary',
                        size: 'small',
                        startIcon: <AddIcon />,
                      }}
                    >
                      Belt
                    </BeltNodeItemUpsertButton>

                    {/* <BeltNodeRefreshRateButton
                      componentProps={{
                        size: 'small',
                        sx: {
                          textAlign: 'center',
                        },
                      }}
                    /> */}

                    <BeltNodeImportExportButton
                      value={{...value, exportFields: getBeltMonitoringReportData().exportFields}}
                      component={Button}
                      componentProps={{color: 'primary'}}
                      onSubmitted={() => onRefresh?.()}
                    >
                      <ImportExportIcon />
                    </BeltNodeImportExportButton>

                    <Button onClick={() => onRefresh?.()}>
                      <Tooltip title="Refresh">
                        <RefreshIcon />
                      </Tooltip>
                    </Button>

                    {refreshPeriod ? (
                      <AutoRefreshSettingsSelect
                        refreshPeriod={refreshPeriod}
                      />
                    ) : null}
                    <Button onClick={() => {}}>
                      <PrintIcon
                        onClick={() => dataGridRef.current?.printTable()}
                      />
                    </Button>
                  </ButtonGroup>
                </Box>
              </AccessControl>
            </Box>
          </Box>
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={isLoading}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                  <AccessControl permissions={['post::/purge']}>
                    <Box display="flex" height={40}>
                      <BeltItemsPurgeButton
                        beltIds={selectedRows
                          .filter((i) => i.id)
                          .map((i) => i.id)
                          .filter(isPresent)}
                        componentProps={{size: 'small', variant: 'outlined'}}
                        onDone={() => onRefresh?.()}
                      >
                        <RemoveCircleOutlineIcon
                          fontSize="small"
                          sx={{mr: 1}}
                        />
                        Purge
                      </BeltItemsPurgeButton>
                    </Box>
                  </AccessControl>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
      {openedUpdateBeltRunning?.pk >= 0 && (
        <BeltNodeItemUpdateRunning
          open={openedUpdateBeltRunning.pk >= 0}
          pk={openedUpdateBeltRunning.pk}
          status={openedUpdateBeltRunning.status}
          onSubmitted={() => {
            onRefresh?.();
          }}
          onClose={() =>
            setOpenedUpdateBeltRunning({pk: -1, status: 'stopped'})
          }
        />
      )}
      <BeltNodeItemUpdateStatus
        ref={miscRef}
        onSubmitted={() => onRefresh?.()}
      />
    </Box>
  );
};
