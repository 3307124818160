/* eslint-disable no-bitwise */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {BeltEditInputBody} from '../../../interfaces/BeltMonitoring';
import {beltAddressMask, BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {beltAckInputSchema} from '../../../scheme/yup/belt-node';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import SnackbarMessages from '../../common/SnackbarMessages';
import {ZoneSelect} from '../../selectors/ZoneSelect';

interface Props {
  pk: number;
  item?: BeltMonitoringNode;
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: Function;
  onSubmitted?: (item: BeltMonitoringNode) => void;
}

const BeltNodeItemAcknowledge = ({
  pk,
  item,
  prefetch,
  locationCoordinates,
  isActiveModal = true,
  onCancel,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<BeltMonitoringNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<BeltMonitoringNode>(`${apiBaseUrl}/belt/${pk}`);
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: BeltEditInputBody) => {
    setSubmittedInProgress(true);

    const payload = {
      name: data.name,
      status: data.status,
      zone_id: data.zone_id,
      latitude: data.latitude,
      longitude: data.longitude

    }
    try {
      const endpoint = `${apiBaseUrl}/belt/${pk}/acknowledge`;
      const resp = await API.patch<BeltMonitoringNode>(endpoint, payload);
      const message = `Belt successfully acknowledged`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (item?: BeltMonitoringNode): BeltEditInputBody => ({
    name: item?.name ?? null,
    status: item?.status ?? null,
    network_id: item?.commtrac_external_id ?? null,
    zone_id: item?.zone_id ?? null,
    reporting_frequency: item?.reporting_frequency ?? null,
    location_frequency: item?.location_frequency ?? null,
    latitude: item?.latitude ?? null,
    longitude: item?.longitude ?? null,
  });

  const formik = useFormik<BeltEditInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: beltAckInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (isActiveModal && locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.latitude !== newPosLat ||
        formik.values.longitude !== newPosLong
      ) {
        formik.setFieldValue('latitude', newPosLat);
        formik.setFieldValue('longitude', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.name ?? ''}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.network_id ? formik.values.network_id & beltAddressMask : ''}
            label="Network Id"
            name="network_id"
            size="small"
            fullWidth
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <ZoneSelect
            value={formik.values.zone_id}
            label="Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            disabled
            value={formik.values.reporting_frequency ?? ''}
            label="Reporting Frequency"
            name="reporting_frequency"
            size="small"
            fullWidth
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            disabled
            value={formik.values.location_frequency ?? ''}
            label="Location Frequency"
            name="location_frequency"
            size="small"
            fullWidth
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.latitude ?? ''}
            label="Latitude"
            name="latitude"
            size="small"
            fullWidth
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.longitude ?? ''}
            label="Longitude"
            name="longitude"
            size="small"
            fullWidth
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel && fetchedData?.acknowledged === 1 ? (
          <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            color="warning"
          >
            Acknowledge
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BeltNodeItemAcknowledge;
