import {Alert, Backdrop, Box, CircularProgress} from '@mui/material';
import {Howl} from 'howler';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty, uniq, values} from 'lodash';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  useConfiguration,
  useConfigurations,
} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  EventListResponse,
  EventSummaryResponse,
} from '../../../interfaces/Event';
import {SafeyeNodeListResponse} from '../../../interfaces/SafeyeNode';
import reduxActions from '../../../redux/actions';
import reduxSelectors from '../../../redux/selectors';
import {getMsFromS} from '../../../utils/datetime';
import {HAZARD_AI_EVENT_SOUNDS} from '../../../utils/events';
import {
  OpenedSafeyeNode,
  OpenedSafeyeNodeMode,
} from '../../../utils/hazardAIPanel';
import {SAFEYE_NANO_EVENT_SOUNDS} from '../../../utils/safeyeNodes';
import {SOUNDS} from '../../../utils/sounds';
import {isPresent} from '../../../utils/type-guards';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import ModalDraggable from '../../common/ModalDraggable';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import SafeyeNodeItemUpsert from '../../safeye-node/SafeyeNodeItemUpsert';
import {DashboardHistoryReportType} from '../DashboardPanelItem';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import EventsReport, {
  EventsReportData,
  getEventsReportData,
} from './EventsReport';
import {HazardAITabs} from './HazardAITabs';
import WarningHazardReport, {
  getWarningHazardsData,
} from './WarningHazardReport';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string | number[],
    type: DashboardHistoryReportType
  ) => void;
}

interface HazardPanelData {
  viewType: 'warnings-hazards' | 'events';
  warningHazards: any; // TODO Make a proper type
  events: EventsReportData;
}

const getHazardPanelData = (): HazardPanelData => ({
  viewType: 'warnings-hazards',
  warningHazards: getWarningHazardsData(),
  events: getEventsReportData(),
});

export const HazardAI = ({value, onUpdate, onOpenHistory}: Props) => {
  const reduxDispatch = useDispatch();

  // Constants
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const configurations = useConfigurations();
  const currentSoundsNames = Object.values({
    ...SAFEYE_NANO_EVENT_SOUNDS,
    globalSound: 'hazard-ai.sound_new_event',
  }).map((name) => configurations[name]?.value || '');

  // Panel config
  const [config, setConfig] = useState<HazardPanelData>(
    !isEmpty(value) ? (value as HazardPanelData) : getHazardPanelData()
  );

  // Constants
  const safeyeNodesRefresh = useConfiguration(
    'hazard-ai',
    'hazard_ai_autorefresh_rate'
  );
  const safeyeNodesRefreshInterval = safeyeNodesRefresh?.value
    ? getMsFromS(+safeyeNodesRefresh?.value)
    : null;

  // Safeye Nodes
  const [fetchedSafeyeNodes, setFetchedSafeyeNodes] =
    useState<SafeyeNodeListResponse>();
  const [fetchedSafeyeNodesErrors, setFetchedSafeyeNodesErrors] = useState<
    string[]
  >([]);
  const [fetchedSafeyeNodesInProgress, setFetchedSafeyeNodesInProgress] =
    useState(false);

  const fetchSafeyeNodes = useCallback(async () => {
    setFetchedSafeyeNodesInProgress(true);
    setFetchedSafeyeNodesErrors([]);

    try {
      const params = {
        ...config.warningHazards.params,
      };

      const resp = await API.get<SafeyeNodeListResponse>(
        `${apiBaseUrl}/safeye-nano-node`,
        {params}
      );
      setFetchedSafeyeNodes(resp?.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedSafeyeNodesErrors(messages);
    }
    setFetchedSafeyeNodesInProgress(false);
  }, [config.warningHazards.params, config.viewType]);

  useEffect(() => {
    fetchSafeyeNodes();
    onUpdate?.({...config});
  }, [config.warningHazards.params]);

  useEffect(() => {
    onUpdate?.({...config});
  }, [config.warningHazards.mapType, config.warningHazards.objectType]);

  useEffect(() => {
    // api call to reload tab contents
    onUpdate?.({...config});
    fetchEvents();
  }, [config.viewType]);

  useRefreshInterval(fetchSafeyeNodes, safeyeNodesRefreshInterval);

  // Opened Safeye Nodes
  const [openedSafeyeNodes, setOpenedSafeyeNodes] = useState<OpenedSafeyeNode>(
    {}
  );

  const handleOpenItem = (id: number, mode?: OpenedSafeyeNodeMode) => {
    if (!(id in openedSafeyeNodes)) {
      setOpenedSafeyeNodes({
        ...openedSafeyeNodes,
        [id]: {mode, id},
      });
    }
  };

  // Events
  const [fetchedEentsData, setFetchedEentsData] = useState<EventListResponse>();
  const [fetchedEventsErrors, setFetchedEventsErrors] = useState<string[]>([]);
  const [fetchedEventsInProgress, setFetchedEventsInProgress] = useState(false);

  const fetchEvents = useCallback(async () => {
    setFetchedEventsInProgress(true);
    setFetchedEventsErrors([]);
    try {
      const params = {
        ...config.events.params,
        limit: config.viewType !== 'events' ? 1 : config.events.params.limit,
        product: ['hazard_ai'],
      };
      const resp = await API.get<EventListResponse>(`${apiBaseUrl}/event`, {
        params,
      });
      setFetchedEentsData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventsErrors(messages);
    }
    setFetchedEventsInProgress(false);
  }, [config.viewType, config.events.params]);

  useEffect(() => {
    fetchEvents();
    onUpdate?.({...config});
  }, [config.events.params]);

  const eventRefresh = useConfiguration(
    'auto-refresh',
    'event_summary_autorefresh_rate'
  );
  const eventRefreshInterval = eventRefresh?.value
    ? getMsFromS(+eventRefresh?.value)
    : null;
  useRefreshInterval(fetchEvents, eventRefreshInterval);

  // Events Summary Data
  const [fetchedEventSummaryData, setFetchedEventSummaryData] =
    useState<EventSummaryResponse>();
  const [fetchedEventSummaryErrors, setFetchedEventSummaryErrors] = useState<
    string[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchedEventSummaryInProgress, setFetchedEventSummaryInProgress] =
    useState(false);

  const fetchEventSummary = useCallback(async () => {
    setFetchedEventSummaryInProgress(true);
    setFetchedEventSummaryErrors([]);
    try {
      const params = {
        date_start: config.events.params.date_start,
        date_end: config.events.params.date_end,
      };
      const resp = await API.get<EventSummaryResponse>(
        `${apiBaseUrl}/event/summary`,
        {params}
      );
      setFetchedEventSummaryData(resp.data);
      const soundFile = getSoundFilesFromEventSummary(resp.data);
      setSoundFiles(soundFile);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventSummaryErrors(messages);
    }
    setFetchedEventSummaryInProgress(false);
  }, [
    config.events.params.date_start,
    config.events.params.date_end,
    JSON.stringify(currentSoundsNames),
  ]);

  const eventSummaryRefresh = useConfiguration(
    'hazard-ai',
    'hazard_ai_sound_interval'
  );

  const eventSummaryRefreshInterval = eventSummaryRefresh?.value
    ? getMsFromS(+eventSummaryRefresh?.value)
    : null;

  useRefreshInterval(fetchEventSummary, eventSummaryRefreshInterval);

  useEffect(() => {
    fetchEventSummary();
  }, [config.events.params.date_start, config.events.params.date_end]);

  // SOUND NOTIFICATIONS
  const playedEventIds = useAppSelector(({app}) => app.playedEventIds);

  const newEventSoundConfiguration = useConfiguration(
    'hazard-ai',
    'sound_new_event'
  );

  const getSoundFilesFromEventSummary = (
    eventSummary: EventSummaryResponse
  ) => {
    const eventTypes = uniq(eventSummary.hazard_ai.sound.map((i) => i.type));

    const soundNames = eventTypes
      .map((item) => configurations[HAZARD_AI_EVENT_SOUNDS[item] ?? '']?.value)
      .filter(isPresent);

    const newEvents = eventSummary.hazard_ai.sound.filter(
      (i) => !playedEventIds.includes(i.id)
    );

    soundNames.unshift(newEventSoundConfiguration?.value ?? '');

    if (newEvents.length) {
      reduxActions.app.setApp(reduxDispatch, {
        playedEventIds: [...playedEventIds, ...newEvents.map((i) => i.id)],
      });
    }
    return uniq(soundNames.map((i) => SOUNDS[i]).filter(isPresent));
  };

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const muteSounds = useAppSelector(reduxSelectors.app.getSoundMuteMode);
  const [soundFiles, setSoundFiles] = useState<any[]>([]);
  const sound = useMemo(() => {
    const src = soundFiles[0];
    return src ? new Howl({src}) : null;
  }, [soundFiles]);

  useEffect(() => {
    if (sound) {
      if (muteSounds) {
        const message =
          'New event received, please enable sounds to miss nothing.';
        enqueueSnackbar(message, {
          variant: 'warning',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
        return;
      }

      if (sound.state() === 'loaded') {
        sound.play();
      } else {
        sound.once('load', () => sound.play());
      }
      sound.once('end', () => {
        setSoundFiles(soundFiles.slice(1));
      });

      return () => {
        sound.unload();
      };
    }
  }, [sound]);

  const [panel] = usePanel();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
      id="hazard-ai-summary"
      bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>
      <Box>
        <HazardAITabs
          activeTab={config.viewType}
          objectType={config.warningHazards.objectType}
          detectionData={fetchedSafeyeNodes?.detection}
          isEventSummaryLoading={fetchedEventSummaryInProgress}
          isEventsLoading={fetchedEventsInProgress}
          onChangeActiveTab={(v) => {
            if (config.viewType !== v) {
              setConfig(
                update(config, {
                  viewType: {
                    $set: v,
                  },
                })
              );
            }
          }}
          onChangeObjectType={(v) => {
            setConfig(
              update(config, {
                warningHazards: {
                  objectType: {
                    $set: v,
                  },
                },
              })
            );
          }}
          totalEventsNumber={fetchedEventSummaryData?.hazard_ai?.count}
          unacknowledgedEventsNumber={
            fetchedEventSummaryData?.hazard_ai?.unacked
          }
        />
      </Box>

      <Box
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        position="relative"
        bgcolor={isDarkMode ? '#2E2E2E' : '#FFF'}
      >
        <Box>
          {fetchedSafeyeNodesErrors.map((error, idx) => (
            <Alert
              key={`error-c-${idx}`}
              severity="error"
              onClose={fetchSafeyeNodes}
            >
              {error}
            </Alert>
          ))}
          {fetchedEventsErrors.map((error, idx) => (
            <Alert
              key={`error-e-${idx}`}
              severity="error"
              onClose={fetchEvents}
            >
              {error}
            </Alert>
          ))}
          {fetchedEventSummaryErrors.map((error, idx) => (
            <Alert
              key={`error-e-${idx}`}
              severity="error"
              onClose={fetchEventSummary}
            >
              {error}
            </Alert>
          ))}
        </Box>
        <Box height="100%" overflow="hidden">
          {config.viewType === 'warnings-hazards' ? (
            <>
              <Backdrop
                open={fetchedSafeyeNodesInProgress}
                sx={{position: 'absolute', zIndex: 1199}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <WarningHazardReport
                value={config.warningHazards}
                onOpenHistory={onOpenHistory}
                onRefresh={fetchSafeyeNodes}
                isLoading={fetchedSafeyeNodesInProgress}
                fetchedData={fetchedSafeyeNodes}
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      warningHazards: {
                        $set: v ?? getWarningHazardsData(),
                      },
                    })
                  );
                }}
                onOpenItem={handleOpenItem}
              />
            </>
          ) : config.viewType === 'events' ? (
            <>
              <Backdrop open={false} sx={{position: 'absolute', zIndex: 1199}}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <EventsReport
                value={config.events}
                loading={fetchedEventsInProgress}
                fetchedData={fetchedEentsData}
                onRefresh={fetchEvents}
                onChange={(v) =>
                  setConfig(
                    update(config, {
                      events: {
                        $set: v ?? getEventsReportData(),
                      },
                    })
                  )
                }
              />
            </>
          ) : null}
        </Box>
      </Box>

      {values(openedSafeyeNodes).map(({id, mode}) => (
        <ModalDraggable key={`safeye-${id}`} open>
          <SafeyeNodeItemUpsert
            pk={id}
            mode={mode}
            onPurged={() => {
              fetchSafeyeNodes();
              setOpenedSafeyeNodes(update(openedSafeyeNodes, {$unset: [id]}));
            }}
            onSubmitted={() => {
              fetchSafeyeNodes();
            }}
            onClose={() => {
              setOpenedSafeyeNodes(update(openedSafeyeNodes, {$unset: [id]}));
            }}
          />
        </ModalDraggable>
      ))}
    </Box>
  );
};
