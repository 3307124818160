/* eslint-disable no-bitwise */
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import Lightbulb from '@mui/icons-material/Lightbulb';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, Button, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import {useState} from 'react';

import Spanner from '../../../images/belts/spanner/Spanner';
import {WifiAIcon} from '../../../images/belts/wifi-icons/WifiAIcon';
import {WifiBIcon} from '../../../images/belts/wifi-icons/WifiBIcon';
import {WifiDIcon} from '../../../images/belts/wifi-icons/WifiDIcon';
import {BELT_RUN_STATUS} from '../../../interfaces/BeltMonitoring';
import {beltAddressMask, BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import { OpenedEntityMode, OpenedEntityType } from '../../../utils/connect-view-panel';

interface Props {
  item: BeltMonitoringNode;
  items?: BeltMonitoringNode[];
  onOpenItem?: (id: number, type: OpenedEntityType, mode: OpenedEntityMode) => void;
  onOpenHistory?: (
    id: number,
    type: 'beltHistoryReport' | 'beltLocationHistoryReport'
  ) => void;
}

const BeltNodeTooltipContent = ({
  item,
  items,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const generate_ICON_BELT_BY_601 = () => {
    if (item.e_601) {
      return (
        <Tooltip title="">
          <Lightbulb sx={{color: 'black', fontSize: 20}} />
        </Tooltip>
      );
    }
  };

  const generate_ICON_BELT_BY_602 = () => {
    if (item.e_602) {
      return (
        <Tooltip title="Input Fault">
          <WarningIcon sx={{color: 'red', fontSize: 20}} />
        </Tooltip>
      );
    }
  };

  const generate_ICON_BELT_BY_606 = () => {
    if (item.e_606) {
      return (
        <Tooltip title="">
          <DoDisturbIcon sx={{color: 'red', fontSize: 20}} />
        </Tooltip>
      );
    }
  };

  const generate_ICON_BELT_BY_603 = () => {
    if (item.e_603) {
      return (
        <Tooltip title="">
          <WifiDIcon />
        </Tooltip>
      );
    }
  };

  const generate_ICON_BELT_BY_604 = () => {
    if (item.e_604) {
      return (
        <Tooltip title="">
          <WifiBIcon color="black"/>
        </Tooltip>
      );
    }
  };

  const generate_ICON_BELT_BY_605 = () => {
    if (item.e_605) {
      return (
        <Tooltip title="">
          <WifiAIcon />
        </Tooltip>
      );
    }
  };

  const generate_ICON_BELT_BY_610 = () => {
    if (item.e_610) {
      return (
        <Tooltip title="">
          <OpenWithIcon sx={{fontSize: 20}} />
        </Tooltip>
      );
    }
  };

  const generate_ICON_BELT_BY_607 = () => {
    if (item.e_607) {
      return (
        <Tooltip title="">
          <Spanner />
        </Tooltip>
      );
    }
  };

  if ((items?.length ?? 0) > 1) {
    return (
      <>
        {items?.map((it, no) => {
          return (
            <Box key={it.id}>
              {no + 1}. Name: {it.name}, Network ID: {it.commtrac_external_id & beltAddressMask},{' '}
              {item.commtrac_date}
            </Box>
          );
        })}
      </>
    );
  } else if ((items?.length ?? 0) < 2) {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box>Name: {item?.name ?? '-'}</Box>
        <Box>Network ID: {item.commtrac_external_id & beltAddressMask}</Box>
        <Box>Speed: {item.speed ?? '-'}</Box>
        <Box>Status: {item?.run_status ? BELT_RUN_STATUS[item?.run_status] : ''}</Box>
        <Box>Time Stamp: {item.commtrac_date ?? '-'}</Box>
        <Box display="flex" alignItems="center">
          {generate_ICON_BELT_BY_601()}
          {generate_ICON_BELT_BY_602()}
          {generate_ICON_BELT_BY_606()}
          {generate_ICON_BELT_BY_603()}
          {generate_ICON_BELT_BY_604()}
          {generate_ICON_BELT_BY_605()}
          {generate_ICON_BELT_BY_610()}
          {generate_ICON_BELT_BY_607()}
        </Box>
        <Box display="flex" my={1}>
          <Box position="relative">
            <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
              History
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onBackdropClick={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  onOpenHistory?.(item?.id, 'beltHistoryReport');
                  setAnchorEl(null);
                }}
              >
                Belt History
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onOpenHistory?.(item?.id, 'beltLocationHistoryReport');
                  setAnchorEl(null);
                }}
              >
                Belt Location History
              </MenuItem>
            </Menu>
          </Box>
          <IconButton
            sx={{
              marginLeft: 'auto',
            }}
            size="small"
            onClick={() => onOpenItem?.(item.id, 'belt', item.acknowledged === 0 ? 'ack' : 'view')}
          >
            <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
          </IconButton>
        </Box>
      </Box>
    );
  }
  return null;
};

export default BeltNodeTooltipContent;
