import {decToHex} from './bigint';

export const getHumanReadable = (v: string) => {
  if (!v) {
    return v;
  }

  let a: string | null;

  if (Number.isSafeInteger(v)) {
    a = parseInt(v).toString(16);
  } else {
    a = decToHex(v);
  }

  if (!a) {
    return v;
  }

  if (a.length < 12) {
    a = '0'.repeat(12 - a.length) + a;
  }

  if (a.length > 12 && a.length < 16) {
    a = '0'.repeat(16 - a.length) + a;
  }

  return a.match(/.{1,2}/g)?.join(':');
};

export const validateMacAddress = (v: string | number) => {
  return (
    v &&
    !!v
      .toString()
      .match(
        /^(?:(?:[0-9a-f]{2}[:]{1}){5}|(?:[0-9a-f]{2}[:]{1}){7}|(?:[0-9a-f]{2}[-]{1}){5}|(?:[0-9a-f]{2}[-]{1}){7}|(?:[0-9a-f]{2}){5}|(?:[0-9a-f]{2}){7})[0-9a-f]{2}$/i
      )
  );
};

export const networkIdFromMacAddress = (macAddress: string) => {
  const cleanedMacAddress = macAddress.replace(/[^a-fA-F0-9]/g, '');
  const decimalNumber = parseInt(cleanedMacAddress, 16);
  return decimalNumber;
};

export const tweakLabelForMiner = (v: string | undefined | null) => {
  return v?.replace(/miner/i, 'Employee') ?? v;
};
