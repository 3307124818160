import {Box} from '@mui/material';
import Image from 'mui-image';

import wifiaIcon from './wifia.svg'

interface Props {
  color?: 'red' | 'white' | 'black';
  size?: number;
}

export const WifiAIcon = ({ color = 'red', size = 20 }: Props) => {
    return (
      <Box
        sx={{
          '& img': {transition: 'none !important', animation: 'none !important'},
          transition: 'none !important',
          color: color
        }}
      >
        <Image
          src={wifiaIcon}
          alt="wi-fi-a"
          width={size}
          height={size}
        />
      </Box>
    );
};

export default WifiAIcon;
