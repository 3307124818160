/* eslint-disable complexity */
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Tab,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {cloneDeep, isEqual} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {BaseEventType} from '../../interfaces/Event';
import {
  User,
  UserEventSubscriptionListResponse,
  UserInputBody,
  UserItemResponse,
  UserPermissionsListInput,
  UserPermissionsListReponse,
} from '../../interfaces/User';
import reduxSelectors from '../../redux/selectors';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  pk?: number;
  item?: User;
  prefetch?: boolean;
  tab?: TabValue;
  onClose?: () => void;
  onSubmitted?: (item: User) => void;
}

type TabValue = 'general' | 'acl' | 'subscription';

interface TabItem {
  value: TabValue;
  label: string;
  visible: boolean;
}

const UserItemUpsert = ({
  pk,
  item,
  prefetch,
  onClose,
  onSubmitted,
  tab: initialTab = 'general',
}: Props) => {
  const me = useAppSelector(({app}) => app.me);
  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<UserItemResponse | undefined>(
    cloneDeep(item)
  );
  const [aclData, setAclData] = useState<UserPermissionsListInput>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const [eventSubscriptions, setEventSubscriptions] = useState<number[]>([]);
  const [fetchingEventSubscription, setFetchingEventSubscription] =
    useState(false);
  const [submittingEventSubscription, setSubmittingEventSubscroption] =
    useState(false);
  const [pageSize, setPageSize] = useState(10);

  const myPermissions = useAppSelector(({app}) => app.me?.permissions);

  const canManageAcl = useMemo(() => {
    return fetchedData?.type_id === 5;
  }, [fetchedData]);

  const canManageSubscription = useMemo(() => {
    return !!pk;
  }, [pk]);

  const tabs: TabItem[] = [
    {
      value: 'general',
      label: 'General',
      visible: true,
    },
    {
      value: 'acl',
      label: 'ACL',
      visible:
        !!myPermissions?.includes('get::/user/:user_id/permissions') &&
        canManageAcl,
    },
    {
      value: 'subscription',
      label: 'Event Subscriptions',
      visible:
        !!myPermissions?.includes('get::/user/:id(\\d+)/event-subscription') &&
        canManageSubscription,
    },
  ];

  const visibleTabs = tabs.filter((i) => i.visible);

  const [activeTab, setActiveTab] = useState(initialTab);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<UserItemResponse>(`${apiBaseUrl}/user/${pk}`);
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  const fetchAclData = async () => {
    setFetchedInProgress(true);

    try {
      const acl = await API.get<UserPermissionsListReponse>(
        `${apiBaseUrl}/user/${pk}/permissions`
      );
      acl.data.sort((a, b) => (a.order > b.order ? 1 : -1));
      setAclData({permissions: acl.data});
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  const fetchEventSubscriptions = async () => {
    setFetchingEventSubscription(true);
    try {
      const eventSubscriptions =
        await API.get<UserEventSubscriptionListResponse>(
          `${apiBaseUrl}/user/${pk}/event-subscription`
        );
      setEventSubscriptions(
        eventSubscriptions.data?.map((it) => it?.event_type)
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchingEventSubscription(false);
    }
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  useEffect(() => {
    if (activeTab === 'acl') {
      fetchAclData();
    } else if (activeTab === 'subscription') {
      fetchEventSubscriptions();
    }
  }, [activeTab]);
  const dataGridRef = useRef<DataGridRef>(null);
  const columns: DataGridColumn<BaseEventType>[] = [
    {
      field: 'name',
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Status',
      sxHeader: {textAlign: 'right'},
      sxCell: {textAlign: 'right'},
      renderCell: ({row}) => {
        return (
          <Box display="flex" gap={1} justifyContent="end">
            <Checkbox
              color="primary"
              checked={eventSubscriptions?.includes(row.type)}
              onChange={() => {
                console.log(row.type);
                if (eventSubscriptions?.includes(row.type)) {
                  const items = cloneDeep(eventSubscriptions);
                  items.splice(items?.indexOf(row.type), 1);
                  setEventSubscriptions(items);
                } else {
                  setEventSubscriptions([...eventSubscriptions, row.type]);
                }
              }}
            />
          </Box>
        );
      },
    },
  ];

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: UserInputBody) => {
    setSubmittedInProgress(true);

    try {
      const input = {
        ...data,
        password: data.password ?? undefined,
        description:
          typeof data.description === 'string' ? data.description : undefined,
      };
      const endpoint = pk ? `${apiBaseUrl}/user/${pk}` : `${apiBaseUrl}/user`;
      const resp = pk
        ? await API.patch<User>(endpoint, input)
        : await API.post<User>(endpoint, input);
      const message = `User has been ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  const submitAclData = async (data: UserPermissionsListInput) => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/user/${pk}/permissions`;
      const resp = await API.post<UserPermissionsListReponse>(endpoint, data);
      resp.data.sort((a, b) => (a.order > b.order ? 1 : -1));
      setAclData({permissions: resp.data});

      const message = `User ACL has been ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  const submitSubscriptions = async () => {
    setSubmittingEventSubscroption(true);
    try {
      const endpoint = `${apiBaseUrl}/user/${pk}/event-subscription`;

      const resp = await API.patch<UserEventSubscriptionListResponse>(
        endpoint,
        {
          event_type: eventSubscriptions,
        }
      );
      setEventSubscriptions(resp.data?.map((it) => it?.event_type));
      const message = `User Event Subscriptions have been updated.`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } finally {
      setSubmittingEventSubscroption(false);
    }
    console.log(eventSubscriptions);
  };
  /*********/
  /* input */
  /*********/
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      name: yup.string().nullable().required('Field is required'),
      username: yup.string().nullable().required('Field is required'),
      email: yup.string().nullable().required('Field is required'),
      status: yup.string().nullable().required('Field is required'),
      type_id: yup.number().nullable().required('Field is required'),
      is_rest: yup.boolean().nullable().required('Field is required'),
      password: yup
        .string()
        .nullable()
        .min(6, 'Password is too short - should be 6 characters minimum.')
        .matches(
          /(?=.*[A-Z])/,
          'Password should have at least 1 Uppercase Letter.'
        )
        .matches(/(?=.*\d)/, 'Password should have at least 1 Number.')
        .matches(
          /(?=.*\W)/,
          'Password should have at least 1 Special Character.'
        ),
      rePassword: yup
        .string()
        .nullable()
        .oneOf([yup.ref('password')], 'Passwords must match'),
    };

    if (!pk) {
      fields.password = yup
        .string()
        .nullable()
        .required('Password is required')
        .min(6, 'Password is too short - should be 6 characters minimum.')
        .matches(
          /(?=.*[A-Z])/,
          'Password should have at least 1 Uppercase Letter.'
        )
        .matches(/(?=.*\d)/, 'Password should have at least 1 Number.')
        .matches(
          /(?=.*\W)/,
          'Password should have at least 1 Special Character.'
        );

      fields.rePassword = yup
        .string()
        .nullable()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Please re-type password');

      fields.email = yup
        .string()
        .email('Invalid email')
        .nullable()
        .required('Field is required');
      fields.reEmail = yup
        .string()
        .nullable()
        .oneOf([yup.ref('email'), null], 'Email must match')
        .required('Please re-type email');
      fields.description = yup
        .string()
        .nullable()
        .required('Field is required');
    }

    return yup.object().shape(fields);
  }, [pk]);

  const getFormikValues = (
    item?: User
  ): UserInputBody & {rePassword: string | null; reEmail: string | null} => ({
    name: item?.name ?? null,
    username: item?.username ?? null,
    email: item?.email ?? null,
    reEmail: null,
    password: null,
    rePassword: null,
    status: item?.status ?? 'active',
    type_id: item?.type_id ?? null,
    is_rest: item?.is_rest ?? false,
    description: item?.description ?? null,
  });

  const formik = useFormik<
    UserInputBody & {rePassword: string | null; reEmail: string | null}
  >({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  const formikAcl = useFormik<UserPermissionsListInput>({
    initialValues: aclData ? aclData : {permissions: []},
    onSubmit: async (values) => {
      await submitAclData(values);
    },
  });

  useEffect(() => {
    const newFormikValues = getFormikValues(fetchedData);
    if (!isEqual(newFormikValues, formik.values)) {
      formik.setValues(newFormikValues);
    }
  }, [fetchedData]);

  useEffect(() => {
    formikAcl.setValues(aclData ? aclData : {permissions: []});
  }, [aclData]);

  const ActionIcon = pk ? EditIcon : AddIcon;
  const actionName = pk ? 'Update' : 'Create';

  return (
    <Box position="relative" gap={3} p={3}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" width="100%" gap={1.5}>
          <ActionIcon sx={{color: 'primary.main'}} />
          <Box fontSize={24}>{actionName} User</Box>
        </Box>
        {onClose ? (
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {visibleTabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box
            component="form"
            position="relative"
            onSubmit={formik.handleSubmit}
          >
            <Box my={4}>
              <TextField
                value={formik.values.name ?? ''}
                fullWidth
                name="name"
                label="Name"
                size="small"
                error={!!formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(event) => {
                  formik.setFieldValue('name', event.target.value || null);
                }}
              />
            </Box>
            <Box my={4}>
              <TextField
                value={formik.values.username ?? ''}
                fullWidth
                name="username"
                label="Username"
                size="small"
                error={!!formik.touched.username && !!formik.errors.username}
                helperText={formik.touched.username && formik.errors.username}
                onChange={(event) => {
                  formik.setFieldValue('username', event.target.value || null);
                }}
              />
            </Box>
            <Box my={4}>
              <TextField
                value={formik.values.email ?? ''}
                fullWidth
                name="email"
                label="Email"
                size="small"
                disabled={!!pk}
                error={!!formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                onChange={(event) => {
                  formik.setFieldValue('email', event.target.value || null);
                }}
              />
            </Box>
            {!pk && (
              <Box my={4}>
                <TextField
                  value={formik.values.reEmail ?? ''}
                  fullWidth
                  name="reEmail"
                  label="Confirm Email"
                  type="reEmail"
                  size="small"
                  error={!!formik.touched.reEmail && !!formik.errors.reEmail}
                  helperText={formik.touched.reEmail && formik.errors.reEmail}
                  onChange={(event) => {
                    formik.setFieldValue('reEmail', event.target.value || null);
                  }}
                />
              </Box>
            )}
            <Box my={4}>
              <TextField
                value={formik.values.password ?? ''}
                fullWidth
                name="password"
                label="Password"
                type="password"
                size="small"
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                onChange={(event) => {
                  formik.setFieldValue('password', event.target.value || null);
                }}
              />
            </Box>
            <Box my={4}>
              <TextField
                value={formik.values.rePassword ?? ''}
                fullWidth
                name="rePassword"
                label="Confirm Password"
                type="password"
                size="small"
                error={
                  !!formik.touched.rePassword && !!formik.errors.rePassword
                }
                helperText={
                  formik.touched.rePassword && formik.errors.rePassword
                }
                onChange={(event) => {
                  formik.setFieldValue(
                    'rePassword',
                    event.target.value || null
                  );
                }}
              />
            </Box>
            <Box my={4}>
              <TextField
                value={formik.values.status ?? ''}
                fullWidth
                name="status"
                label="Status"
                select
                size="small"
                error={!!formik.touched.status && !!formik.errors.status}
                helperText={formik.touched.status && formik.errors.status}
                onChange={formik.handleChange}
              >
                {[
                  {value: 'active', name: 'Active'},
                  {value: 'inactive', name: 'Inactive'},
                ].map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {!pk && (
              <Box my={4}>
                <TextField
                  value={formik.values.type_id ?? null}
                  fullWidth
                  name="type_id"
                  label="Role"
                  select
                  size="small"
                  error={!!formik.touched.type_id && !!formik.errors.type_id}
                  helperText={formik.touched.type_id && formik.errors.type_id}
                  onChange={formik.handleChange}
                >
                  {assets.roles
                    .filter((i) => i.id > (me?.type_id ?? 0))
                    .map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
            )}
            <Box my={4}>
              <TextField
                value={formik.values.is_rest ? 1 : 0}
                fullWidth
                name="api"
                label="API User"
                select
                size="small"
                error={!!formik.touched.is_rest && !!formik.errors.is_rest}
                helperText={formik.touched.is_rest && formik.errors.is_rest}
                onChange={(event) =>
                  formik.setFieldValue('is_rest', !!event.target.value)
                }
              >
                {[
                  {value: 0, name: 'No'},
                  {value: 1, name: 'Yes'},
                ].map((i) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box my={4}>
              <TextField
                value={formik.values.description ?? ''}
                fullWidth
                name="description"
                label="Description"
                size="small"
                multiline
                rows={5}
                error={
                  !!formik.touched.description && !!formik.errors.description
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                onChange={(event) => {
                  formik.setFieldValue(
                    'description',
                    event.target.value || null
                  );
                }}
              />
            </Box>
            {fetchedErrors.map((error, index) => (
              <Alert key={index} severity="error" sx={{my: 2}}>
                {error}
              </Alert>
            ))}
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button onClick={() => onClose?.()}>Cancel</Button>

              <LoadingButton
                sx={{ml: 1}}
                variant="contained"
                loading={submittedInProgress}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value="acl" sx={{p: 0}}>
          <Box
            component="form"
            position="relative"
            onSubmit={formikAcl.handleSubmit}
          >
            {aclData?.permissions.map((el, index) => (
              <Box key={el.id} my={4}>
                <TextField
                  value={
                    formikAcl.values.permissions.filter(
                      (e) => e.id === el.id
                    )[0]?.allowed ?? ''
                  }
                  fullWidth
                  name={el.id}
                  label={el.label}
                  select
                  size="small"
                  key={el.id}
                  onChange={(event) => {
                    formikAcl.setFieldValue(
                      `permissions[${index}]`,
                      {id: el.id, allowed: event.target.value} || null
                    );
                  }}
                >
                  {[
                    {value: true, name: 'Enabled'},
                    {value: false, name: 'Disabled'},
                  ].map((i) => (
                    <MenuItem key={i.name} value={i.value as any}>
                      {i.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            ))}
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button onClick={() => onClose?.()}>Cancel</Button>

              <LoadingButton
                sx={{ml: 1}}
                variant="contained"
                loading={submittedInProgress}
                type="submit"
              >
                Save ACL
              </LoadingButton>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value="subscription">
          <DataGrid
            ref={dataGridRef}
            rows={assets?.eventBaseTypes}
            pageSize={pageSize}
            onPageSizeChange={(e) => {
              setPageSize(e);
            }}
            columns={columns}
            loading={fetchingEventSubscription}
            pagination
            paginationMode="client"
            size="small"
            sortingMode="client"
          />
          <Backdrop
            open={fetchingEventSubscription}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: 2}}>
            <Button onClick={() => onClose?.()}>Cancel</Button>

            <LoadingButton
              sx={{ml: 1}}
              variant="contained"
              loading={submittingEventSubscription}
              type="button"
              onClick={() => submitSubscriptions()}
            >
              Save Event Subscriptions
            </LoadingButton>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default UserItemUpsert;
