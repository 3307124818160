import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SaveIcon from '@mui/icons-material/Save';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import {LoadingButton} from '@mui/lab';
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Menu,
  MenuItem,
  Modal,
} from '@mui/material';
import MuiAppBar, {AppBarProps} from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container, {ContainerProps} from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {styled} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Image from 'mui-image';
import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink, useNavigate} from 'react-router-dom';

import AccessControl from '../components/common/AccessControl';
import Status from '../components/common/Status';
import {CompanyListButton} from '../components/company/buttons/CompanyListButton';
import CompanySelectManager from '../components/profile/CompanySelectManager';
import MyAccount from '../components/profile/MyAccount';
import MyDarkModeSwitcher from '../components/profile/MyDarkModeSwitcher';
import {ProfileSettingsModal} from '../components/profile/ProfileSettingsModal';
import SoundManager from '../components/profile/SoundManager';
import {COMPANY_SUBMENU, CompanySubMenu} from '../constants/menus';
import {useAppSelector} from '../hooks/redux';
import reduxActions from '../redux/actions';
import reduxSelectors from '../redux/selectors';
import {checkPrivilegeAction} from '../utils/dashboard';
import {LOGO} from '../utils/logo';

interface Props {
  children?: ReactNode;
  contentContainerProps?: ContainerProps;
}

const drawerWidth: number = 240;

interface DashboardAppBarProps extends AppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DashboardAppBarProps>(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    top: '64px',
    height: 'calc(100% - 64px)',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    color: '#FFF',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.mode === 'dark' ? 'inheri' : '#185A7D',
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

export const DashboardLayout = ({children, contentContainerProps}: Props) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);
  const isLoggedIn = useAppSelector(reduxSelectors.auth.getLoggedIn);
  const assets = useAppSelector(({assets}) => assets);
  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;
  const connect_enabled = !!company?.commtrac_enabled;
  const proximity_enabled = !!company?.proximity_enabled;
  const alarm_enabled = !!company?.alarm_enabled;

  const me = useAppSelector(({app}) => app.me);
  const myConfigurations = useAppSelector(({app}) => app.myConfigurations);
  const currentCompanyId = useAppSelector(({app}) => app.companyId);
  const activedDashboard = useAppSelector(({app}) => app.activedDashboard);
  const inSubmittingInSavingDashboard = useAppSelector(
    ({app}) => app.inSubmittingInSavingDashboard
  );
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [openedMenuItems, setOpenedMenuItems] = React.useState<string[]>([]);

  const toggleDrawer = () => setSidebarOpened(!sidebarOpened);

  const toggleMenuItem = (item: string) => {
    if (!openedMenuItems.includes(item)) {
      setOpenedMenuItems([...openedMenuItems, item]);
    } else if (openedMenuItems.includes(item)) {
      setOpenedMenuItems(openedMenuItems.filter((i) => !i.includes(item)));
    }
  };

  const checkPrivilegeMenu = useCallback(
    (menuItem: CompanySubMenu) => {
      if ((menuItem?.required_products?.length ?? 0) === 0) {
        return true;
      }
      let results = false;
      if (hazard_ai_enabled) {
        results ||=
          menuItem?.required_products?.includes('hazard_ai_enabled') ?? true;
      }
      if (connect_enabled) {
        results ||=
          menuItem?.required_products?.includes('connect_enabled') ?? true;
      }
      if (proximity_enabled) {
        results ||=
          menuItem?.required_products?.includes('proximity_enabled') ?? true;
      }
      if (alarm_enabled) {
        results ||=
          menuItem?.required_products?.includes('alarm_enabled') ?? true;
      }
      return results;
    },
    [hazard_ai_enabled, connect_enabled, proximity_enabled, alarm_enabled]
  );

  const generateMenuItem = (menuList: Array<CompanySubMenu>, pos: String) => {
    return (
      <>
        {menuList.map(
          (menuItem, index) =>
            checkPrivilegeMenu(menuItem) &&
            (menuItem?.children?.length > 0 ? (
              <>
                <ListItemButton
                  key={index}
                  component={RouterLink}
                  to=""
                  sx={{height: '40px', pl: Number(`${menuItem?.depth + 9}`)}}
                  onClick={() => toggleMenuItem(`${pos}_${menuItem?.name}`)}
                >
                  <ListItemText
                    primary={menuItem?.name}
                    primaryTypographyProps={{fontSize: 14, maxWidth: 100}}
                  />
                  {openedMenuItems.includes(`${pos}_${menuItem?.name}`) ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItemButton>
                <Collapse
                  in={openedMenuItems.includes(`${pos}_${menuItem?.name}`)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      borderColor: isDarkMode ? 'grey.800' : 'grey.200',
                    }}
                  >
                    {generateMenuItem(
                      menuItem?.children,
                      `${pos}_${menuItem?.name}`
                    )}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItemButton
                component={RouterLink}
                to={`${menuItem?.page}`}
                sx={{height: '40px', pl: Number(`${menuItem?.depth + 9}`)}}
              >
                <ListItemText
                  primary={menuItem?.name}
                  primaryTypographyProps={{
                    fontSize: 12,
                  }}
                />
              </ListItemButton>
            ))
        )}
      </>
    );
  };
  // fetch me
  const [isFetchConfiguratioinFailed, setIsFetchConfiguratioinFailed] =
    useState(false);
  const handleFetchConfigurations = async () => {
    setIsFetchConfiguratioinFailed(false);
    try {
      if (!me) {
        await reduxActions.app.fetchMe(reduxDispatch);
      }
      if (!myConfigurations) {
        await reduxActions.app.fetchMyConfigurations(reduxDispatch);
      }
    } catch {
      setIsFetchConfiguratioinFailed(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn && !me) {
      handleFetchConfigurations();
    }
  }, [isLoggedIn, me]);

  // set currentCompanyId
  useEffect(() => {
    if (isLoggedIn && me && !currentCompanyId) {
      reduxActions.app.setApp(reduxDispatch, {companyId: me.company_id});
    }
  }, [isLoggedIn, me, currentCompanyId]);

  // fetch assets
  const [isFetchAssetsFailed, setIsFetchAssetsFailed] = useState(false);
  const handleFetchAssets = async () => {
    setIsFetchAssetsFailed(false);
    try {
      await reduxActions.assets.fetchAssets(reduxDispatch);
    } catch {
      setIsFetchAssetsFailed(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn && me && currentCompanyId && !assets.isFetched) {
      handleFetchAssets();
    }
  }, [isLoggedIn, me, currentCompanyId, assets.isFetched]);

  useEffect(() => {
    setOpenedMenuItems([]);
  }, [sidebarOpened]);

  useEffect(() => {
    if (openedMenuItems.length && !sidebarOpened) {
      setSidebarOpened(true);
    }
  }, [openedMenuItems]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/auth/signin');
    }
  }, [isLoggedIn]);

  // Profile Menu
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const profileMenuOpened = Boolean(profileMenuAnchorEl);
  const [profileSettingsOpened, setProfileSettingsOpened] = useState(false);

  // My Account
  const [myAccountOpened, setMyAccountOpened] = useState(false);
  const myAccountStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #9b9b9b',
    boxShadow: 24,
    p: 3,
    overflowY: 'auto',
    maxHeight: '100%',
  };

  const onAddItem = () => {
    document.getElementById('btn-add-new-item')?.click();
  };

  const onShareToRole = () => {
    document.getElementById('btn-share-to-role')?.click();
  };

  const onSaveDashboard = () => {
    document.getElementById('btn-save-dashboard')?.click();
  };

  return assets.isFetched && currentCompanyId && me && myConfigurations ? (
    <>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />

        <AppBar
          open={sidebarOpened}
          position="absolute"
          sx={{
            background: (theme) =>
              theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
          }}
        >
          <Toolbar sx={{pr: '24px'}}>
            <IconButton
              edge="start"
              color="primary"
              onClick={toggleDrawer}
              sx={{
                mr: 2.2,
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'inherit' : '#185A7D',
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{mr: 2.2}}
              display="flex"
              columnGap="2px"
              alignItems="center"
            >
              <Image
                height={40}
                width="auto"
                src={isDarkMode ? LOGO.white_top : LOGO.black_top}
              ></Image>
              <Image
                height={25}
                width="auto"
                src={isDarkMode ? LOGO.white : LOGO.black}
              ></Image>
            </Box>

            <Typography noWrap fontSize={20} flexGrow={1} color="primary.main">
              {company?.name}
            </Typography>

            {activedDashboard && (
              <Typography
                noWrap
                fontSize={20}
                flexGrow={1}
                color="primary.main"
              >
                {activedDashboard?.name}
              </Typography>
            )}
            {activedDashboard && (
              <>
                <AccessControl
                  accessChecker={() =>
                    checkPrivilegeAction(me, activedDashboard, 'EDIT')
                  }
                >
                  <Button
                    sx={{minWidth: 'auto'}}
                    onClick={() => {
                      onAddItem();
                    }}
                  >
                    <AddIcon />
                  </Button>
                </AccessControl>

                <AccessControl
                  accessChecker={() =>
                    checkPrivilegeAction(me, activedDashboard, 'EDIT')
                  }
                >
                  <Button
                    sx={{minWidth: 'auto'}}
                    onClick={() => {
                      onShareToRole();
                    }}
                  >
                    <ShareIcon />
                  </Button>
                </AccessControl>

                <AccessControl
                  permissions={['patch::/dashboard/:id(\\d+)']}
                  accessChecker={() =>
                    checkPrivilegeAction(me, activedDashboard, 'EDIT')
                  }
                >
                  <LoadingButton
                    sx={{minWidth: 'auto'}}
                    loading={inSubmittingInSavingDashboard}
                    onClick={() => {
                      onSaveDashboard();
                    }}
                  >
                    <SaveIcon />
                  </LoadingButton>
                </AccessControl>
              </>
            )}

            <AccessControl permissions={['get::/company']}>
              <CompanySelectManager/>
            </AccessControl>

            <SoundManager/>

            <MyDarkModeSwitcher />

            <IconButton
              color="primary"
              onClick={(event) => setProfileMenuAnchorEl(event.currentTarget)}
            >
              <PersonIcon />
            </IconButton>

            <Menu
              anchorEl={profileMenuAnchorEl}
              open={profileMenuOpened}
              onBackdropClick={() => setProfileMenuAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setMyAccountOpened(true);
                  setProfileMenuAnchorEl(null);
                }}
              >
                <PersonIcon sx={{mr: 1.5}} />
                My account
              </MenuItem>

              <Divider />

              <MenuItem
                onClick={() => {
                  setProfileSettingsOpened(true);
                  setProfileMenuAnchorEl(null);
                }}
              >
                <SettingsIcon sx={{mr: 1.5}} />
                Settings
              </MenuItem>

              <MenuItem
                onClick={() => {
                  reduxActions.auth.clearAuthTokens(reduxDispatch);
                  reduxActions.app.clearApp(reduxDispatch);
                  reduxActions.assets.clearAssets(reduxDispatch);
                  setProfileMenuAnchorEl(null);
                }}
              >
                <LogoutIcon sx={{mr: 1.5}} />
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={sidebarOpened}>
          <List
            component="nav"
            disablePadding
            sx={{
              /**
               * To Do: Danylo should update with theme
               *
               */
              svg: {
                path: {
                  fill: '#FFF',
                },
              },
            }}
          >
            {company?.primary_dashboard && (
              <Box
                borderBottom="1px solid"
                borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
              >
                <ListItemButton component={RouterLink} to="/dashboards/primary">
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>

                  <ListItemText primary={company.primary_dashboard.name} />
                </ListItemButton>
              </Box>
            )}
            {me.favouriteDashboard?.map((dashboard) => (
              <Box
                key={dashboard.id}
                borderBottom="1px solid"
                borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
              >
                <ListItemButton
                  component={RouterLink}
                  to={`/dashboards/${dashboard.id}`}
                >
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>

                  <ListItemText primary={dashboard.name} />
                </ListItemButton>
              </Box>
            ))}
            <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton component={RouterLink} to="/dashboards">
                <ListItemIcon sx={{minWidth: 40}}>
                  <DashboardIcon />
                </ListItemIcon>

                <ListItemText primary="Dashboards" />
              </ListItemButton>
            </Box>
            <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton component={RouterLink} to="/panels">
                <ListItemIcon sx={{minWidth: 40}}>
                  <QueryStatsIcon />
                </ListItemIcon>

                <ListItemText primary="Panels" />
              </ListItemButton>
            </Box>
            {/* <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton component={RouterLink} to="/indicator/panel">
                <ListItemIcon>
                  <DragIndicatorIcon />
                </ListItemIcon>

                <ListItemText primary="Indicator Panel" />
              </ListItemButton>
            </Box> */}
            <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton onClick={() => toggleMenuItem('admin')}>
                <ListItemIcon sx={{minWidth: 40}}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Administration" />
                {openedMenuItems.includes('admin') ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItemButton>
            </Box>
            <Collapse
              in={openedMenuItems.includes('admin')}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                sx={{
                  borderBottom: '1px solid',
                  borderColor: isDarkMode ? 'grey.800' : 'grey.200',
                }}
              >
                <Box>
                  <AccessControl permissions={['get::/configuration']}>
                    <ListItemButton
                      component={RouterLink}
                      to="/admin/products"
                      sx={{height: '40px', pl: 9}}
                    >
                      <ListItemText
                        primary="Products"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </ListItemButton>
                  </AccessControl>

                  <AccessControl permissions={['get::/user']}>
                    <ListItemButton
                      component={RouterLink}
                      to="/admin/users"
                      sx={{height: '40px', pl: 9}}
                    >
                      <ListItemText
                        primary="Users"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </ListItemButton>
                  </AccessControl>

                  <ListItemButton
                    component={RouterLink}
                    to=""
                    sx={{height: '40px', pl: 9}}
                    onClick={() => toggleMenuItem('admin_company')}
                  >
                    <ListItemText
                      primary="Company Settings"
                      primaryTypographyProps={{fontSize: 14}}
                    />
                    {openedMenuItems.includes('admin_company') ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openedMenuItems.includes('admin_company')}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        borderColor: isDarkMode ? 'grey.800' : 'grey.200',
                      }}
                    >
                      {generateMenuItem(COMPANY_SUBMENU, `admin_company`)}
                    </List>
                  </Collapse>
                  <AccessControl
                    products={['proximity']}
                    permissions={['get::/proximity/import']}
                  >
                    <ListItemButton
                      component={RouterLink}
                      to="/admin/proximity-import"
                      sx={{height: '40px', pl: 9}}
                    >
                      <ListItemText
                        primary="Proximity Imports"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </ListItemButton>
                  </AccessControl>

                  {/* @TODO: do we need this page? */}
                  {/* <ListItemButton
                      component={RouterLink}
                      to="/admin/geo-fence"
                      sx={{ height: '40px', pl: 9 }}
                    >
                      <ListItemText primary="Geo Fence" primaryTypographyProps={{ fontSize: 14 }} />
                    </ListItemButton> */}

                  <AccessControl permissions={['get::/company']}>
                    <CompanyListButton
                      component={ListItemButton}
                      componentProps={{
                        sx: {height: '40px', pl: 9},
                      }}
                    >
                      <ListItemText
                        primary="SaaS Companies"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </CompanyListButton>
                  </AccessControl>

                  <ListItemButton
                    component={RouterLink}
                    to="/admin/help"
                    sx={{height: '40px', pl: 9}}
                  >
                    <ListItemText
                      primary="Help"
                      primaryTypographyProps={{fontSize: 14}}
                    />
                  </ListItemButton>
                </Box>
              </List>
            </Collapse>
            {!hazard_ai_enabled && (
              <AccessControl permissions={['get::/subscription']}>
                <Box
                  borderBottom="1px solid"
                  borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
                >
                  <ListItemButton component={RouterLink} to="/subscriptions">
                    <ListItemIcon>
                      <ScheduleIcon />
                    </ListItemIcon>

                    <ListItemText primary="Reporting" />
                  </ListItemButton>
                </Box>
              </AccessControl>
            )}
          </List>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Status short={!sidebarOpened} />
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.background.default,
            flexGrow: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container
            maxWidth={false}
            disableGutters={isCompactMode}
            sx={{
              py: isCompactMode ? 0 : 4,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
            style={{paddingLeft: 0}}
            {...contentContainerProps}
          >
            {children}
          </Container>
        </Box>
      </Box>

      <ProfileSettingsModal
        open={profileSettingsOpened}
        onClose={() => setProfileSettingsOpened(false)}
      />

      {/*
          My Account Modal
          Refectored out of MyAccountButton (which has been deleted) to fix a form bug
          Could be refactored into another file if needed elsewhere in the application - see ProfileSettingsModal for working example
      */}
      <Modal open={myAccountOpened} onClose={() => setMyAccountOpened(false)}>
        <Box sx={myAccountStyle}>
          <Box fontSize={24} display="flex" alignItems="center">
            <PersonIcon sx={{mr: 1}} />
            My Account
          </Box>

          <MyAccount onClose={() => setMyAccountOpened(false)} />
        </Box>
      </Modal>
    </>
  ) : (
    <Backdrop open={!assets.isFetched || !!me}>
      {!isFetchAssetsFailed && !isFetchConfiguratioinFailed ? (
        <CircularProgress color="inherit" />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <ErrorIcon color="error" fontSize="large" />
          {isFetchAssetsFailed ? (
            <Button variant="outlined" onClick={handleFetchAssets}>
              Reload Assets
            </Button>
          ) : isFetchConfiguratioinFailed ? (
            <Button variant="outlined" onClick={handleFetchConfigurations}>
              Reload Configurations
            </Button>
          ) : null}
        </Box>
      )}
    </Backdrop>
  );
};
