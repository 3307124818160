import {Dispatch} from 'redux';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  BackendResponse,
  BackendStatus,
  ConnectionStatus,
} from '../../interfaces/Backend';
import {ConfigurationResponse} from '../../interfaces/Configuration';
import {MeResponse} from '../../interfaces/Me';
import {getAppInitialState} from './reducers';
import {AppState} from './types';

export enum Actions {
  SetApp = 'SetApp',
}

const appActions = {
  setApp: (dispatch: Dispatch, data: Partial<AppState>) => {
    return dispatch({type: Actions.SetApp, data});
  },

  fetchMe: async (dispatch: Dispatch) => {
    const me = (await API.get<MeResponse>(`${apiBaseUrl}/user/me`)).data;
    return appActions.setApp(dispatch, {me});
  },

  fetchMyConfigurations: async (dispatch: Dispatch) => {
    const myConfigurations = (
      await API.get<ConfigurationResponse>(`${apiBaseUrl}/user-configuration`)
    ).data;
    return appActions.setApp(dispatch, {myConfigurations});
  },

  setMuteSounds: async (dispatch: Dispatch, muteSounds: boolean) => {
    return appActions.setApp(dispatch, {muteSounds});
  },

  pollBackend: async (dispatch: Dispatch) => {
    try {
      const start = performance.now();
      const backendResponse = (
        await API.get<BackendResponse>(`${apiBaseUrl}/system/poll`)
      ).data;

      const end = performance.now();
      const backend: BackendStatus = {
        ...backendResponse,
        connected: true,
        delay: Math.round(end - start),
        connection_status:
          Math.round(end - start) > 50
            ? ConnectionStatus.POOR
            : ConnectionStatus.GOOD,
        messages: backendResponse?.messages,
        actions: backendResponse?.actions,
        license: true,
      };

      appActions.setApp(dispatch, {backend});
    } catch (err: any) {
      const backend: BackendStatus = {
        version: 'unknown',
        connected: false,
        delay: 0,
        connection_status: ConnectionStatus.DISCONNECTED,
        branch: '',
        build: 0,
      };

      if (err?.response?.status === 403) {
        backend.license = false;
      }

      appActions.setApp(dispatch, {backend});
    }
  },

  clearApp: (dispatch: Dispatch) => {
    const data: AppState = {
      ...getAppInitialState(),
      companyId: null,
    };

    return dispatch({type: Actions.SetApp, data});
  },
};

export default appActions;
