import {Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {useAppSelector} from '../../hooks/redux';

export const LicenseCheckPage = () => {
  const backend = useAppSelector((app) => app.app.backend);
  const navigate = useNavigate();
  if (backend?.license !== false) {
    navigate('/');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      It looks like your license has expired. Please contact Strata support for
      assistance.
    </Box>
  );
};
