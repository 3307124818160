import CloseIcon from '@mui/icons-material/Close';
import DensitySmall from '@mui/icons-material/DensitySmall';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {BeltInputBody} from '../../../interfaces/BeltMonitoring';
import { BeltMonitoringNode } from '../../../interfaces/BeltMonitoringNode';
import {beltBaseInputSchema} from '../../../scheme/yup/belt-node';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import SnackbarMessages from '../../common/SnackbarMessages';
import StatusSelect from '../../selectors/StatusSelect';
import {ZoneSelect} from '../../selectors/ZoneSelect';

interface Props {
  locationCoordinates?: MapLatLangCoordinates;
  onCancel?: () => void;
  onSubmitted?: (item: BeltMonitoringNode) => void;
}

const BeltNodeItemCreate = ({
  locationCoordinates,
  onCancel,
  onSubmitted
}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  /**********/
  /* submit */
  /**********/
  const submitData = async (data: BeltInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/belt`;
      const resp = await API.post<BeltMonitoringNode>(endpoint, data);
      const message = 'Belt successfully created';
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });

      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (): BeltInputBody => ({
    name: null,
    status: null,
    commtrac_external_id: null,
    zone_id: null,
    latitude: locationCoordinates?.lat
    ? +locationCoordinates.lat.toFixed(6)
    : null,
    longitude: locationCoordinates?.lat
    ? +locationCoordinates.lat.toFixed(6)
    : null,
  });

  const formik = useFormik<BeltInputBody>({
    initialValues: getFormikValues(),
    validationSchema: beltBaseInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    if (locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.latitude !== newPosLat ||
        formik.values.longitude !== newPosLong
      ) {
        formik.setFieldValue('latitude', newPosLat);
        formik.setFieldValue('longitude', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <DensitySmall sx={{fontSize: 24}} />
          <Box fontSize={24}>Create Belt</Box>
        </Box>
        {onCancel ? (
          <IconButton onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.name ?? ''}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={
              !!formik.touched.name && !!formik.errors.name
            }
            helperText={
              formik.touched.name && formik.errors.name
            }
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.commtrac_external_id ?? ''}
            label="Network ID"
            size="small"
            name="commtrac_external_id"
            fullWidth
            error={
              !!formik.touched.commtrac_external_id && !!formik.errors.commtrac_external_id
            }
            helperText={
              formik.touched.commtrac_external_id && formik.errors.commtrac_external_id
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <ZoneSelect
            value={formik.values.zone_id}
            label="Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.latitude ?? ''}
            label="Latitude"
            size="small"
            name="latitude"
            fullWidth
            error={
              !!formik.touched.latitude && !!formik.errors.latitude
            }
            helperText={
              formik.touched.latitude && formik.errors.latitude
            }
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.longitude ?? ''}
            label="Longitude"
            size="small"
            name="longitude"
            fullWidth
            error={
              !!formik.touched.longitude && !!formik.errors.longitude
            }
            helperText={
              formik.touched.longitude && formik.errors.longitude
            }
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value="60"
            label="Reporting Frequency"
            size="small"
            name="commtrac_report_frequency"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BeltNodeItemCreate;
