import { AddCircleOutline } from '@mui/icons-material';
import CellTowerIcon from '@mui/icons-material/CellTower';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Grid, TextField} from '@mui/material';
import {getIn, useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  Sensor,
  SentroGatewayItem,
  SentroGatewayUpsertInputBody,
} from '../../interfaces/SentroGateway';
import { gatewayInputSchema } from '../../scheme/yup/gateway';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  onCancel?: Function;
  onSubmitted?: () => void;
}

const SentroGatewayItemUpsert: React.FC<Props> = ({onCancel, onSubmitted}) => {
  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const [sensors, setSensors] = useState<Sensor[]>([]);

  const submitData = async (data: any) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/gateway`;
      await API.post<SentroGatewayItem>(endpoint, data);
      const message = `Sentro 1 Gateway has been created`;

      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const getFormikValues = (): SentroGatewayUpsertInputBody => ({
    name: '',
    container_name: '',
    mqtt_port: null,
    mqtt_protocol: null,
    mqtt_host: null,
    period: null,
    sensors: [],
  });

  const formik = useFormik<SentroGatewayUpsertInputBody>({
    initialValues: getFormikValues(),
    validationSchema: gatewayInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  const handleAddSensor = () => {
    setSensors(prevSensors => [
      ...prevSensors,
      {
        port_1: null,
        ip_address: null,
        port_2: null,
      },
    ]);
  }

  const handleRemoveSensorItem = (index: number) => {
    // setSensors(sensors.splice(index, 1))
    console.log("index", index);
    setSensors((prevSensors) => {
      const newSensors = [...prevSensors];
      newSensors.splice(index, 1);
      return newSensors;
    });
  }
  console.log(formik.errors);
  const handleSensorChange = (index: number, fieldName: keyof Sensor, value: string) => {
    setSensors((prevSensors) => {
      const updatedSensors = prevSensors.map((sensor, i) =>
        i === index ? { ...sensor, [fieldName]: value } : sensor
      );

      formik.setValues({
        ...formik.values,
        sensors: updatedSensors,
      });

      return updatedSensors;
    });
  };

  const renderSensorFields = () => (
    <>
      {sensors.map((it, idx) => (
        <Grid spacing={3} container key={`sensor-row-${idx}`} style={{ marginBottom: '8px' }}>
          <Grid item xs={3} lg={3} xl={3}>
            <TextField
              value={it.port_1}
              label="Port 1"
              size="small"
              name="port_1"
              rows={3}
              fullWidth
              onChange={(e) => handleSensorChange(idx, "port_1", e.target.value)}
              error={
                !!getIn(formik.touched, `sensors[${idx}].port_1`) &&
                !!getIn(formik.errors, `sensors[${idx}].port_1`)
              }
              helperText={
                getIn(formik.touched, `sensors[${idx}].port_1`) &&
                getIn(formik.errors, `sensors[${idx}].port_1`)
                  ? getIn(formik.errors, `sensors[${idx}].port_1`)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={5} lg={5} xl={5}>
            <TextField
              value={it.ip_address}
              label="IP"
              size="small"
              name="ip_address"
              rows={3}
              fullWidth
              onChange={(e) => handleSensorChange(idx, "ip_address", e.target.value)}
              error={
                !!getIn(formik.touched, `sensors[${idx}].ip_address`) &&
                !!getIn(formik.errors, `sensors[${idx}].ip_address`)
              }
              helperText={
                getIn(formik.touched, `sensors[${idx}].ip_address`) &&
                getIn(formik.errors, `sensors[${idx}].ip_address`)
                  ? getIn(formik.errors, `sensors[${idx}].ip_address`)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={3} lg={3} xl={3}>
            <TextField
              value={it.port_2}
              label="Port 2"
              size="small"
              name="port_2"
              rows={3}
              fullWidth
              onChange={(e) => handleSensorChange(idx, "port_2", e.target.value)}
              error={
                !!getIn(formik.touched, `sensors[${idx}].port_2`) &&
                !!getIn(formik.errors, `sensors[${idx}].port_2`)
              }
              helperText={
                getIn(formik.touched, `sensors[${idx}].port_2`) &&
                getIn(formik.errors, `sensors[${idx}].port_2`)
                  ? getIn(formik.errors, `sensors[${idx}].port_2`)
                  : ''
              }
            />
          </Grid>
          <Grid container item xs={1} lg={1} xl={1} justifyContent="center" alignItems="center">
            <HighlightOffIcon onClick={() => handleRemoveSensorItem(idx)} key={`remove-row-${idx}`}/>
          </Grid>
        </Grid>
      ))}
    </>
  );

  const renderFormFields = () => (
    <Box>
      <Box my={4}>
        <TextField
          value={formik.values.name ?? ''}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.container_name ?? ''}
          label="Container Name"
          size="small"
          name="container_name"
          fullWidth
          error={
            !!formik.touched.container_name && !!formik.errors.container_name
          }
          helperText={formik.touched.container_name && formik.errors.container_name}
          onChange={formik.handleChange}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.period ?? ''}
          label="Period"
          size="small"
          name="period"
          type="number"
          rows={3}
          fullWidth
          onChange={formik.handleChange}
          error={!!formik.touched.period && !!formik.errors.period}
          helperText={formik.touched.period && formik.errors.period}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.mqtt_port ?? ''}
          label="Port"
          size="small"
          name="mqtt_port"
          type="number"
          rows={3}
          fullWidth
          onChange={formik.handleChange}
          error={!!formik.touched.mqtt_port && !!formik.errors.mqtt_port}
          helperText={formik.touched.mqtt_port && formik.errors.mqtt_port}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.mqtt_protocol ?? ''}
          label="Protocol"
          size="small"
          name="mqtt_protocol"
          rows={3}
          fullWidth
          onChange={formik.handleChange}
          error={!!formik.touched.mqtt_protocol && !!formik.errors.mqtt_protocol}
          helperText={
            formik.touched.mqtt_protocol && formik.errors.mqtt_protocol}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.mqtt_host ?? ''}
          label="Host"
          size="small"
          name="mqtt_host"
          rows={3}
          fullWidth
          onChange={formik.handleChange}
          error={!!formik.touched.mqtt_host && !!formik.errors.mqtt_host}
          helperText={formik.touched.mqtt_host && formik.errors.mqtt_host}
        />
      </Box>

      <Box my={4}>
        <Button
          variant="contained"
          startIcon={<AddCircleOutline />}
          onClick={() => handleAddSensor()}
        >
          Sensor
        </Button>
        <Box my={4}
          sx={{border: sensors.length > 0 ? '1px solid rgba(255, 255, 255, 0.23)' : 'none'}}
          padding={sensors.length > 0 ? 2 : 0}
        >
          {sensors.length > 0 && renderSensorFields()}
        </Box>

      </Box>
    </Box>
  );

  return (
    <Box
      component="form"
      position="relative"
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Grid container mb={3}>
        <Grid item>
          <Box display="flex" alignItems="center" gap={2}>
            <CellTowerIcon fontSize="large" sx={{color: 'primary.main'}} />
            <Box fontSize={24}>Create Sentro 1 Gateway</Box>
          </Box>
        </Grid>
      </Grid>
      {renderFormFields()}
      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => {
          formik.resetForm();
          setSensors([]);
        }}>Reset</Button>
        <Box display="flex" justifyContent="end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>

          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ml: 1}}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SentroGatewayItemUpsert;
