/* eslint-disable no-bitwise */
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import update from 'immutability-helper';
import {isNull} from 'lodash';
import {useEffect, useMemo, useRef} from 'react';

import {useConfiguration} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import {
  AMSEModuleNode,
  AMSEModuleNodeListQuery,
  AMSEModuleNodeListResponse,
} from '../../../interfaces/AMSEModuleNode';
import {ExportField} from '../../../interfaces/Export';
import {amsSensorAddressMask} from '../../../interfaces/GasMonitoringNode';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {isPresent} from '../../../utils/type-guards';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import AMSEModuleImportExportButton from '../AMSEModule/AMSEModuleImportExportButton';
import AMSEModuleItemEditButton from '../AMSEModule/AMSEModuleItemEditButton';
import AMSEModuleItemsPurgeButton from '../AMSEModule/AMSEModuleItemsPurgeButton';
import AMSEModuleItemUpsertButton from '../AMSEModule/AMSEModuleItemUpsertButton';

export interface AMSEModulesReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  params: Partial<AMSEModuleNodeListQuery>;
  selectedIds: string[] | null;
  shownFields: {
    all?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

const DEFAULT_SHOWN_FIELDS = [
  'select',
  'id',
  'actions',
  'emod_conf',
  'serial_number',
  'type',
  'sensor_name',
  'commtrac_external_id',
  'date_installation',
  'date_uninstallation',
  'date_calibration',
  'calibration_value',
];

export const getAMSEModulesReportData = (): AMSEModulesReportData => {
  return {
    mapLayers: ['street', 'mine', 'alarms', 'e_module'],
    mapLevel: null,
    params: {
      page: 0,
      limit: 2000,
      status: 'all',
      section_ids: [],
    },
    selectedIds: [],
    shownFields: {
      all: DEFAULT_SHOWN_FIELDS,
    },
    grid: {
      page: 0,
      pageSize: 25,
    },
    exportFields: [],
  };
};

interface Props {
  value: AMSEModulesReportData;
  fetchedData?: AMSEModuleNodeListResponse;
  isLoading?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  onOpenItem?: (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
      | 'amsShortTerm'
      | 'amsLongTerm'
      | 'amsLocation'
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
      | 'beltLocationHistoryReport'
      | 'beltHistoryReport'
  ) => void;
  onChange?: (value?: AMSEModulesReportData) => void;
  onRefresh?: () => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
}

export const EModulesReport = ({
  value,
  fetchedData,
  isLoading,
  locationCoordinates,
  onOpenItem,
  onOpenHistory,
  onChange,
  onRefresh = () => {},
  onChangeLocationCoordinates,
}: Props) => {
  const config = useMemo(() => value ?? getAMSEModulesReportData(), [value]);
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);

  // Data Grid
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);
  const columns: DataGridColumn<AMSEModuleNode>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(String(row.id))}
          onChange={() => toggleSelectItem(String(row.id))}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      valueGetter: ({row}) => row.id,
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Tooltip title="Edit">
          <MoreHorizIcon />
        </Tooltip>
      ),
      type: 'actions',
      doNotExport: true,
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => (
        <AccessControl roles={[1, 2, 3]}>
          <AMSEModuleItemEditButton
            item={row}
            onOpenItem={onOpenItem}
            onOpenHistory={onOpenHistory}
            component={IconButton}
            componentProps={{
              color: 'primary',
            }}
          >
            <MoreHorizIcon />
          </AMSEModuleItemEditButton>
        </AccessControl>
      ),
    },
    {
      field: 'emod_conf',
      headerName: 'EMod Conf',
      sxHeader: {minWidth: 60, textAlign: 'center'},
      renderHeader: () => (
        <Tooltip title="EMod Conf">
          <TimerOutlinedIcon />
        </Tooltip>
      ),
      valueGetter: ({row}) => {
        const {e_408: isCalibrationExpired, e_410: isEModuleUnregistered} = row;

        if (isEModuleUnregistered) {
          return 2;
        }

        if (isCalibrationExpired) {
          return 1;
        }

        return 0;
      },
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        const {e_408: isCalibrationExpired, e_410: isEModuleUnregistered} = row;

        if (isEModuleUnregistered) {
          return (
            <Tooltip title="e-Module Unregistered">
              <TheatersOutlinedIcon />
            </Tooltip>
          );
        }

        if (isCalibrationExpired) {
          return (
            <Tooltip title="e-Module Calibration Date Expired">
              <TimerOutlinedIcon color="error" />
            </Tooltip>
          );
        }

        return (
          <Tooltip title="Ok">
            <p></p>
          </Tooltip>
        );
      },
    },
    {
      field: 'serial_number',
      headerName: 'eModule SN',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.serial_number ?? '',
    },
    {
      field: 'type',
      headerName: 'Type',
      sxHeader: {minWidth: 100, textAlign: 'center'},
      sortable: true,
      renderCell: ({row}) => {
        return !isNull(row?.type) ? typeIdLabels?.[row.type] : 'None';
      },
    },
    {
      field: 'sensor_name',
      headerName: 'Sentro 1 Sensor Name',
      sortable: true,
      valueGetter: ({row}) => row.ams?.name,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Sentro 1 Network ID',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) =>
        row?.commtrac_external_id
          ? row?.commtrac_external_id & amsSensorAddressMask
          : '',
    },
    {
      field: 'date_installation',
      headerName: 'Installed Date',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.date_installation ?? '',
    },
    {
      field: 'date_uninstallation',
      headerName: 'Uninstalled Date',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.date_uninstallation ?? '',
    },
    {
      field: 'date_calibration',
      headerName: 'Calibration Date',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.date_calibration ?? '',
    },
    {
      field: 'recalibration_date',
      headerName: 'Recalibration Date',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.recalibration_date ?? '',
    },
    {
      field: 'calibration_value',
      headerName: 'Calibrartion Value',
      sxCell: {textAlign: 'center'},
      sortable: true,
      valueGetter: ({row}) => row?.calibration_value ?? '',
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields.all;
  }, [config]);

  useEffect(() => {
    const excludedFields = ['actions'];

    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter(
              (col) => !!col.headerName && !excludedFields.includes(col.field)
            )
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: !shownFields?.includes(col.field),
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    onChange?.(
      update(config, {
        shownFields: {
          all: {$set: fields},
        },
      })
    );
  };

  // Multiple Select
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((item) => config.selectedIds?.includes(String(item.id))),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((item) => String(item.id)) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((item) => String(item.id)),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  const mapData = useMemo(() => {
    if (fetchedData?.items && config.selectedIds?.length) {
      return fetchedData?.items.filter(
        (i) => config.selectedIds?.includes(`${i.id}`) && !!i?.ams
      );
    }
    return [];
  }, [fetchedData, config.selectedIds, selectedItems]);

  // Refresh Interval
  const refreshPeriod = useConfiguration(
    'auto-refresh',
    'ams_grid_autorefresh_rate'
  );

  return (
    <Box height="100%" width="100%">
      <ResizableColumns
        left={
          <>
            <Box
              display="flex"
              alignItems="center"
              height={70}
              minWidth={520}
              gap={2}
              p={2}
            />
            <Box height="100%">
              <Map
                panel="alarm_log_report"
                eModules={mapData}
                selectedMapLayers={config.mapLayers}
                selectedLevel={config.mapLevel}
                height="calc(100% - 70px)"
                minWidth={200}
                availableMapLayers={[
                  'employees',
                  'assets',
                  'street',
                  'mine',
                  'nodes',
                  'alarms',
                  'e_module',
                ]}
                onSelectMapLayers={(v) => {
                  onChange?.(
                    update(config, {
                      mapLayers: {
                        $set: v,
                      },
                    })
                  );
                }}
                onSelectLevel={(v) => {
                  onChange?.(
                    update(config, {
                      mapLevel: {
                        $set: v,
                      },
                    })
                  );
                }}
                onOpenItem={(e) => {
                  onOpenItem?.(e, 'e-module');
                }}
                onOpenHistory={onOpenHistory}
                onGetClickCoordinates={onChangeLocationCoordinates}
              />
            </Box>
          </>
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box p={2} minWidth={800}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height={40}
              gap={2}
            >
              <AccessControl roles={[1, 2, 3]}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  width="100%"
                  height="100%"
                >
                  <ButtonGroup>
                    <AMSEModuleItemUpsertButton
                      onSubmitted={() => {
                        onRefresh?.();
                        onChangeLocationCoordinates?.(undefined);
                      }}
                      locationCoordinates={locationCoordinates}
                      componentProps={{
                        color: 'primary',
                        size: 'small',
                        startIcon: <AddIcon />,
                      }}
                    >
                      EModule
                    </AMSEModuleItemUpsertButton>

                    <AMSEModuleImportExportButton
                      value={value}
                      component={Button}
                      componentProps={{color: 'primary'}}
                    >
                      <ImportExportIcon />
                    </AMSEModuleImportExportButton>

                    <Button onClick={() => onRefresh?.()}>
                      <Tooltip title="Refresh">
                        <RefreshIcon />
                      </Tooltip>
                    </Button>

                    {refreshPeriod ? (
                      <AutoRefreshSettingsSelect
                        refreshPeriod={refreshPeriod}
                      />
                    ) : null}
                    <Button onClick={() => {}}>
                      <PrintIcon
                        onClick={() => dataGridRef.current?.printTable()}
                      />
                    </Button>
                  </ButtonGroup>
                </Box>
              </AccessControl>
            </Box>
          </Box>
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={isLoading}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                  <AccessControl permissions={['post::/purge']}>
                    <Box display="flex" height={40}>
                      <AMSEModuleItemsPurgeButton
                        amsEModulesIds={selectedRows
                          .filter((i) => i.id)
                          .map((i) => i.id)
                          .filter(isPresent)}
                        componentProps={{size: 'small', variant: 'outlined'}}
                        onDone={() => onRefresh?.()}
                      >
                        <RemoveCircleOutlineIcon
                          fontSize="small"
                          sx={{mr: 1}}
                        />
                        Purge
                      </AMSEModuleItemsPurgeButton>
                    </Box>
                  </AccessControl>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
    </Box>
  );
};
