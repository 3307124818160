import {Box} from '@mui/material';
import Image from 'mui-image';

import wifibIcon from './wifib.svg'
import wifibBIcon from './wifibB.svg'

interface Props {
  color?: 'red' | 'white' | 'black';
  size?: number;
}


export const WifiBIcon = ({ color = 'red', size = 20 }: Props) => {
    return (
      <Box
        sx={{
          '& img': {transition: 'none !important', animation: 'none !important'},
          transition: 'none !important',
          color: color
        }}
      >
        {color === 'red' && (
          <Image
            src={wifibIcon}
            alt="wi-fi-b"
            width={size}
            height={size}
        />
        )}
        {color === 'black' && (
          <Image
            src={wifibBIcon}
            alt="wi-fi-b"
            width={size}
            height={size}
        />
        )}
      </Box>
    );
};

export default WifiBIcon;
