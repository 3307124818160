import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import {
  COMPANY_PRUNE_HISTORY_SECTIONS,
  COMPANY_SETTINGS_SECTIONS,
  fieldConfiguration,
  SETTING_FIELDS,
} from '../../utils/configs/adminCompanySettingsConfig';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import {AdminSmtpTestEmailDialog} from './AdminSmtpTestEmailDialog';

interface ConfigurationCompanySettingsValues {
  [key: string]: string | number | null;
}

const getFormikValues = (
  item?: ConfigurationResponse
): ConfigurationCompanySettingsValues => ({
  ..._.chain(SETTING_FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => {
      return SETTING_FIELDS.includes(`${i.section}.${i.name}`);
    })
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse
): ConfigurationCompanySettingsValues => ({
  ..._.chain(item)
    .filter((i) => {
      return SETTING_FIELDS.includes(`${i.section}.${i.name}`);
    })
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

const getConfigurationInput = (
  values: ConfigurationCompanySettingsValues,
  section?: string
) => ({
  section: ['log', 'smtp', 'prune-history', 'global'].filter(
    (s) => !section || section === s
  ),
  configuration: _.map(values, (value, key) => ({
    section: key.split('.')[0],
    name: key.split('.')[1],
    value,
  })).filter((item) => !section || section === item.section),
});

export interface AdminCompanySettingsConfigProps {
  disabled?: boolean;
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface AdminCompanySettingsConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const AdminCompanySettingsSystemConfig = forwardRef<
  AdminCompanySettingsConfigRef,
  React.PropsWithChildren<AdminCompanySettingsConfigProps>
>(({disabled, onChangeSubmittedInProgress, onChangeResetInProgress}, ref) => {
  const productsEnabled = useAppSelector(reduxSelectors.assets.productsEnabled);

  const [showSmtpTestEmailDialog, setShowSmtpTestEmailDialog] = useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const companyAssets = useMemo(() => {
    const enableAssets: Record<string, any> = {};

    if (assets.company) {
      for (const field in assets.company) {
        if (field.includes('_enabled')) {
          // @ts-ignore
          enableAssets[field.split('_')[0]] = !!assets.company[field];
        }
      }
    }

    return enableAssets;
  }, [assets.company]);

  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => formik.handleSubmit(),
    reset: () => handleResetData(),
    clear: () => formik.setValues(getFormikValuesClear(fetchedData)),
    submittedInProgress: submittedInProgress,
  }));

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<ConfigurationResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>();
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const params = {
        section: JSON.stringify(['log', 'smtp', 'prune-history', 'global']),
      };
      const resp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      setFetchedErrors(error.data);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setFetchedData(undefined);
      setFetchedErrors(undefined);
    };
  }, []);

  /**********/
  /* submit */
  /**********/
  const [submittedInProgress, setSubmittedInProgress] =
    useState<boolean>(false);

  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);

    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      reduxDispatch(reduxActions.assets.fetchAssets);
      enqueueSnackbar('Configuration successfully updated', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  /**********/
  /* reset */
  /**********/
  const [resetInProgress, setResetInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const resetData = async (data: ConfigurationInput) => {
    setResetInProgress(true);

    try {
      const resp = await API.patch<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully reset', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setResetInProgress(false);
  };

  const handleResetData = async () => {
    await resetData(getConfigurationInput(formik.values));
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object();

  const formik = useFormik({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(getConfigurationInput(values));
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  /************/
  /* keysData */
  /************/
  const keysData = useMemo(
    () => ({
      ..._.chain(fetchedData)
        .keyBy((i) => `${i.section}.${i.name}`)
        .mapValues()
        .value(),
    }),
    [fetchedData]
  );

  // other
  useEffect(() => {
    onChangeSubmittedInProgress?.(submittedInProgress);
  }, [submittedInProgress]);

  useEffect(() => {
    onChangeResetInProgress?.(resetInProgress);
  }, [resetInProgress]);

  const fieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    formik.setValues({...formik.values, [name]: event.target.value});
  };

  const filteredPruneHistorySettingByCompanySubscribe = useMemo(() => {
    return COMPANY_PRUNE_HISTORY_SECTIONS.filter((section) => {
      const products = section.products as unknown as any[];
      if (!products.find((i: any) => productsEnabled.includes(i))) {
        return false;
      }
      if (section.key) {
        const companyAssetsIncludeKey = Boolean(companyAssets[section.key]);
        if (companyAssetsIncludeKey) {
          return companyAssets[section.key];
        }
        return true;
      }
      return true;
    });
  }, [companyAssets, COMPANY_PRUNE_HISTORY_SECTIONS]);

  return (
    <Box position="relative">
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      {!!filteredPruneHistorySettingByCompanySubscribe.length && (
        <Paper sx={{p: 3, mb: 3}}>
          <Box fontSize={20} lineHeight={1.6}>
            Prune History Settings
          </Box>
          {filteredPruneHistorySettingByCompanySubscribe.map((section) => (
            <>
              <Box fontSize={20} lineHeight={1.6} mb={2} mt={4}>
                {section.title}
              </Box>

              <Grid container spacing={4}>
                {section.items.map((name) => (
                  <Grid key={name} item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      type={fieldConfiguration(name)?.type}
                      select={!!fieldConfiguration(name)?.options}
                      size="small"
                      disabled={disabled}
                      onChange={(e: any) => fieldHandler(e, name)}
                    >
                      {fieldConfiguration(name)?.options?.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ))}
              </Grid>
            </>
          ))}
        </Paper>
      )}

      {COMPANY_SETTINGS_SECTIONS.map((section) => (
        <Paper key={section.title} sx={{p: 3, mb: 3}}>
          <Box fontSize={20} lineHeight={1.6} mb={3}>
            {section.title}
          </Box>

          <Grid container spacing={4}>
            {section.items.map((name) => (
              <Grid key={name} item xs={12} lg={6}>
                <TextField
                  fullWidth
                  label={t(keysData[name]?.label)}
                  value={formik.values[name] ?? ''}
                  name={name}
                  type={fieldConfiguration(name)?.type}
                  select={!!fieldConfiguration(name)?.options}
                  size="small"
                  disabled={disabled}
                  onChange={(e: any) => fieldHandler(e, name)}
                >
                  {fieldConfiguration(name)?.options?.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ))}
            {section.title === 'SMTP Settings' && (
              <Grid item xs={12} lg={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setShowSmtpTestEmailDialog(true)}
                >
                  Save and send test email
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      ))}

      <AdminSmtpTestEmailDialog
        opened={showSmtpTestEmailDialog}
        smtpData={getConfigurationInput(formik.values, 'smtp')}
        onClose={() => setShowSmtpTestEmailDialog(false)}
      ></AdminSmtpTestEmailDialog>
    </Box>
  );
});
