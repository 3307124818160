import {ListItem} from '@mui/material';
import React, {useState} from 'react';

import SentroGateWayItemUpsertModal from '../SentroGateWayItemUpsertModal';

interface Props {
  pk?: any;
  item?: any;
  prefetch?: boolean;
  component?: React.FC;
  componentProps?: any;
  children?: React.ReactNode | React.ReactNode[];
  onSubmitted?: () => void;
  onClose?: () => void;
  onPurged?: () => void;
  onOpenHistory?: () => void;
}

const SentroGatewayItemUpsertButton = ({
  pk,
  item,
  prefetch,
  component = ListItem,
  componentProps = {},
  children,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  const Component = component;
  const [isOpened, setIsOpened] = useState(false);
  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      {isOpened && (
        <SentroGateWayItemUpsertModal
          pk={pk}
          item={item}
          prefetch={prefetch}
          onClose={closeModal}
          onSubmitted={onSubmitted}
          onPurged={onPurged}
          onOpenHistory={onOpenHistory}
        />
      )}
    </>
  );
};

export default SentroGatewayItemUpsertButton;
