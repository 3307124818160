import inRange from 'lodash/inRange';

import {CommtracNode} from '../interfaces/CommtracNode';
import {useGetMinerNetworkId} from './get-miner-network-id';
import {useAppSelector} from './redux';

export const useGetCommtracNodeType = () => {
  const getAssetHumanType = useGetAssetHumanType();
  const getAssetMachineType = useGetAssetMachineType();

  const getCommtracNodeType = (node: CommtracNode) => {
    if (node.type === 'miner') {
      return getAssetHumanType({
        wifi_enabled: node.wifi_enabled,
        commtrac_external_id: node.commtrac_external_id,
        mc2_flag: node.mc2_flag,
      });
    } else if (node.type === 'asset') {
      return getAssetMachineType({
        wifi_enabled: node.wifi_enabled,
        commtrac_external_id: node.commtrac_external_id,
      });
    }
  };
  return getCommtracNodeType;
};

export const useGetAssetMachineType = () => {
  const tagAddressRange = useAppSelector(
    ({assets}) => assets.constants?.tag_address_range
  );

  const getAssetMachineType = ({
    commtrac_external_id,
    wifi_enabled,
  }: {
    commtrac_external_id?: number | null;
    wifi_enabled?: 0 | 1 | null;
  }) => {
    if (wifi_enabled) {
      return 'WiFi';
    } else if (typeof commtrac_external_id === 'number' && tagAddressRange) {
      if (
        inRange(
          commtrac_external_id,
          tagAddressRange?.min - 1,
          tagAddressRange?.max + 1
        )
      ) {
        return 'TAG';
      }
      return 'AT';
    }
  };

  return getAssetMachineType;
};

export const useGetAssetHumanType = () => {
  const getMinerNetworkId = useGetMinerNetworkId();
  const camAddressRange = useAppSelector(
    ({assets}) => assets.constants?.cam_address_range
  );
  const tagAddressRange = useAppSelector(
    ({assets}) => assets.constants?.tag_address_range
  );

  const getAssetMachineType = ({
    wifi_enabled,
    commtrac_external_id,
    mc2_flag,
  }: {
    wifi_enabled?: 0 | 1 | null;
    commtrac_external_id?: number | null;
    mc2_flag?: 0 | 1 | null;
  }) => {
    const networkId = getMinerNetworkId(commtrac_external_id);
    if (wifi_enabled) {
      return 'WiFi';
    } else if (mc2_flag === 1) {
      return 'MC2';
    } else if (
      typeof commtrac_external_id === 'number' &&
      typeof networkId === 'number' &&
      tagAddressRange &&
      camAddressRange
    ) {
      if (
        inRange(networkId, tagAddressRange?.min - 1, tagAddressRange?.max + 1)
      ) {
        return 'TAG';
      } else if (
        inRange(networkId, camAddressRange?.min - 1, camAddressRange?.max + 1)
      ) {
        return 'CAM';
      }
      return 'MC';
    }
  };

  return getAssetMachineType;
};
