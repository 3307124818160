import AddIcon from '@mui/icons-material/Add';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import BarChartIcon from '@mui/icons-material/BarChart';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BuildIcon from '@mui/icons-material/Build';
import Crop54Icon from '@mui/icons-material/Crop54';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import update from 'immutability-helper';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import {cloneElement, useEffect, useMemo, useRef} from 'react';

import {
  COMMUNICATION_NODE_TYPES,
  WIFI_POINT_TYPES,
} from '../../../constants/node';
import {useConfiguration} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import {ExportField} from '../../../interfaces/Export';
import {Node, NodeListQuery, NodeListResponse} from '../../../interfaces/Node';
import reduxSelectors from '../../../redux/selectors';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {eventIconsLegacy} from '../../../utils/event-icons';
import {signalBytesToDbm} from '../../../utils/node';
import {getBatteryPercentage, getNodeKey} from '../../../utils/nodes';
import {isPresent} from '../../../utils/type-guards';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import {BatteryIcon} from '../../common/BatteryIcon';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import CommunicationNodeBroadcastReportFrequencyButton from '../../communication-node/CommunicationNodeBroadcastReportFrequencyButton';
import CommunicationNodeItemUpsertButton from '../../communication-node/CommunicationNodeItemUpsertButton';
import EventIcon from '../../EventIcon';
import NodeItemsEditButton from '../../nodes/buttons/NodeItemsEditButton';
import NodeItemsPurgeButton from '../../nodes/buttons/NodeItemsPurgeButton';
import NodeImportExportButton from '../../nodes/NodeImportExportButton';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import WifiPointItemUpsertButton from '../../wifi-point/WifiPointItemUpsertButton';

interface Props {
  value?: NodesReportData;
  fetchedData?: NodeListResponse;
  loading?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  onChange?: (value?: NodesReportData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
      | 'amsShortTerm'
      | 'amsLongTerm'
      | 'amsLocation'
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
      | 'beltLocationHistoryReport'
      | 'beltHistoryReport'
  ) => void;
  onOpenItem?: (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
  onRefresh?: () => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
}

export interface NodesReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  params: Partial<NodeListQuery>;
  selectedIds: string[] | null;
  shownFields: {
    all?: string[];
    cn?: string[];
    wifi?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

const DEFAULT_SHOWN_FIELDS_CN = [
  'cn_battery_voltage',
  'no_heartbeat',
  'assigned_master',
  'msg_conf',
  'msg_version',
  'id',
  'name',
  'node_type',
  'zone',
  'cn_on_surface',
  'external_id',
  'cn_routing_root_address',
  'cn_routing_parent_address',
  'cn_routing_parent_rssi',
  'cn_routing_number_of_hops',
  'cn_last_update',
];

const DEFAULT_SHOWN_FIELDS_WIFI = [
  'no_heartbeat',
  'id',
  'name',
  'node_type',
  'external_id',
  'zone',
  'wifi_ip',
  'wifi_mac_switch',
  'wifi_mac_radio_1',
  'wifi_mac_radio_2',
  'wifi_last_response',
  'wifi_last_latency',
  'wifi_last_ping',
];

const DEFAULT_SHOWN_FIELDS = union(
  DEFAULT_SHOWN_FIELDS_CN,
  DEFAULT_SHOWN_FIELDS_WIFI
);

const makeIconSmall = (icon: any) => {
  return cloneElement(icon, {sx: {fontSize: 16}});
};

export const getNodesReportData = (): NodesReportData => {
  return {
    mapLayers: ['nodes', 'mine', 'street'],
    mapLevel: null,
    params: {
      communication_node_status: 'active',
      wifi_point_status: 'active',
      node_type: [],
      section_ids: [],
      limit: 2000,
      page: 0,
    },
    selectedIds: null,
    shownFields: {
      all: DEFAULT_SHOWN_FIELDS,
      cn: DEFAULT_SHOWN_FIELDS_CN,
      wifi: DEFAULT_SHOWN_FIELDS_WIFI,
    },
    grid: {
      page: 0,
      pageSize: 25,
    },
    exportFields: [],
  };
};

const NodesReport = ({
  value,
  fetchedData,
  loading,
  locationCoordinates,
  onChange,
  onOpenHistory,
  onOpenItem,
  onRefresh,
  onChangeLocationCoordinates,
}: Props) => {
  const assets = useAppSelector(({assets}) => assets.zones);
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);

  const config = useMemo(() => value ?? getNodesReportData(), [value]);

  /*************/
  /* data grid */
  /*************/
  const myPermissions = useAppSelector(({app}) => app.me?.permissions);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);
  const columns: DataGridColumn<Node>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            disabled={rows.length === 0}
            checked={selectedItems.length > 0 && selectedAll}
            indeterminate={selectedItems.length > 0 && !selectedAll}
            onChange={() => toggleSelectAllItems()}
          />
        </Box>
      ),
      renderCell: ({row}) => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            checked={selectedItems.includes(getNodeKey(row))}
            onChange={() => toggleSelectItem(getNodeKey(row))}
            size="small"
          />
        </Box>
      ),
    },
    {
      field: 'actions',
      renderHeader: () => (
        <Tooltip title="More">
          <MoreHorizIcon />
        </Tooltip>
      ),
      // headerName: 'Edit',
      type: 'actions',
      hideable: false,
      width: 40,
      hidden:
        !myPermissions?.includes('get::/node/:id/communication-node') &&
        !myPermissions?.includes('get::/node/:id/wifi-point'),
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => {
        if (row.node_type === 'communication_node' && row.cn_id) {
          return row.ack === '0' ? (
            <AccessControl
              permissions={['patch::/node/:id/communication-node/acknowledge']}
            >
              <IconButton
                color="primary"
                onClick={() => {
                  if (row.cn_id) {
                    onOpenItem?.(row.cn_id, 'cn', 'ack');
                  }
                }}
                size="small"
              >
                <Tooltip title="Acknowledge">
                  <WarningAmberIcon color="warning" sx={{fontSize: 16}} />
                </Tooltip>
              </IconButton>
            </AccessControl>
          ) : (
            <AccessControl
              permissions={['patch::/node/:id/communication-node']}
            >
              <NodeItemsEditButton
                item={row}
                component={IconButton}
                componentProps={{
                  color: 'primary',
                  size: 'small',
                }}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              >
                <MoreHorizIcon sx={{fontSize: 16}} />
              </NodeItemsEditButton>
            </AccessControl>
          );
        } else if (row.node_type === 'wifi_point' && row.wifi_id) {
          return row.ack === '0' ? (
            <AccessControl
              permissions={['patch::/node/:id/wifi-point/acknowledge']}
            >
              <IconButton
                color="primary"
                onClick={() => {
                  if (row.wifi_id) {
                    onOpenItem?.(row.wifi_id, 'wifi', 'ack');
                  }
                }}
                size="small"
              >
                <Tooltip title="Acknowledge">
                  <WarningAmberIcon color="warning" sx={{fontSize: 16}} />
                </Tooltip>
              </IconButton>
            </AccessControl>
          ) : (
            <AccessControl permissions={['patch::/node/:id/wifi-point']}>
              <NodeItemsEditButton
                item={row}
                component={IconButton}
                componentProps={{
                  color: 'primary',
                  size: 'small',
                }}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              >
                <MoreHorizIcon sx={{fontSize: 16}} />
              </NodeItemsEditButton>
            </AccessControl>
          );
        }
      },
    },
    {
      field: 'cn_battery_voltage',
      headerName: 'Battery Voltage',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Low Battery">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => row.cn_battery_voltage ?? 0,
      renderCell: ({row}) => {
        if (
          row.node_type === 'communication_node' &&
          typeof row.cn_battery_voltage === 'number'
        ) {
          if (row.cn_battery_voltage <= 20) {
            return (
              <Tooltip title="External Power">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  <EventIcon
                    eventType="communicationNodes.externalPower"
                    sx={{fontSize: 16}}
                  />
                </Box>
              </Tooltip>
            );
          }
          const value = getBatteryPercentage(row.cn_battery_voltage);
          return (
            <Tooltip title={`${value}%`}>
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <BatteryIcon value={value} sx={{fontSize: 16}} />
              </Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'no_heartbeat',
      headerName: 'No heartbeat',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="No Heartbeat">
          <WifiIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.node_type === 'communication_node') {
          if (row.e_121) {
            return (
              <Tooltip title="Communication Node Many Heartbeat">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(eventIconsLegacy.nodes.cnManyHeartbeat)}
                </Box>
              </Tooltip>
            );
          } else if (row.e_102) {
            return (
              <Tooltip title="Communication Node No Heartbeat">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(eventIconsLegacy.nodes.cnNoHeartbeat)}
                </Box>
              </Tooltip>
            );
          } else if (row.e_104) {
            return (
              <Tooltip title="Communication Node Few Heartbeat">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(eventIconsLegacy.nodes.cnFewHeartbeat)}
                </Box>
              </Tooltip>
            );
          }
        } else if (row.node_type === 'wifi_point') {
          if (row.wifi_connection_status === 'disconnected') {
            return (
              <Tooltip title="Disconnected">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(eventIconsLegacy.nodes.wifiDisconnected)}
                </Box>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'assigned_master',
      headerName: 'Assigned Master',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Assigned Master">
          <MergeTypeIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.node_type === 'communication_node') {
          if (row.e_112) {
            return (
              <Tooltip title="Unregistered Network">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(eventIconsLegacy.nodes.cnUnregisteredNetwork)}
                </Box>
              </Tooltip>
            );
          } else if (row.e_101) {
            return (
              <Tooltip title="Assigned Master">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(eventIconsLegacy.nodes.cnAssignedMaster)}
                </Box>
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'msg_conf',
      headerName: 'Msg Conf',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Msg Conf">
          <BuildIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.node_type === 'communication_node') {
          if (row.e_122) {
            return (
              <Tooltip title="Configuration Message Failed">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(
                    eventIconsLegacy.nodes.cnConfigurationMessageFailed
                  )}
                </Box>
              </Tooltip>
            );
          } else if (row.configuration_message?.answer === 'NACK') {
            return (
              <Tooltip title="NACK">
                <Box sx={{position: 'relative', left: 4, top: 4}}>
                  {makeIconSmall(
                    eventIconsLegacy.nodes.cnConfigurationMessageNack
                  )}
                </Box>
              </Tooltip>
            );
          } else if (row.e_132) {
            return (
              <Tooltip title="ACK">
                {makeIconSmall(
                  eventIconsLegacy.nodes.cnConfigurationMessageAck
                )}
              </Tooltip>
            );
          }
        }
      },
    },
    {
      field: 'msg_version',
      headerName: 'Msg Version',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Msg Version">
          <AllInclusiveIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        let title: string | null = null;
        if (row.e_110 && row.e_111) {
          title = 'PIC and CC1110';
        } else if (row.e_110) {
          title = 'PIC';
        } else if (row.e_111) {
          title = 'CC1110';
        }
        if (title) {
          return (
            <Tooltip title={`${title} 'Version Changed'`}>
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                {makeIconSmall(eventIconsLegacy.nodes.cnVersionChanged)}
              </Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => row.name || '',
    },
    {
      field: 'node_type',
      headerName: 'Type',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return COMMUNICATION_NODE_TYPES.find(
            (i) => row.cn_node_type === `${i.id}`
          )?.name;
        } else if (row.node_type === 'wifi_point') {
          return WIFI_POINT_TYPES.find((i) => row.wifi_type === i.key)?.name;
        }
      },
    },
    {
      field: 'zone',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) => assets.find((i) => i.id === row.zone_id)?.name,
    },
    {
      field: 'cn_on_surface',
      headerName: 'Pos',
      sortable: true,
      valueGetter: ({row}) => row.cn_on_surface === '1',
      renderCell: ({row}) => {
        if (row.cn_on_surface === '1') {
          return (
            <Tooltip title="Surface">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <BarChartIcon color="success" sx={{fontSize: 16}} />
              </Box>
            </Tooltip>
          );
        } else if (row.cn_on_surface === '0') {
          return (
            <Tooltip title="Underground">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <Crop54Icon color="success" sx={{fontSize: 16}} />
              </Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'external_id',
      headerName: 'Network ID',
      sortable: true,
    },
    {
      field: 'wifi_ip',
      headerName: 'IP',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'wifi_point') {
          return row.wifi_ip;
        }
      },
    },
    {
      field: 'wifi_mac_switch',
      headerName: 'Switch',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'wifi_point') {
          return row.wifi_mac_switch;
        }
      },
    },
    {
      field: 'wifi_mac_radio_1',
      headerName: 'Radio 1',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'wifi_point') {
          return row.wifi_mac_radio_1;
        }
      },
    },
    {
      field: 'wifi_mac_radio_2',
      headerName: 'Radio 2',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'wifi_point') {
          return row.wifi_mac_radio_2;
        }
      },
    },
    {
      field: 'wifi_last_response',
      headerName: 'Last Response',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'wifi_point') {
          return row.wifi_last_response;
        }
      },
    },
    {
      field: 'wifi_last_latency',
      headerName: 'Latency',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'wifi_point') {
          return row.wifi_last_latency;
        }
      },
    },
    {
      field: 'longitude',
      headerName: 'Lon',
      sortable: true,
      valueGetter: ({row}) => {
        return row.longitude;
      },
    },
    {
      field: 'latitude',
      headerName: 'Lat',
      sortable: true,
      valueGetter: ({row}) => {
        return row.latitude;
      },
    },
    {
      field: 'cn_routing_root_address',
      headerName: 'Root Address',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_routing_root_address;
        }
      },
    },
    {
      field: 'cn_routing_parent_address',
      headerName: 'Parent Address',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_routing_parent_address;
        }
      },
    },
    {
      field: 'cn_routing_parent_rssi',
      headerName: 'Parent RSSI',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return signalBytesToDbm(row.cn_routing_parent_rssi);
        }
      },
    },
    {
      field: 'cn_routing_number_of_hops',
      headerName: 'Hops to Root',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_routing_number_of_hops;
        }
      },
    },
    {
      field: 'cn_timing_root_address',
      headerName: 'Timing Root Address',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_timing_root_address;
        }
      },
    },
    {
      field: 'cn_timing_parent_address',
      headerName: 'Timing Parent Address',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_timing_parent_address;
        }
      },
    },
    {
      field: 'cn_timing_number_of_hops',
      headerName: 'Timing Hops to Root',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_timing_number_of_hops;
        }
      },
    },
    {
      field: 'cn_lost_routing_parents',
      headerName: 'Lost Routing Parents',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_lost_routing_parents;
        }
      },
    },
    {
      field: 'cn_lost_timing_parents',
      headerName: 'Lost Timing Parents',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_lost_timing_parents;
        }
      },
    },
    {
      field: 'cn_changed_routing_parents',
      headerName: 'Changed Routing Parents',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_changed_routing_parents;
        }
      },
    },
    {
      field: 'cn_changed_timing_parents',
      headerName: 'Changed Timing Parents',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_changed_timing_parents;
        }
      },
    },
    {
      field: 'cn_network_entry',
      headerName: 'Network Entry',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_network_entry;
        }
      },
    },
    {
      field: 'cn_network_entry_reason',
      headerName: 'Network Entry Reason',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_network_entry_reason;
        }
      },
    },
    {
      field: 'cn_idle_subframes',
      headerName: 'Idle Subframes',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_idle_subframes;
        }
      },
    },
    {
      field: 'cn_neighbor_table_full',
      headerName: 'Neighbor Table Full',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_neighbor_table_full;
        }
      },
    },
    {
      field: 'cn_neighbor_table_count',
      headerName: 'Neighbor Table Count',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_neighbor_table_count;
        }
      },
    },
    {
      field: 'cn_cc1110_version',
      headerName: 'CC1110 Version',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_cc1110_version;
        }
      },
    },
    {
      field: 'cn_pic_version',
      headerName: 'PIC Version',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_pic_version;
        }
      },
    },
    {
      field: 'cn_heartbeat_count',
      headerName: 'Heartbeat Count',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_heartbeat_count;
        }
      },
    },
    {
      field: 'cn_message_overflow_count',
      headerName: 'Message Overflow Count',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_message_overflow_count;
        }
      },
    },
    {
      field: 'cn_routing_above_threshold',
      headerName: 'Routing Above Threshold',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_routing_above_threshold;
        }
      },
    },
    {
      field: 'cn_timing_above_threshold',
      headerName: 'Timing Above Threshold',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_timing_above_threshold;
        }
      },
    },
    {field: 'cn_date_activated', headerName: 'Activated at', sortable: true},
    {
      field: 'cn_date_deactivated',
      headerName: 'Deactivated at',
      sortable: true,
    },
    {
      field: 'wifi_last_ping',
      headerName: 'Last Ping',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'wifi_point') {
          return row.wifi_last_ping;
        }
      },
    },
    {
      field: 'cn_last_update',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_type === 'communication_node') {
          return row.cn_last_update;
        }
      },
    },
  ];

  const shownFields = useMemo(() => {
    if (isEqual(config.params.node_type, ['communication_node'])) {
      return config.shownFields.cn;
    } else if (isEqual(config.params.node_type, ['wifi_point'])) {
      return config.shownFields.wifi;
    }
    return config.shownFields.all;
  }, [config]);

  useEffect(() => {
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter((col) => !!col.headerName && col.field !== 'actions')
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: !shownFields?.includes(col.field),
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    if (isEqual(config.params.node_type, ['communication_node'])) {
      onChange?.(
        update(config, {
          shownFields: {
            cn: {$set: fields},
          },
        })
      );
    } else if (isEqual(config.params.node_type, ['wifi_point'])) {
      onChange?.(
        update(config, {
          shownFields: {
            wifi: {$set: fields},
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          shownFields: {
            all: {$set: fields},
          },
        })
      );
    }
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => config.selectedIds?.includes(getNodeKey(i))),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((i) => getNodeKey(i)) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((i) => getNodeKey(i)),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  /************/
  /* map data */
  /************/
  const mapData = useMemo(() => {
    if (fetchedData?.items && config.selectedIds?.length) {
      return fetchedData?.items.filter((i) =>
        config.selectedIds?.includes(getNodeKey(i))
      );
    }
    return [];
  }, [fetchedData, config.selectedIds, selectedItems]);

  /********************/
  /* refresh interval */
  /********************/
  const refreshPeriod = useConfiguration(
    'auto-refresh',
    'commtrac_commnode_autorefresh_rate'
  );

  return (
    <Box height="100%" width="100%">
      <ResizableColumns
        left={
          <>
            <Box
              display="flex"
              alignItems="center"
              height={isCompactMode ? 56 : 70}
              p={isCompactMode ? 1 : 2}
              minWidth={520}
              gap={2}
            >
              <Button
                onClick={() => onOpenHistory?.(0, 'networkDiagnostics')}
                variant="outlined"
                size="small"
                sx={{height: '100%'}}
              >
                Network Diagnostics
              </Button>
              <AccessControl
                permissions={[
                  'post::/node/communication-node/broadcast_report_frequency',
                ]}
              >
                <CommunicationNodeBroadcastReportFrequencyButton
                  componentProps={{
                    variant: 'outlined',
                    size: 'small',
                    sx: {height: '100%'},
                  }}
                >
                  Broadcast Reporting Frequency
                </CommunicationNodeBroadcastReportFrequencyButton>
              </AccessControl>
            </Box>
            <Map
              panel="nodes_report"
              nodes={mapData}
              selectedMapLayers={config.mapLayers}
              selectedLevel={config.mapLevel}
              height="calc(100% - 70px)"
              minWidth={200}
              availableMapLayers={[
                'employees',
                'assets',
                'street',
                'mine',
                'nodes',
                'alarms',
                'ams_sensor_nodes',
                'routing_network',
                'timing_network',
                'new_devices',
              ]}
              onSelectMapLayers={(v) => {
                onChange?.(
                  update(config, {
                    mapLayers: {
                      $set: v,
                    },
                  })
                );
              }}
              onSelectLevel={(v) => {
                onChange?.(
                  update(config, {
                    mapLevel: {
                      $set: v,
                    },
                  })
                );
              }}
              onOpenItem={onOpenItem}
              onOpenHistory={onOpenHistory}
              onGetClickCoordinates={onChangeLocationCoordinates}
            />
          </>
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box p={isCompactMode ? 1 : 2} minWidth={800}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height={40}
              gap={2}
            >
              <Box flexGrow={2} display="flex" height="100%">
                <ZoneSelect
                  value={config.params.section_ids?.[0]}
                  size="small"
                  nullLabel="All Sections"
                  fullWidth
                  onChange={(v) => {
                    const sectionIds = v ? [+v] : [];
                    onChange?.(
                      update(config, {
                        params: {
                          section_ids: {
                            $set: sectionIds,
                          },
                        },
                      })
                    );
                  }}
                />
              </Box>
              <Box display="flex" height="100%">
                <ButtonGroup>
                  <AccessControl
                    permissions={['post::/node/communication-node']}
                  >
                    <CommunicationNodeItemUpsertButton
                      locationCoordinates={locationCoordinates}
                      component={Button}
                      componentProps={{
                        color: 'primary',
                        size: 'small',
                        startIcon: <AddIcon />,
                      }}
                      onSubmitted={() => {
                        onRefresh?.();
                        onChangeLocationCoordinates?.(undefined);
                      }}
                      onClose={() => {
                        onChangeLocationCoordinates?.(undefined);
                      }}
                    >
                      CN
                    </CommunicationNodeItemUpsertButton>
                  </AccessControl>
                  <AccessControl permissions={['post::/node/wifi-point']}>
                    <WifiPointItemUpsertButton
                      locationCoordinates={locationCoordinates}
                      component={Button}
                      componentProps={{
                        color: 'primary',
                        size: 'small',
                        startIcon: <AddIcon />,
                      }}
                      onSubmitted={() => {
                        onRefresh && onRefresh();
                        onChangeLocationCoordinates?.(undefined);
                      }}
                      onClose={() => {
                        onChangeLocationCoordinates?.(undefined);
                      }}
                    >
                      WiFi
                    </WifiPointItemUpsertButton>
                  </AccessControl>

                  <NodeImportExportButton
                    value={value}
                    component={Button}
                    componentProps={{color: 'primary'}}
                    onSubmitted={() => onRefresh?.()}
                  >
                    <ImportExportIcon />
                  </NodeImportExportButton>

                  <Button onClick={() => onRefresh?.()}>
                    <Tooltip title="Refresh">
                      <RefreshIcon />
                    </Tooltip>
                  </Button>

                  {refreshPeriod ? (
                    <AutoRefreshSettingsSelect refreshPeriod={refreshPeriod} />
                  ) : null}
                  <Button onClick={() => dataGridRef.current?.printTable()}>
                    <PrintIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={loading}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems?.length ? (
                <Box display="flex" alignItems="center" gap={3}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                  <AccessControl permissions={['post::/purge']}>
                    <Box display="flex" height={40}>
                      <NodeItemsPurgeButton
                        cnIds={selectedRows
                          .filter((i) => i.cn_id)
                          .map((i) => i.cn_id)
                          .filter(isPresent)}
                        wifiIds={selectedRows
                          .filter((i) => i.wifi_id)
                          .map((i) => i.wifi_id)
                          .filter(isPresent)}
                        componentProps={{size: 'small', variant: 'outlined'}}
                        onDone={() => onRefresh?.()}
                      >
                        <RemoveCircleOutlineIcon
                          fontSize="small"
                          sx={{mr: 1}}
                        />{' '}
                        Purge
                      </NodeItemsPurgeButton>
                    </Box>
                  </AccessControl>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
    </Box>
  );
};

export default NodesReport;
