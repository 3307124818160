import * as yup from 'yup';

import {
  getIsCommtracExternalIdVisible,
  getIsMacAddressVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsWifiEnabledVisible,
} from '../../utils/commtrac-nodes';
import {
  getMaxLengthMessage,
  getMaxMessage,
  getMinMessage,
  nameMessage,
} from './consts';
import {
  macAddressValidator,
  maxNameLength,
  maxNetworkId,
  maxProximityId,
  nameValidator,
} from './utils';

export const assetMachineBaseInputSchema = yup.object().shape({
  proximity_enabled: yup.boolean().required(),
  hazard_ai_enabled: yup.boolean().required(),
  commtrac_enabled: yup.boolean().required(),
  is_commtrac: yup.boolean().nullable(),
  is_proximity: yup.boolean().required(),
  name: yup
    .string()
    .nullable()
    .matches(nameValidator.regex, nameMessage)
    .required('Field is required')
    .min(1)
    .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
  type_id: yup.number().nullable().required('Field is required'),
  zone_id: yup
    .number()
    .nullable()
    .when(['commtrac_enabled', 'proximity_enabled'], {
      is: (
        commtrac_enabled: boolean | undefined,
        proximity_enabled: boolean | undefined
      ) => commtrac_enabled || proximity_enabled,
      then: (schema) => schema.required('Field is required'),
    }),
  site_id: yup
    .number()
    .nullable()
    .when(['hazard_ai_enabled'], {
      is: (hazard_ai_enabled: boolean | undefined) => hazard_ai_enabled,
      then: (schema) => schema.required('Field is required'),
    }),
  status: yup.string().nullable().required('Field is required'),
  external_id: yup
    .number()
    .nullable()
    .integer()
    .min(1, getMinMessage(1))
    .max(maxProximityId, getMaxMessage(maxProximityId))
    .when(['is_proximity', 'proximity_enabled'], {
      is: (
        is_proximity: boolean | null,
        proximity_enabled: boolean | undefined
      ) => is_proximity && proximity_enabled,
      then: (schema) => schema.required('Field is required'),
    }),
  events_flag: yup
    .string()
    .oneOf(['YES', 'NO'])
    .nullable()
    .when(['is_proximity', 'proximity_enabled'], {
      is: (
        is_proximity: boolean | null,
        proximity_enabled: boolean | undefined
      ) => is_proximity && proximity_enabled,
      then: (schema) => schema.required('Field is required'),
    }),
  wifi_enabled: yup
    .boolean()
    .nullable()
    .when(['is_commtrac'], {
      is: (is_commtrac: boolean | null) => getIsWifiEnabledVisible(is_commtrac),
      then: (schema) => schema.required('Field is required'),
    }),
  min_voltage: yup
    .number()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsMinVoltageVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMaxMessage(100 / 10))
          .required('Field is required'),
    }),
  max_voltage: yup
    .number()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsMaxVoltageVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMaxMessage(100 / 10))
          .required('Field is required'),
    }),
  input_1_type: yup
    .number()
    .nullable()
    .when(['is_proximity', 'proximity_enabled'], {
      is: (
        is_proximity: boolean | null,
        proximity_enabled: boolean | undefined
      ) => is_proximity && proximity_enabled,
      then: (schema) => schema.min(0).required('Field is required'),
    }),
  input_2_type: yup
    .number()
    .nullable()
    .when(['is_proximity', 'proximity_enabled'], {
      is: (
        is_proximity: boolean | null,
        proximity_enabled: boolean | undefined
      ) => is_proximity && proximity_enabled,
      then: (schema) => schema.min(0).required('Field is required'),
    }),
  input_3_type: yup
    .number()
    .nullable()
    .when(['is_proximity', 'proximity_enabled'], {
      is: (
        is_proximity: boolean | null,
        proximity_enabled: boolean | undefined
      ) => is_proximity && proximity_enabled,
      then: (schema) => schema.min(0).required('Field is required'),
    }),
  input_4_type: yup
    .number()
    .nullable()
    .when(['is_proximity', 'proximity_enabled'], {
      is: (
        is_proximity: boolean | null,
        proximity_enabled: boolean | undefined
      ) => is_proximity && proximity_enabled,
      then: (schema) => schema.min(0).required('Field is required'),
    }),
  input_5_type: yup
    .number()
    .nullable()
    .when(['is_proximity', 'proximity_enabled'], {
      is: (
        is_proximity: boolean | null,
        proximity_enabled: boolean | undefined
      ) => is_proximity && proximity_enabled,
      then: (schema) => schema.min(0).required('Field is required'),
    }),
});

export const assetMachineCreateInputSchema = assetMachineBaseInputSchema.shape({
  commtrac_external_id: yup
    .number()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsCommtracExternalIdVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(1, getMinMessage(1))
          .max(maxNetworkId, getMaxMessage(maxNetworkId))
          .required('Field is required'),
    }),
  mac_address: yup
    .string()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsMacAddressVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .matches(
            macAddressValidator.regex,
            macAddressValidator.message.replace('%1', 'Mac Address')
          )
          .required('Field is required'),
    }),
});

export const assetMachineUpdateInputSchema = assetMachineBaseInputSchema.shape({});

export const assetMachineUpdateNetworkInputSchema = yup.object().shape({
  wifi_enabled: yup.boolean().nullable(),
  commtrac_external_id: yup
    .number()
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsCommtracExternalIdVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(1, getMinMessage(1))
          .max(maxNetworkId, getMaxMessage(maxNetworkId))
          .required('Field is required'),
    }),
  mac_address: yup
    .string()
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsMacAddressVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .matches(
            macAddressValidator.regex,
            macAddressValidator.message.replace('%1', 'Mac Address')
          )
          .required('Field is required'),
    }),
});
