import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  pk?: number,
  item?: BeltMonitoringNode;
  status?: string,
  onSubmitted?: (item: BeltMonitoringNode) => void;
  onClose?: () => void;
}

const BeltNodeItemActivationModal = ({
  pk,
  item,
  status,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  console.log("satatus", item);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionName = (item?.status ?? status)  === 'active' ? 'DeActivate' : 'Activate';

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      const resp = await API.patch<BeltMonitoringNode>(
        `${apiBaseUrl}/belt/${pk}`,
        {
          status: actionName === 'Activate' ? 'active' : 'inactive',
        }
      );
      const message = `Belt successfully ${actionName === 'Activate' ? 'activated' : 'deactivated'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{actionName} Belt Monitoring Node</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName} this belt?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(item?.id  ?? pk ?? -1)}
          color={actionName === 'Activate' ? 'success' : 'warning'}
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BeltNodeItemActivationModal;
