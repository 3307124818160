import ApartmentIcon from '@mui/icons-material/Apartment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {useAppSelector} from '../../hooks/redux';
import {Company} from '../../interfaces/Company';
import reduxActions from '../../redux/actions';

interface Props {
  item: Company;
}

export const CompanySelectMenu = ({item}: Props) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const currentCompanyId = useAppSelector(({app}) => app.companyId);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(menuAnchorEl);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpened}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onBackdropClick={() => setMenuAnchorEl(null)}
      >
        <MenuItem
          disabled={currentCompanyId === item.id || item.status !== "active"}
          onClick={() => {
            reduxActions.app.setApp(reduxDispatch, {companyId: item.id});
            reduxActions.assets.clearAssets(reduxDispatch);
            navigate('/dashboards');
          }}
        >
          <ApartmentIcon fontSize="small" sx={{mr: 2}} />
          Set as Current
        </MenuItem>
      </Menu>
    </>
  );
};
