import {AlarmOutlined} from '@mui/icons-material';
import {
  Alert,
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Paper,
  Tooltip,
} from '@mui/material';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  AlarmLogNode,
  AlarmLogNodeListResponse,
} from '../../interfaces/AlarmLogNode';
import reduxActions from '../../redux/actions';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
interface Props {
  formik?: any;
  preSelected?: number[];
  onSelectItems?: (ids: number[]) => void;
}
const AlarmUpdateStatusAlarmList = ({
  onSelectItems,
  formik,
  preSelected,
}: Props) => {
  const dataGridRef = useRef<DataGridRef>(null);
  const zones = useAppSelector(({assets}) => assets.zones);
  const reduxDispatch = useDispatch();
  const activationReason = useAppSelector(
    ({assets}) => assets.activationReason
  );
  const [fetchedAlarmLogData, setFetchedAlarmLogData] =
    useState<AlarmLogNodeListResponse>();
  const [fetchedAlarmLogErrors, setFetchedAlarmLogErrors] = useState<string[]>(
    []
  );
  const [fetchedAlarmLogDataInProgress, setFetchedAlarmLogDataInProgress] =
    useState(false);
  useEffect(() => {
    fetchAlarmLogs();
  }, []);

  const fetchAlarmLogs = async () => {
    setFetchedAlarmLogDataInProgress(true);
    setFetchedAlarmLogErrors([]);

    try {
      const params = {};

      const resp = await API.get<AlarmLogNodeListResponse>(
        `${apiBaseUrl}/alarm-module`,
        {params}
      );

      setFetchedAlarmLogData(resp.data);
      reduxActions.assets.setAssets(reduxDispatch, {
        alarm_logs: resp.data.items,
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedAlarmLogErrors(messages);
    } finally {
      setFetchedAlarmLogDataInProgress(false);
    }
  };

  const rows = useMemo(() => {
    return fetchedAlarmLogData?.items?.filter((it) => it.status !== 'inactive');
  }, [fetchedAlarmLogData]);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    if (onSelectItems) {
      setSelectedItems(preSelected ?? []);
    }
  }, [preSelected]);

  useEffect(() => {
    if (onSelectItems) {
      onSelectItems(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (formik.values.module === 'all') {
      selectAll();
    }
  }, [formik.values.module]);
  useEffect(() => {
    if (formik.values.module === 'all') {
      selectAll();
    }
  });

  const selectedRows = useMemo(
    () => rows?.filter((i) => selectedItems.includes(i.id)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows?.length === selectedRows?.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (selectedItems?.includes(id)) {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const selectAll = () => {
    setSelectedItems((rows ?? []).map((i) => i.id));
  };
  const unselectAll = () => {
    setSelectedItems([]);
  };
  const toggleSelectAllItems = () => {
    if (selectedItems.length >= (rows?.length ?? 0)) {
      unselectAll();
    } else {
      selectAll();
    }
  };
  const columns: DataGridColumn<AlarmLogNode>[] = [
    {
      field: 'select',
      type: 'select',
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows?.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'type',
      headerName: 'Alarm',
      sortable: true,
      renderHeader: () => {
        return <AlarmOutlined />;
      },
      renderCell: ({row}) => {
        if (row.alarm) {
          return (
            <Tooltip title="Alarm">
              <AlarmOutlined color="error" />
            </Tooltip>
          );
        } else if (row.warning) {
          return (
            <Tooltip title="Warning">
              <AlarmOutlined color="warning" />
            </Tooltip>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'reason',
      headerName: 'Reason',
      renderCell: ({row}) => {
        if (row.alarm) {
          return (
            <>
              {activationReason?.find((o) => o.key === row.alarm_reason)
                ?.label ?? ''}
            </>
          );
        } else if (row.warning) {
          return (
            <>
              {activationReason?.find((o) => o.key === row.warning_reason)
                ?.label ?? ''}
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'note',
      headerName: 'Note',
      renderCell: ({row}) => {
        if (row.alarm) {
          return <>{row.alarm_reason_note}</>;
        } else if (row.warning) {
          return <>{row.warning_reason_note}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.status}</Box>;
      },
    },
    {
      field: 'zone_id',
      headerName: 'Section',
      sortable: true,
      renderCell: ({row}) => {
        return (
          <Box whiteSpace="nowrap">
            {zones?.find((it) => it.id === row.zone_id)?.name}
          </Box>
        );
      },
    },
    {
      field: 'mac_address',
      headerName: 'Mac Address',
      sortable: true,
      renderCell: ({row}) => {
        return row.mac_address;
      },
    },
  ];
  return (
    <Paper sx={{p: 2, px: 3, mb: 2}}>
      <Box fontSize={16} mb={2}>
        Select the alarm modules you want apply:
      </Box>
      {fetchedAlarmLogErrors.map((error, idx) => (
        <Alert
          key={`error-es-${idx}`}
          severity="error"
          onClose={() => setFetchedAlarmLogErrors([])}
        >
          {error}
        </Alert>
      ))}
      <FormHelperText
        error={!!formik.touched.alarm_modules && !!formik.errors.alarm_modules}
      >
        {formik.touched.alarm_modules && formik.errors.alarm_modules}
      </FormHelperText>
      <DataGrid
        ref={dataGridRef}
        rows={rows ?? []}
        loading={fetchedAlarmLogDataInProgress}
        columns={columns}
        size="small"
        pagination
        sx={{
          td: {
            padding: '10px 16px',
          },
        }}
      />
      <Backdrop
        open={fetchedAlarmLogDataInProgress}
        sx={{position: 'absolute'}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default AlarmUpdateStatusAlarmList;
