import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import AMSEModuleItemCreate from './AMSEModuleItemCreate';
import AMSEModuleNodeItem from './AMSEModuleNodeItem';

interface Props {
  pk?: number;
  item?: AMSEModuleNode;
  mode?: 'view' | 'update' | 'ack' | 'active';
  prefetch?: boolean;
  isActiveModal?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (
    externalId: number,
    type: 'amsEmoduleSensorHistory' | 'amsEmoduleCalibration'
  ) => void;
}

const AMSEModuleItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <AMSEModuleNodeItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      onClose={onClose}
      onOpenHistory={onOpenHistory}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
    />
  ) : (
    <AMSEModuleItemCreate onCancel={onClose} onSubmitted={onSubmitted} />
  );
};

export default AMSEModuleItemUpsert;
