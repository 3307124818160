import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {AlarmLogNode} from '../../interfaces/AlarmLogNode';
import AccessControl from '../common/AccessControl';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  setViewType?: Function;
  onOpenItem?: Function;
  onOpenHistory?: Function;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  item?: AlarmLogNode;
}

const AlarmItemEditButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  // setViewType,
  onOpenHistory,
  onOpenItem,
  item,
}: // onPurged,
Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <AccessControl permissions={['patch::/alarm-module/:id(\\d+)']}>
          <MenuItem
            onClick={() => {
              onOpenItem?.(item?.id, 'alarm', 'update');
              setAnchorEl(null);
            }}
          >
            Edit Alarm
          </MenuItem>
        </AccessControl>
        <MenuItem
          onClick={() => {
            onOpenItem?.(item?.id, 'alarm', 'view');
            setAnchorEl(null);
          }}
        >
          Info & Actions
        </MenuItem>
        <MenuItem
          onClick={() => {
            console.log(item);
            onOpenHistory?.(item?.id, 'alarm');
            setAnchorEl(null);
          }}
        >
          View Alarm History
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenHistory?.(item?.id, 'alarm_log');
            setAnchorEl(null);
          }}
        >
          View Alarm Log
        </MenuItem>
      </Menu>
    </>
  );
};

export default AlarmItemEditButton;
