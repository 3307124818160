/* eslint-disable complexity */
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  TextField,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useGetAssetMachineType} from '../../hooks/get-commtrac-node-type';
import {useAppSelector} from '../../hooks/redux';
import {AssetMachine} from '../../interfaces/AssetMachine';
import {getBooleanValue} from '../../utils/boolean';
import {
  getIsCommtracExternalIdVisible,
  getIsMacAddressVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsWifiEnabledVisible,
} from '../../utils/commtrac-nodes';
import AccessControl from '../common/AccessControl';
import {getHasPermission} from '../common/AccessControl/utils';
import {MachineTypeSelect} from '../selectors/MachineTypeSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import AssetMachineItemActionsButton from './AssetMachineItemActionsButton';

interface Props {
  pk: number;
  item?: AssetMachine;
  prefetch?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onAck?: () => void;
  onPurged?: () => void;
  onFetched?: (item: AssetMachine) => void;
  onOpenHistory?: (item: AssetMachine) => void;
  onSubmitted?: () => void;
}

const TABS = [
  {value: 'general', label: 'General'},
  {value: 'location', label: 'Location'},
  {value: 'connection', label: 'Connection'},
  // {value: 'sensors', label: 'Sensors'},
];

const AssetMachineItemView = ({
  pk,
  item,
  prefetch,
  onCancel,
  onEdit,
  onAck,
  onPurged,
  onFetched,
  onOpenHistory,
  onSubmitted,
}: Props) => {
  const [activeTab, setActiveTab] = useState('general');
  const me = useAppSelector(({app}) => app.me);
  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const company = useAppSelector(({assets}) => assets.company);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AssetMachine>(
        `${apiBaseUrl}/asset-machine/${pk}`
      );
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  const isWifiEnabledVisible = getIsWifiEnabledVisible(true);
  const isCommtracExternalIdVisible = getIsCommtracExternalIdVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isMacAddressVisible = getIsMacAddressVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isMinVoltageVisible = getIsMinVoltageVisible(
    true,
    !!fetchedData?.wifi_enabled
  );
  const isMaxVoltageVisible = getIsMaxVoltageVisible(
    true,
    !!fetchedData?.wifi_enabled
  );

  const getAssetMachineType = useGetAssetMachineType();

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.name ?? ''}
              label="Name"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/asset-machine/:id']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />

            <TextField
              value={fetchedData?.description ?? ''}
              label="Description"
              size="small"
              disabled
              fullWidth
              multiline
              rows={3}
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/asset-machine/:id']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />

            <MachineTypeSelect
              value={fetchedData?.type_id}
              label="Machine Type"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/asset-machine/:id']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />

            <TextField
              value={
                getAssetMachineType({
                  commtrac_external_id: fetchedData?.commtrac_external_id,
                  wifi_enabled: fetchedData?.wifi_enabled,
                }) ?? ''
              }
              label="AT Type"
              size="small"
              disabled
              fullWidth
            />

            <ZoneSelect
              value={fetchedData?.zone_id}
              label="Assigned Section"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/asset-machine/:id']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />

            <StatusSelect
              value={fetchedData?.status}
              fullWidth
              label="Status"
              size="small"
              disabled
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/asset-machine/:id']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />

            <TextField
              value={fetchedData?.date_activated ?? ''}
              label="Activated TimeStamp"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.date_deactivated ?? ''}
              label="DeActivated TimeStamp"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>

        <TabPanel value="location" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <ZoneSelect
              value={fetchedData?.commtrac_current_zone_id}
              label="Section"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_pos_lat ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_pos_lon ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_strongest_cn_name ?? ''}
              label="Strongest Node"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_date ?? ''}
              label="Timestamp"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={getBooleanValue(
                fetchedData?.commtrac_on_surface,
                'Underground',
                'On Surface'
              )}
              label="Pos"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.commtrac_beacon_data ?? ''}
              label="Beacon Data"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="connection" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            {isWifiEnabledVisible ? (
              <TextField
                value={getBooleanValue(fetchedData?.wifi_enabled ?? false)}
                label="WiFi Enabled"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {isCommtracExternalIdVisible ? (
              <TextField
                value={fetchedData?.commtrac_external_id ?? ''}
                label="Network ID"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {isMacAddressVisible ? (
              <TextField
                value={fetchedData?.mac_address ?? ''}
                label="Mac Address"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {isMinVoltageVisible ? (
              <TextField
                value={
                  fetchedData?.min_voltage ? fetchedData?.min_voltage / 10 : ''
                }
                label="Min Voltage"
                size="small"
                disabled
                fullWidth
              />
            ) : null}

            {isMaxVoltageVisible ? (
              <TextField
                value={
                  fetchedData?.max_voltage ? fetchedData?.max_voltage / 10 : ''
                }
                label="Max Voltage"
                size="small"
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <AccessControl permissions={['patch::/asset-human/:id']}>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => onEdit?.()}
                      />
                    </AccessControl>
                  ),
                }}
              />
            ) : null}

            {company?.proximity_enabled ? (
              <TextField
                value={fetchedData?.external_id ?? ''}
                label="Proximity ID"
                size="small"
                disabled
                fullWidth
                InputProps={{
                  endAdornment: (
                    <AccessControl permissions={['patch::/asset-machine/:id']}>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => onEdit?.()}
                      />
                    </AccessControl>
                  ),
                }}
              />
            ) : null}
          </Box>
        </TabPanel>
        {/* <TabPanel value="sensors" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={getBooleanValue(fetchedData?.events_flag)}
              label="ATest"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              label="Machine Input 1"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              label="Machine Input 2"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              label="Machine Input 3"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              label="Machine Input 4"
              size="small"
              fullWidth
              disabled
            />

            <TextField
              label="Machine Input 5"
              size="small"
              fullWidth
              disabled
            />
          </Box>
        </TabPanel> */}
      </TabContext>

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="start" gap={0.5}>
          {onOpenHistory && fetchedData?.commtrac_node_id ? (
            <Button onClick={() => onOpenHistory(fetchedData)}>History</Button>
          ) : null}
          {fetchedData ? (
            <>
              {onAck && fetchedData?.commtrac_ack !== '1' ? (
                <AccessControl
                  permissions={['patch::/commtrac-node/:id(\\d+)/miner/acknowledge']}
                >
                  <Button onClick={() => onAck()}>Acknowledge</Button>
                </AccessControl>
              ) : null}
                {(getHasPermission(me?.permissions ?? [], ['patch::/asset-machine/:id']) ||
                getHasPermission(me?.permissions ?? [], ['post::/purge']) ||
                me?.type_id === 1 || me?.type_id === 2 || me?.type_id === 3) && (
                <AssetMachineItemActionsButton
                  item={fetchedData}
                  component={IconButton}
                  componentProps={{size: 'small', color: 'primary'}}
                  onSubmitted={() => {
                    onSubmitted?.();
                  }}
                  onPurged={onPurged}
                >
                  <MoreHorizIcon />
                </AssetMachineItemActionsButton>
                )}
            </>
          ) : null}
        </Box>
        <Box display="flex" justifyContent="end" gap={0.5}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <AccessControl permissions={['patch::/asset-machine/:id']}>
              <Button onClick={() => onEdit()}>Edit Asset</Button>
            </AccessControl>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default AssetMachineItemView;
