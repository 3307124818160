import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, IconButton, Tooltip} from '@mui/material';
import Image from 'mui-image';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import SensorIcon from '../../../images/ams/sensors/icon_sensor.svg';
import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import ModalDraggable from '../../common/ModalDraggable';
import BeltNodeItemAcknowledge from './BeltNodeItemAcknowledge';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk: number;
  item?: BeltMonitoringNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  locationCoordinates?: MapLatLangCoordinates;
  children?: ReactNode;
  opended?: boolean;
  onSubmitted?: (item: BeltMonitoringNode) => void;
  onClose: (id: number) =>void;
}

const BeltNodeItemAcknowledgeButton = <
  T extends ComponentType = typeof Button,
>({
  pk,
  component,
  componentProps,
  item,
  locationCoordinates,
  children,
  opended,
  onSubmitted,
  onClose,
  ...props
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(opended ?? false);

  return (
    <>
      <Tooltip title="Acknowledge">
        <Component {...componentProps} onClick={() => setIsOpened(true)}>
          {children}
        </Component>
      </Tooltip>

      {isOpened ? (
        <ModalDraggable open={isOpened}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                className="dragHandle"
                display="flex"
                alignItems="center"
                width="100%"
                gap={1.5}
              >
                <Image src={SensorIcon} alt="heat map" width={26} height={18} />
                <Box fontSize={24}>Acknowledge Belt Node</Box>
              </Box>
              <IconButton onClick={() => {
                setIsOpened(false);
                onClose(item?.id ?? pk);
              }}>
                <CloseIcon/>
              </IconButton>
            </Box>
            <BeltNodeItemAcknowledge
              {...props}
              pk={pk}
              item={item}
              locationCoordinates={locationCoordinates}
              onSubmitted={(item) => {
                setIsOpened(false);
                onSubmitted?.(item);
                onClose(item.id);
              }}
              onCancel={() => setIsOpened(false)}
            />
          </Box>
        </ModalDraggable>
      ) : null}
    </>
  );
};

export default BeltNodeItemAcknowledgeButton;
