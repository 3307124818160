import {Button, Divider, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import { useAppSelector } from '../../../hooks/redux';
import {BELT_STATUS_LABEL} from '../../../interfaces/BeltMonitoring';
import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {getBeltNodeStatus} from '../../../utils/belt';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  setViewType?: Function;
  onOpenItem?: Function;
  onOpenHistory?: Function;
  onUpdateRun?: Function;
  onUpdateStatus?: Function;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  item?: BeltMonitoringNode;
}

const BeltNodeItemEditButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  onOpenItem,
  onUpdateRun,
  onUpdateStatus,
  item,
  onOpenHistory
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const beltData = useAppSelector(({assets}) => assets.belt);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onOpenHistory?.(item?.id, 'beltHistoryReport');
            setAnchorEl(null);
          }}
        >
          View Belt History
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenHistory?.(item?.id, 'beltLocationHistoryReport');
            setAnchorEl(null);
          }}
        >
          View Belt Location History
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenItem?.(item?.id, 'belt', 'update');
            setAnchorEl(null);
          }}
        >
          Edit Belt
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenItem?.(item?.id, 'belt', 'view');
            setAnchorEl(null);
          }}
        >
          Info & Actions
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onOpenItem?.(item?.id, 'belt', 'active', item?.status);
            setAnchorEl(null);
          }}
        >
          {item?.status === 'active' ? 'DeActivate': 'Activate'} Belt{`(${item?.name})`}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onUpdateRun?.(item?.id ?? -1, BELT_STATUS_LABEL[item?.running?.toString() ?? '0']);
            setAnchorEl(null);
          }}
        >
          {BELT_STATUS_LABEL[item?.running?.toString() ?? '0']} Belt
        </MenuItem>
        <MenuItem
          onClick={() => {
            onUpdateStatus?.(item?.id ?? -1, BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 1", beltData?.output_bit_label, item?.output_bits)], 1);
            setAnchorEl(null);
          }}
        >
          {BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 1", beltData?.output_bit_label, item?.output_bits)]} Misc Output 1
        </MenuItem>
        <MenuItem
          onClick={() => {
            onUpdateStatus?.(item?.id ?? -1, BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 2", beltData?.output_bit_label, item?.output_bits)], 2);
            setAnchorEl(null);
          }}
        >
          {BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 2", beltData?.output_bit_label, item?.output_bits)]} Misc Output 2
        </MenuItem>
        <MenuItem
          onClick={() => {
            onUpdateStatus?.(item?.id ?? -1, BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 3", beltData?.output_bit_label, item?.output_bits)], 3);
            setAnchorEl(null);
          }}
        >
          {BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 3", beltData?.output_bit_label, item?.output_bits)]} Misc Output 3
        </MenuItem>
        <MenuItem
          onClick={() => {
            onUpdateStatus?.(item?.id ?? -1, BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 4", beltData?.output_bit_label, item?.output_bits)], 4);
            setAnchorEl(null);
          }}
        >
          {BELT_STATUS_LABEL[getBeltNodeStatus("Misc Output 4", beltData?.output_bit_label, item?.output_bits)]} Misc Output 4
        </MenuItem>
      </Menu>
    </>
  );
};

export default BeltNodeItemEditButton;
