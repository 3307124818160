import {ErrorBoundary} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '../../hooks/redux';
import {DashboardEntity, DashboardPanelData} from '../../interfaces/Dashboard';
import {panelCodesEnabledSelect} from '../../redux/assets/selectors';
import {PanelCode, PANELS_DATA} from '../../utils/panels';
import AccessControl from '../common/AccessControl';
import AlertFullSize from '../common/AlertFullSize';
import ErrorBoundaryFallback from '../common/ErrorBoundaryFallback';
import {usePanel} from '../dashboards/entities/DashboardEntityContext';
import {AlarmGrid} from './AlarmGrid';
import {AlarmHistoryReports} from './AlarmHistoryTrackingReports';
import {AlarmLogReports} from './AlarmLogTrackingReports';
import {AMSEModuleCalibration} from './AMSEModuleCalibrationHistory';
import {AmsEmoduleInstallationHistory} from './AmsEmoduleInstallationHistory';
import {AmsEmoduleSensorHistory} from './AmsEmoduleSensorHistory';
import {GasMonitoringReport} from './AMSSensor';
import {Backend} from './Backend';
import {BackHaulers} from './BackHaulers';
import {BeltHistoryReport} from './BeltHistoryReport';
import {BeltLocationHistoryReport} from './BeltLocationHistoryReport';
import {CheckoutStationDetailsReport} from './CheckoutStationDetailsReport';
import {CheckoutStationOverviewReport} from './CheckoutStationOverviewReport';
import {CnWifiNodes} from './CnWifiNodes';
import {CommtracNodeTrackingReports} from './CommtracNodeTrackingReports';
import {ConnectView} from './ConnectView';
import {DashboardPanelTitleSlot} from './DashboardPanelTitleSlot';
import {EmployeeAssetsGrid} from './EmployeeAssetsGrid';
import {EmployeeHistoryReport} from './EmployeeHistoryReport';
import {EmployeeOverviewReport} from './EmployeeOverviewReport';
import {HazardAI} from './HazardAI';
import {HazardAIDectionLog} from './HazardAIDetectionLog';
import {HazardAIMachineSpeedChart} from './HazardAIMachineSpeedChart';
import {HazardAIMachineUtilizationChart} from './HazardAIMachineUtilizationChart';
import {HazardAISummaryChart} from './HazardAISummaryChart';
import {HazardHeatMapReports} from './HazardHeatmap';
import {InteractionsLastDays} from './InteractionsLastDays';
import {InteractionsPerMachine} from './InteractionsPerMachine';
import {MachineDetailsReport} from './MachineDetailsReport';
import {MachineHistoryReport} from './MachineHistoryReport';
import {MachineInteractionReport} from './MachineInteractionReport';
import {MachineOverviewReport} from './MachineOverviewReport';
import {MachineStatusReport} from './MachineStatusReport';
import {MachineSummaryReport} from './MachineSummaryReport';
import {Map} from './Map';
import {MCronLogs} from './MCronLogs';
import {MProcessorLogs} from './MProcessorLogs';
import {Mqtt} from './Mqtt';
import {NetworkDiagnostics} from './NetworkDiagnostics';
import {Nginx} from './Nginx';
import {NodeTrackingReports} from './NodeTrackingReports';
import {SentroGateway} from './SentroGateway';
import {SystemServicesTable} from './SystemServicesTable';
import {TopEmployeeInteractions} from './TopEmployeeInteractions';

export type DashboardHistoryReportType =
  | 'asset'
  | 'cn'
  | 'wifi'
  | 'nodes'
  | 'wifiLongTerm'
  | 'employee'
  | 'commtracNodeByCn'
  | 'networkDiagnostics'
  | 'alarm'
  | 'alarm_log'
  | 'hazard_ai_detection_log'
  | 'hazard_ai_heatmap'
  | 'amsShortTerm'
  | 'amsLongTerm'
  | 'amsLocation'
  | 'amsEmoduleInstallationHistory'
  | 'amsEmoduleSensorHistory'
  | 'amsEmoduleCalibration'
  | 'beltHistoryReport'
  | 'beltLocationHistoryReport';

interface Props {
  code?: PanelCode;
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value?: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string | number[],
    type: DashboardHistoryReportType
  ) => void;
}

const COMPONENTS: {
  [key in PanelCode]: (props: Props) => JSX.Element;
} = {
  MachineDetailsReport,
  MachineOverviewReport,
  MachineHistoryReport,
  MachineStatusReport,
  EmployeeHistoryReport,
  EmployeeOverviewReport,
  MachineInteractionReport,
  MachineSummaryReport,
  CheckoutStationOverviewReport,
  CheckoutStationDetailsReport,
  InteractionsPerMachine,
  TopEmployeeInteractions,
  InteractionsLastDays,
  ConnectView,
  HazardAI,
  HazardAIDectionLog,
  HazardAIMachineUtilizationChart,
  HazardAIMachineSpeedChart,
  HazardAISummaryChart,
  Map,
  MCronLogs,
  MProcessorLogs,
  SystemServicesTable,
  CommtracNodeTrackingReports,
  AlarmHistoryReports,
  AlarmLogReports,
  NodeTrackingReports,
  NetworkDiagnostics,
  BackHaulers,
  SentroGateway,
  AlarmGrid,
  CnWifiNodes,
  EmployeeAssetsGrid,
  HazardHeatMapReports,
  Nginx,
  Backend,
  Mqtt,
  GasMonitoringReport,
  AmsEmoduleInstallationHistory,
  AmsEmoduleSensorHistory,
  AMSEModuleCalibration,
  BeltHistoryReport,
  BeltLocationHistoryReport,
};

const DashboardPanelItem = ({
  code,
  value,
  entities,
  onUpdate,
  onOpenHistory,
}: Props) => {
  const {t} = useTranslation();
  const [, setPanel] = usePanel();
  const panelCodesEnabled = useAppSelector(panelCodesEnabledSelect);

  const panelData = code ? PANELS_DATA[code] : null;
  const Component = code ? COMPONENTS[code] : null;

  if (!code || !panelData || !Component) {
    return (
      <AlertFullSize severity="error">
        Panel {code} does not exits.
      </AlertFullSize>
    );
  }

  if (!panelCodesEnabled.includes(code)) {
    return (
      <AlertFullSize severity="error">
        You do not have access to this content.
      </AlertFullSize>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={() => setPanel?.({code})}
    >
      <AccessControl
        permissions={panelData.permissions}
        roles={panelData.roles}
        fallback={
          <>
            <DashboardPanelTitleSlot>
              {t(`panels.${code}`)}
            </DashboardPanelTitleSlot>
            <AlertFullSize severity="error">
              You do not have access to this content.
            </AlertFullSize>
            <DashboardPanelTitleSlot>
              {t(`panels.${code}`)}
            </DashboardPanelTitleSlot>
            <AlertFullSize severity="error">
              You do not have access to this content.
            </AlertFullSize>
          </>
        }
      >
        <Component
          value={value}
          entities={entities}
          onUpdate={onUpdate}
          onOpenHistory={onOpenHistory}
        />
      </AccessControl>
    </ErrorBoundary>
  );
};

export default DashboardPanelItem;
