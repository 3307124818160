import {AlarmOutlined} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {
  AlarmHistory,
  AlarmHistoryListQuery,
  AlarmHistoryListResponse,
} from '../../../interfaces/AlarmsHistory';
import {getAlarmNodeHistoryId} from '../../../utils/message-types';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {MapLayerId} from '../../common/Map';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import MessageTypeSelect from '../../selectors/MessageTypeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: AlarmLogTrackingReportsData;
  onUpdate?: (value?: AlarmLogTrackingReportsData) => void;
}

interface AlarmLogTrackingReportsDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: string[];
  selectAll: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    message_type?: number[];
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
  };
}

export interface AlarmLogTrackingReportsData {
  activeId?: number;
  mapLayers?: MapLayerId[];
  mapLevel?: number | null;
  openedItems: AlarmLogTrackingReportsDataTab[];
}

export const getAlarmLogTrackingReportsData =
  (): AlarmLogTrackingReportsData => ({
    activeId: undefined,
    mapLayers: ['employee_history', 'street', 'mine'],
    mapLevel: null,
    openedItems: [],
  });

const getAlarmLogTrackingReportsDataTab = (
  id: number
): AlarmLogTrackingReportsDataTab => ({
  id,
  selectAll: true,
  params: {
    date_start: dayjs().format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  },
});

const MESSAGE_TYPE = [
  {
    id: 1,
    label: 'Heartbeat',
  },
  {
    id: 50,
    label: 'Control',
  },
] as const;

const MESSAGE_TYPE_OPTIONS = [
  {
    code: 1,
    name: 'Enable',
  },
  {
    code: 50,
    name: 'Disable',
  },
];

const DEFAULT_SHOWN_FIELDS = [
  'id',
  'type',
  'reason',
  'reason_note',
  'message_type',
  'user_id',
  'source_mac_address',
  'mac_address',
  'date',
];

export const AlarmLogTrackingReports = ({value, onUpdate}: Props) => {
  const activationReason = useAppSelector(
    ({assets}) => assets.activationReason
  );

  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config = useMemo(() => {
    const v = value ?? getAlarmLogTrackingReportsData();
    return {
      ...v,
      mapLayers: v.mapLayers ?? ['employee_history', 'street', 'mine'],
      mapLevel: v.mapLevel ?? null,
    };
  }, [value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex]
          ? config.openedItems[openedItemIndex]
          : getAlarmLogTrackingReportsDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const alarms = useAppSelector(({assets}) => assets.alarm_logs);
  const tabNames = useMemo(
    () =>
      config.openedItems.map((o) => {
        const alarm = alarms.find((i) => i.id === o.id);
        return alarm?.name ?? '';
      }),
    [config.openedItems, alarms]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<AlarmHistoryListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<AlarmHistoryListQuery | null>(
    () =>
      openedItem?.id
        ? {
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
            message_type:
              openedItem.params?.message_type?.map((it) =>
                it === 1 ? 'enable' : 'disable'
              ) ?? [],
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: AlarmHistoryListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/alarm-module/${config.activeId}/log`;
        const resp = await API.get<AlarmHistoryListResponse>(endpoint, {
          params,
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/
  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);
  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];

  const columns: DataGridColumn<AlarmHistory>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(getAlarmNodeHistoryId(row))}
          onChange={() => toggleSelectItem(getAlarmNodeHistoryId(row))}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Alarm',
      renderHeader: () => {
        return <AlarmOutlined />;
      },
      renderCell: ({row}) => {
        if (row.alarm) {
          const tooltipContent = `
            <span>Alarm</span><br />
            <span>Reason: ${
              activationReason?.find((it) => it.key === row?.reason)?.label ??
              '_'
            }</span><br />
            <span>Reason Note: ${row.reason_note ?? '_'}</span>
          `;
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{__html: tooltipContent}} />}
              arrow
            >
              <AlarmOutlined color="error" />
            </Tooltip>
          );
        } else if (row.warning) {
          const tooltipContent = `
            <span>Warning</span><br />
            <span>Reason: ${
              activationReason?.find((it) => it.key === row?.reason)?.label ??
              '_'
            }</span><br />
            <span>Reason Note: ${row.reason_note ?? '_'}</span>
          `;
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{__html: tooltipContent}} />}
              arrow
            >
              <AlarmOutlined color="warning" />
            </Tooltip>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'reason',
      headerName: 'Reason',
      sxCell: {whiteSpace: 'nowrap'},
      sortable: true,
      renderCell: ({row}) =>
        activationReason?.find((it) => it.key === row?.reason)?.label ?? '',
    },
    {
      field: 'reason_note',
      headerName: 'Note',
      sortable: true,
      sxCell: {minWidth: 300},
      renderCell: ({row}) => row.reason_note ?? '',
    },
    {
      field: 'user_id',
      headerName: 'User',
      sortable: true,
      renderCell: ({row}) => row?.user?.name ?? '',
    },
    {
      field: 'message_type',
      headerName: 'Message Type',
      sortable: true,
      renderCell: ({row}) => {
        return (
          MESSAGE_TYPE.find((it) => it.id === row.message_type)?.label ?? ''
        );
      },
    },
    {
      field: 'source_mac_address',
      headerName: 'Source Mac Address',
      sortable: true,
      renderCell: ({row}) => row.source_mac_address,
    },
    {
      field: 'mac_address',
      headerName: 'Mac Address',
      sortable: true,
      renderCell: ({row}) => row.mac_address,
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sortable: true,
      sxCell: {minWidth: 180},
      renderCell: ({row}) => row.date,
    },
  ];

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = useMemo(
    () => openedItem?.selectAll ? rows.map((it) => getAlarmNodeHistoryId(it)) : openedItem?.selectedIds ?? [],
    [rows, openedItem]
  );

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems?.includes(getAlarmNodeHistoryId(i))),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i) => getAlarmNodeHistoryId(i)) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  return (
    <>
      <DashboardPanelTitleSlot>Alarm Log Reports</DashboardPanelTitleSlot>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="hidden"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          py={1.5}
          bgcolor={(theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
          }
        >
          {isOpenAwayFromConnectView && (
            <Box px={1.5}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <Button variant="outlined" {...bindTrigger(popupState)}>
                      Select an Object
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      {alarms?.map((it) => (
                        <MenuItem
                          key={it.id}
                          onClick={() => {
                            onUpdate?.(
                              update(value, {
                                activeId: {
                                  $set: it.id ?? undefined,
                                },
                                openedItems: {
                                  $set: [
                                    ...config.openedItems,
                                    {
                                      id: it.id ?? -1,
                                    } as AlarmLogTrackingReportsDataTab,
                                  ],
                                },
                              })
                            );
                            popupState.close();
                          }}
                        >
                          {it.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </PopupState>
            </Box>
          )}
          {config.openedItems.length ? (
            <Box px={2}>
              <Tabs
                value={openedItem?.id}
                variant="scrollable"
                onChange={(_event, v) => {
                  if (v) {
                    onUpdate?.(
                      update(config, {
                        activeId: {
                          $set: v,
                        },
                      })
                    );
                  }
                }}
              >
                {config.openedItems.map((i, idx) => (
                  <Tab
                    key={i.id}
                    value={i.id}
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        <Box>{tabNames?.[idx] ?? 'Undefined'}</Box>
                        <CloseIcon
                          fontSize="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            onUpdate?.(
                              update(value, {
                                activeId: {
                                  $set:
                                    config.activeId === i.id
                                      ? undefined
                                      : config.activeId,
                                },
                                openedItems: {
                                  $set:
                                    config.openedItems.filter(
                                      (o) => o.id && o.id !== i.id
                                    ) ?? [],
                                },
                              })
                            );
                          }}
                        />
                      </Box>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          ) : (
            <Box minWidth={400} px={1.5}>
              <Alert severity="warning">No Alarms Opened</Alert>
            </Box>
          )}

          <Box display="flex" flexDirection="column" px={1.5}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Box display="flex" gap={1}>
                <Box minWidth={400}>
                  <DateRangeSelect
                    value={
                      openedItem
                        ? [
                            dayjs(openedItem.params?.date_start).toDate(),
                            dayjs(openedItem.params?.date_end).toDate(),
                          ]
                        : undefined
                    }
                    size="small"
                    disabled={!openedItem}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  selectAll: true,
                                  params: {
                                    ...openedItem.params,
                                    date_start: v?.[0]
                                      ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                      : undefined,
                                    date_end: v?.[0]
                                      ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                      : undefined,
                                  },
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />
                </Box>
                <MessageTypeSelect
                  value={openedItem?.params?.message_type}
                  size="small"
                  type="message_type"
                  disabled={!openedItem}
                  options={MESSAGE_TYPE_OPTIONS}
                  onChange={(v) => {
                    if (openedItem) {
                      onUpdate?.(
                        update(config, {
                          openedItems: {
                            [openedItemIndex]: {
                              $set: {
                                ...openedItem,
                                selectAll: true,
                                params: {
                                  ...openedItem.params,
                                  message_type: v,
                                },
                              },
                            },
                          },
                        })
                      );
                    }
                  }}
                />
              </Box>

              <Box display="flex">
                <ButtonGroup disabled={!openedItem}>
                  <Button
                    size="small"
                    onClick={() => params && fetchData(params)}
                  >
                    <RefreshIcon />
                  </Button>

                  <AutoRefreshSelect
                    value={openedItem?.refreshInterval ?? null}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  refreshInterval: v,
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />

                  <Button
                    size="small"
                    onClick={() => dataGridRef.current?.printTable()}
                  >
                    <PrintIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
        </Box>

        <Backdrop
          open={fetchedInProgress}
          sx={{position: 'absolute', zIndex: 1199}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, idx) => (
          <Alert
            key={idx}
            severity="error"
            onClose={() => params && fetchData(params)}
          >
            {error}
          </Alert>
        ))}

        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          shownFields={shownFields}
          pagination
          paginationMode="server"
          size="small"
          sortBy={
            params?.order
              ? {
                  field: params?.order,
                  dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                }
              : null
          }
          sortingMode="server"
          page={params?.page}
          pageSize={params?.limit}
          rowCount={fetchedData?.count}
          sxFooter={{
            bgcolor: (theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
          }}
          onPageChange={(v) => {
            if (openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        selectAll: true,
                        params: {
                          ...openedItem.params,
                          page: v,
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onPageSizeChange={(v) => {
            if (openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        selectAll: true,
                        params: {
                          ...openedItem.params,
                          page: 0,
                          limit: v,
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onSort={(v) => {
            if (v && openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        params: {
                          ...openedItem.params,
                          order: v.field,
                          dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onShownFieldsChange={setShownFields}
        />
      </Box>
    </>
  );
};
