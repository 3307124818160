import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import { omit } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  CommunicationNode,
  CommunicationNodeUpdateInputBody,
} from '../../interfaces/CommunicationNodeV2';
import {communicationNodeUpdateInputSchema} from '../../scheme/yup/communication-node';
import { getHasPermission } from '../common/AccessControl/utils';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../common/Map';
import SnackbarMessages from '../common/SnackbarMessages';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  pk: number;
  mode?: 'view' | 'update' | 'ack' | 'update_from_info';
  item?: CommunicationNode;
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: Function;
  onSubmitted?: (item: CommunicationNode) => void;
}

const CommunicationNodeItemUpdate = ({
  pk,
  mode,
  item,
  prefetch,
  locationCoordinates,
  isActiveModal,
  onCancel,
  onSubmitted,
}: Props) => {
  const me = useAppSelector(({app}) => app.me);
  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<CommunicationNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<CommunicationNode>(
        `${apiBaseUrl}/node/${pk}/communication-node`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: CommunicationNodeUpdateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const isChangedExternalId = fetchedData?.external_id === formik.values.external_id ? ["report_frequency", "external_id"] : ["report_frequency"];
      const payload = omit(data, isChangedExternalId);
      const endpoint = `${apiBaseUrl}/node/${pk}/communication-node`;
      const resp = await API.patch<CommunicationNode>(endpoint, payload);
      const message = `Communication Node successfully updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });

      if (fetchedData?.report_frequency !== data.report_frequency) {
        const endpointRF = `${apiBaseUrl}/node/${pk}/communication-node/report_frequency`;
        await API.patch<CommunicationNode>(endpointRF, {
          frequency: data.report_frequency
        });
        const messageRF = `Report frequency configuration message has been successfully sent`;
        enqueueSnackbar(messageRF, {
          variant: 'success',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });
      }

      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (
    item?: CommunicationNode
  ): CommunicationNodeUpdateInputBody => ({
    name: item?.name ?? null,
    status: item?.status ?? null,
    external_id: item?.external_id ?? null,
    report_frequency: item?.report_frequency ?? 60,
    zone_id: item?.zone_id ?? null,
    pos_lat: item?.pos_lat ?? null,
    pos_long: item?.pos_long ?? null,
  });

  const formik = useFormik<CommunicationNodeUpdateInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: communicationNodeUpdateInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (isActiveModal && locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.pos_lat !== newPosLat ||
        formik.values.pos_long !== newPosLong
      ) {
        formik.setFieldValue('pos_lat', newPosLat);
        formik.setFieldValue('pos_long', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name ?? ''}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.external_id ?? ''}
          label="Network ID"
          size="small"
          name="external_id"
          type="number"
          fullWidth
          error={!!formik.touched.external_id && !!formik.errors.external_id}
          helperText={formik.touched.external_id && formik.errors.external_id}
          inputProps={{min: 1, step: 1}}
          onChange={(e) =>
            formik.setFieldValue(
              e.target.name,
              e.target.value ? Math.abs(+e.target.value) : null
            )
          }
        />
        <TextField
          value={formik.values.report_frequency ?? ''}
          label="Report Frequency (s)"
          size="small"
          type="number"
          disabled={!getHasPermission(me?.permissions ?? [], ['patch::/node/:id/communication-node/report_frequency'])}
          fullWidth
          error={!!formik.touched.report_frequency && !!formik.errors.report_frequency}
          helperText={formik.touched.report_frequency && formik.errors.report_frequency}
          inputProps={{min: 1, step: 1}}
          onChange={(e) => {
            formik.setFieldValue('report_frequency', e.target.value);
          }}
        />
        {fetchedData?.external_id !== formik.values.external_id ? (
          <Alert severity="warning">
            You try to update Network ID, all existed events will unrelated from
            current node.
          </Alert>
        ) : null}
        <ZoneSelect
          value={formik.values.zone_id}
          label="Section"
          size="small"
          fullWidth
          error={!!formik.touched.zone_id && !!formik.errors.zone_id}
          helperText={formik.touched.zone_id && formik.errors.zone_id}
          onChange={(v) => formik.setFieldValue('zone_id', v)}
        />
        <TextField
          value={formik.values.pos_lat ?? ''}
          label="Latitude (click on map or enter manually)"
          size="small"
          name="pos_lat"
          type="number"
          fullWidth
          error={!!formik.touched.pos_lat && !!formik.errors.pos_lat}
          helperText={formik.touched.pos_lat && formik.errors.pos_lat}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.pos_long ?? ''}
          label="Longitude (click on map or enter manually)"
          size="small"
          name="pos_long"
          type="number"
          fullWidth
          error={!!formik.touched.pos_long && !!formik.errors.pos_long}
          helperText={formik.touched.pos_long && formik.errors.pos_long}
          onChange={formik.handleChange}
        />
      </Box>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          mode === 'update_from_info' ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : (
            <Button onClick={() => onCancel()}>Cancel</Button>
          )
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunicationNodeItemUpdate;
