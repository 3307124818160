import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import {t} from 'i18next';
import {useEffect, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  SentroGatewayItem,
  SentroGatewayListResponse,
} from '../../../interfaces/SentroGateway';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import SentroGatewayItemUpsertButton from '../../sentro_gateway/buttons/SentroGatewayItemUpsertButton';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {SentroGatewayTabs} from './SentroGatewayTabs';

interface Props {
  value?: DashboardPanelData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SentroGateway = ({value}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<SentroGatewayItem[]>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [activeId, setActiveId] = useState<number | null>(() => null);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<SentroGatewayListResponse>(
        `${apiBaseUrl}/gateway`,
        {}
      );
      setFetchedData(resp.data.items);
    } catch (error: any) {
      setFetchedErrors(error.response.data?.errors ?? []);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnDeleted = () => {
    setActiveId(null);
    fetchData();
  };

  const [panel] = usePanel();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <ButtonGroup
        sx={{
          position: 'absolute',
          top: 4,
          left: 4,
          p: 1,
        }}
      >
        <SentroGatewayItemUpsertButton
          component={Button}
          componentProps={{
            color: 'primary',
            size: 'small',
          }}
          onSubmitted={() => fetchData()}
        >
          <AddIcon />
        </SentroGatewayItemUpsertButton>

        <Button onClick={fetchData}>
          <RefreshIcon />
        </Button>
      </ButtonGroup>

      <Box width="100%" height="100%" sx={{pt: 0}}>
        {fetchedData ? (
          <SentroGatewayTabs
            value={fetchedData}
            activeId={activeId}
            setActiveId={setActiveId}
            onDeleted={handleOnDeleted}
          />
        ) : (
          <Box
            height="100%"
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            No data
          </Box>
        )}
      </Box>
    </Box>
  );
};
