export const beltAddressMask = 0x0fffff;
export const BeltMonitoringNodeList = [
  {
  id: 60,
  type: "belt",
  mc2_flag: null,
  name: "#1 Belt",
  status: 'active' as 'active' | 'inactive' | null,
  atest: null,
  checked_in: null,
  date_checked_in: "2022-10-24 08:36:31",
  company_id: 1,
  employee_asset_id: null,
  external_id: null,
  commtrac_external_id: 51,
  commtrac_date: "2024-08-07 01:12:39",
  commtrac_battery_voltage: 112,
  commtrac_alarm: 0 as 0 | 1,
  commtrac_pos_lon: "-80.944232",
  commtrac_pos_lat: "38.320679",
  commtrac_error_ellipse_major_radius: 61,
  commtrac_error_ellipse_minor_radius: 61,
  commtrac_error_ellipse_orientation: 0,
  commtrac_beacon_data: [{nodeID: 303123,rssi: -58}],
  commtrac_on_surface: 0,
  commtrac_current_zone_id: 144,
  commtrac_current_zone_name: "Underground",
  commtrac_strongest_cn: "303123",
  commtrac_strongest_cn_name: "#1 KVA #1 Belt Line",
  commtrac_report_frequency: 10,
  commtrac_ack: 1,
  checked_out_location_count: 0,
  activated_at: null,
  deactivated_at: null,
  location_frequency: null,
  cam_monitor_battery: true,
  has_motion_sensor: null,
  motion_sensor_value: null,
  sensors_number: null,
  low_battery_notified: null,
  sensor: null,
  sensor2: null,
  units: null,
  units2: null,
  value: null,
  value2: null,
  input_bits: "0000000000101000",
  output_bits: "0011001010000000",
  belt_status: "Auto Stopped",
  speed: 0,
  motor1: 0,
  motor2: 0,
  inputs: 0,
  running: 0,
  misk_ouput_1_on: false,
  misk_ouput_2_on: false,
  misk_ouput_3_on: true,
  misk_ouput_4_on: true,
  input_faults: "Remote Switch Fault, Sequence Fault",
  output_faults: "Misc Output 3 On, Misc Output 4 On",
  output_status: "Output(s) On",
  output_status_text: "Misc Output 3 On, Misc Output 4 On",
  event_601: false,
  event_602: true,
  event_603: false,
  event_604: false,
  event_605: false,
  event_606: true,
  config_failed: false,
  event_607: false,
  event_610: false,
  event_611: true,
  event_632: true,
  heartbeat_type: "",
  e_input: "0000000000101000",
  output: "0011001010000000",
  chat: true,
  msg_text: null,
  msg_conf: null,
  msg_broadcast: null,
  name_dropdown: "#1 Belt (#51)"
  },
  {
    id: 94,
    type: "belt",
    mc2_flag: null,
    name: "#3 DRIVE",
    status: 'active' as 'active' | 'inactive' | null,
    atest: null,
    checked_in: null,
    date_checked_in: "2020-05-12 09:00:12",
    company_id: 1,
    employee_asset_id: null,
    external_id: null,
    commtrac_external_id: 20,
    commtrac_date: "2024-08-16 12:52:41",
    commtrac_battery_voltage: 112,
    commtrac_alarm: 0 as 0 | 1,
    commtrac_pos_lon: "-87.532554",
    commtrac_pos_lat: "33.867924",
    commtrac_error_ellipse_major_radius: 61,
    commtrac_error_ellipse_minor_radius: 61,
    commtrac_error_ellipse_orientation: 0,
    commtrac_beacon_data: [{nodeID: 300383,rssi: -68}],
    commtrac_on_surface: 0,
    commtrac_current_zone_id: 144,
    commtrac_current_zone_name: "Underground",
    commtrac_strongest_cn: "300383",
    commtrac_strongest_cn_name: "14-60 3 belt",
    commtrac_report_frequency: 60,
    commtrac_ack: 0,
    checked_out_location_count: 0,
    activated_at: "2020-05-21 07:23:37",
    deactivated_at: null,
    location_frequency: null,
    cam_monitor_battery: true,
    has_motion_sensor: null,
    motion_sensor_value: null,
    sensor: null,
    sensor2: null,
    units: null,
    units2: null,
    value: null,
    value2: null,
    low_battery_notified: null,
    input_bits: "0000000000100000",
    output_bits: "0000000010000000",
    belt_status: "Auto Stopped",
    sensors_number: null,
    speed: 0,
    motor1: 0,
    motor2: 0,
    inputs: 0,
    running: 0,
    misk_ouput_1_on: false,
    misk_ouput_2_on: false,
    misk_ouput_3_on: true,
    misk_ouput_4_on: true,
    input_faults: "Sequence Fault",
    output_faults: "Misc Output 3 On, Misc Output 4 On",
    output_status: "All Outputs Off",
    output_status_text: "Misc Output 3 On, Misc Output 4 On",
    event_601: false,
    event_602: true,
    event_603: false,
    event_604: false,
    event_605: false,
    event_606: true,
    config_failed: false,
    event_607: false,
    event_610: false,
    event_611: true,
    event_632: true,
    heartbeat_type: "",
    e_input: "0000000000101000",
    output: "0011001010000000",
    chat: true,
    msg_text: null,
    msg_conf: null,
    msg_broadcast: null,
    name_dropdown: "#1 Belt (#51)"
  }
];

type Beacon = {
  nodeID: number;
  rssi: number;
}

type ConfirmMessage = {
  id: number;
  answer: string | null;
  status: string | null;
  message: string | null;
  message_type: number;
  date: string | null;
  date_ack: string | null;
  date_read: string | null;
}


export interface BeltMonitoringNode {
  id: number;
  type: string;
  mc2_flag: boolean | null;
  name: string;
  status: 'active' | 'inactive' | null;
  atest: 0 | 1 | null;
  checked_in: 0 | 1 | null;
  date_checked_in: string | null;
  company_id: number;
  employee_asset_id: number | null;
  external_id: number | null;
  commtrac_external_id: number;
  commtrac_date: string | null;
  commtrac_battery_voltage: number | null;
  commtrac_alarm: 0 | 1;
  latitude: number | null;
  longitude: number | null;
  commtrac_error_ellipse_major_radius: number | null;
  commtrac_error_ellipse_minor_radius: number | null;
  commtrac_error_ellipse_orientation: number | null;
  commtrac_beacon_data: Beacon[] | [];
  commtrac_on_surface: number | null;
  zone_id: number | null;
  commtrac_current_zone_name: string | null;
  commtrac_strongest_cn: string | null;
  commtrac_strongest_cn_name: string | null;
  reporting_frequency: number | null;
  acknowledged: 1 | 0;
  checked_out_location_count: number | null;
  activated_at: string | null;
  deactivated_at: string | null;
  location_frequency: number | null;
  cam_monitor_battery: boolean | null;
  has_motion_sensor: boolean | null;
  motion_sensor_value: number | null;
  sensors_number: number | null;
  low_battery_notified: boolean | null;
  sensor: number | null;
  sensor2: number |null;
  units: string | null;
  units2: string | null;
  value: number | null;
  value2: number |null;
  input_bits: string | null;
  output_bits: string | null;
  belt_status: string | null;
  speed: number | null;
  motor1: number | null;
  motor2: number | null;
  inputs: number | null;
  running: number | null;
  run_status: number | null;
  misk_ouput_1_on: boolean | null;
  misk_ouput_2_on: boolean | null;
  misk_ouput_3_on: boolean | null;
  misk_ouput_4_on: boolean | null;
  input_faults: string | null;
  output_faults: string | null;
  output_status: string | null;
  output_status_text: string | null;
  e_601: boolean;
  e_602: boolean;
  e_603: boolean;
  e_604: boolean;
  e_605: boolean;
  e_606: boolean;
  config_failed: boolean;
  e_607: boolean;
  e_610: boolean;
  e_611: boolean;
  e_632: boolean;
  heartbeat_type: string | null;
  e_input: string | null;
  output: string | null;
  chat: boolean | null;
  msg_text: boolean | null;
  msg_conf: string | null;
  msg_broadcast: boolean | null;
  name_dropdown: string | null;
  configuration_message: ConfirmMessage | {};
}

export interface BeltMonitoringNodeListQuery {
  page: number;
  limit: number;
  standby: boolean;
  status: 'active' | 'inactive' | 'unacknowledged' | 'all';
  section_ids: number[];
}

export interface BeltMonitoringNodeListResponse {
  current_page: number;
  items_per_page: number;
  pages: number;
  count: number;
  total: {
    all: number;
    active: number;
    inactive: number;
    unacknowledged: number;
  };
  items: BeltMonitoringNode[];
}
