import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';


type commtracNodeObj = {
  id: number,
  checked_in: number | null | undefined
  commtrac_ack: string | null | undefined
  type: string | null | undefined
}

interface Props extends Omit<DialogProps, 'onClose'> {
  ids: commtracNodeObj[];
  onSubmitted?: () => void;
  onClose?: () => void;
}

const CommtracNodeItemAssetMinerCheckInOutModal = ({
  ids,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionName = `${ids.length === ids.filter((it) => it.checked_in).length ? 'Checked Out' : 'Checked In'}`;
  const checkInOutIdsAsset = {id: ids.filter((it) => it.type === 'asset' && it.commtrac_ack !== "0").map((it) => it.id)};
  const checkInOutIdsMiner = {id: ids.filter((it) => it.type === 'miner' && it.commtrac_ack !== "0").map((it) => it.id)};
  const submit = async () => {
    setIsSubmitting(true);
    try {
      const checkInIds = {id: ids.filter((it) => !it.checked_in && it.commtrac_ack !== "0").map((it) => it.id)};
      const checkOutIds = {id: ids.filter((it) => it.checked_in && it.commtrac_ack !== "0").map((it) => it.id)};

      const endpointCheckInAsset = `${apiBaseUrl}/commtrac-node/force_check_in`;
      if (checkInIds.id.length > 0) {
        await API.patch(endpointCheckInAsset, checkInIds);
      }
      const endpointCheckOutAsset = `${apiBaseUrl}/commtrac-node/force_check_out`;
      if (checkOutIds.id.length > 0) {
        await API.patch(endpointCheckOutAsset, checkOutIds);
      }

      let message = null;
      if (ids.length === 1) {
        if (ids[0].type === 'miner') {
          message = `Employee successfully Force ${actionName}`;
        } else if (ids[0].type === 'asset') {
          message = `Employee successfully Force ${actionName}`;
        }
      } else if (checkInOutIdsAsset.id.length === 0) {
        message = `Employees successfully Force ${actionName}`;
      } else if (checkInOutIdsMiner.id.length === 0) {
        message = `Machines successfully Force ${actionName}`;
      } else {
        message = `Commtrac Nodes  successfully Force ${actionName}`;
      }
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>
        {actionName}
        {ids.length === 1
            ? ids[0].type === 'miner'
              ? ' Employee'
              : ' Machine'
            : checkInOutIdsAsset.id.length === 0
              ? ' Employees'
              : checkInOutIdsMiner.id.length === 0
                ? ' Machines'
                : ' Commtrac nodes'
        }
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName} {ids.length === 1 ? 'this' : 'these '}
          {ids.length === 1
            ? ids[0].type === 'miner'
              ? ' employee'
              : ' machine'
            : checkInOutIdsAsset.id.length === 0
              ? ' employees'
              : checkInOutIdsMiner.id.length === 0
                ? 'machines'
                : 'commtrac nodes'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit()}
          color="warning"
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommtracNodeItemAssetMinerCheckInOutModal;
