import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import AccessControl from '../../common/AccessControl';
import { getHasPermission } from '../../common/AccessControl/utils';
import EmployeeItemsPurgeChatEventsButton from '../../employees/buttons/EmployeeItemsPurgeChatEventsButton';
import CommtracNodeItemAssetMinerCheckInOutModal from '../CommtracNodeItemAssetMinerCheckInOutModal';
import CommtracNodeItemsPurgeEventsButton from './CommtracNodeItemsPurgeEventsButton';
import CommtracNodeItemsPurgeNodeButton from './CommtracNodeItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

type commtracNodeObj = {
  id: number,
  checked_in: number | null | undefined,
  commtrac_ack: string | null | undefined,
  type: string | undefined,
  status: 'active' | 'inactive' | null | undefined;
}

type Action =
  'checkInOut'
  | 'checkIn'
  | 'checkOut';

interface Props<T extends ComponentType> {
  ids: commtracNodeObj[];
  purgeChatEnabled?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const CommtracNodeItemsPurgeButton = <T extends ComponentType = typeof Button>({
  ids,
  purgeChatEnabled = true,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const me = useAppSelector(({app}) => app.me);
  const Component = component ?? Button;
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;
  const [action, setAction] = useState<Action>();

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        {getHasPermission(me?.permissions ?? [], ['post::/purge']) && (
          <>
            <CommtracNodeItemsPurgeNodeButton
              ids={ids.map((it: any) => it.id)}
              component={MenuItem}
              onDone={() => {
                setMenuAnchorEl(undefined);
                onDone?.();
              }}
            >
              All
            </CommtracNodeItemsPurgeNodeButton>

            <CommtracNodeItemsPurgeEventsButton
              ids={ids.map((it: any) => it.id)}
              component={MenuItem}
              onDone={() => {
                setMenuAnchorEl(undefined);
                onDone?.();
              }}
            >
              Transactions
            </CommtracNodeItemsPurgeEventsButton>
          </>
        )}

        {(purgeChatEnabled && getHasPermission(me?.permissions ?? [], ['post::/purge'])) ? (
          <EmployeeItemsPurgeChatEventsButton
            commtrac_node_ids={ids.filter((it) => it.type === "miner").map((it: any) => it.id)}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onDone?.();
            }}
          >
            Purge Chat
          </EmployeeItemsPurgeChatEventsButton>
        ) : null}
        {ids.every(it => it.status === 'active' && !it.checked_in && it.commtrac_ack !== "0") && (
          <AccessControl
            permissions={['patch::/commtrac-node/force_check_in']}
          >
            <MenuItem
              onClick={() => {
                setAction('checkInOut');
                setMenuAnchorEl(undefined);
              }}
            >
              Force Check In
            </MenuItem>
          </AccessControl>
        )}
        {ids.every(it => it.status === 'active' && it.checked_in && it.commtrac_ack !== "0") && (
          <AccessControl
            permissions={['patch::/commtrac-node/force_check_out']}
          >
            <MenuItem
              onClick={() => {
                setAction('checkInOut');
                setMenuAnchorEl(undefined);
              }}
            >
              Force Check Out
            </MenuItem>
          </AccessControl>
        )}
      </Menu>

      <CommtracNodeItemAssetMinerCheckInOutModal
        ids={ids}
        open={!!action}
        onClose={() => setAction(undefined)}
        onSubmitted={onDone}
      />
    </>
  );
};

export default CommtracNodeItemsPurgeButton;
