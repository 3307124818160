import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Button, Divider, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import {UseGetLabel} from '../../hooks/label';
import {AssetMachine} from '../../interfaces/AssetMachine';
import AccessControl from '../common/AccessControl';
import ModalDraggable from '../common/ModalDraggable';
import AssetMachineItemUpsert from './AssetMachineItemUpsert';
import AssetMachineItemsPurgeButton from './buttons/AssetMachineItemsPurgeButton';
import AssetMachineItemsPurgeEventsButton from './buttons/AssetMachineItemsPurgeEventsButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: AssetMachine;
  mode?: 'view' | 'update' | 'chat';
  isAdminSection?: boolean;
  prefetch?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: () => void;
  onOpen?: () => void;
  onClose?: Function;
}

const AssetMachineItemEditButton = <T extends ComponentType = typeof Button>({
  pk,
  item,
  mode,
  isAdminSection = false,
  prefetch,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
  onOpenHistory,
  //onOpen,
  onClose,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;
  const [isOpened, setIsOpened] = useState(false);
  const ids = useMemo(() => {
    return item?.id ? [item?.id] : [];
  }, [item]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem
          onClick={() => {
            setIsOpened(true);
            setMenuAnchorEl(undefined);
          }}
        >
          <EditIcon fontSize="small" sx={{mr: 1.5}} />
          {`Edit ${UseGetLabel({label: 'machine'})}`}
        </MenuItem>
        <AccessControl permissions={['post::/purge']}>
          <Divider />
          <AssetMachineItemsPurgeButton
            ids={ids}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onSubmitted?.();
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 2}} />
            Purge All
          </AssetMachineItemsPurgeButton>

          <AssetMachineItemsPurgeEventsButton
            ids={ids}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onSubmitted?.();
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 2}} />
            Purge Transactions
          </AssetMachineItemsPurgeEventsButton>
        </AccessControl>
      </Menu>
      {isOpened ? (
        <ModalDraggable open={isOpened}>
          <AssetMachineItemUpsert
            pk={pk}
            item={item}
            mode={mode}
            isAdminSection={isAdminSection}
            prefetch={prefetch}
            onClose={() => {
              setIsOpened(false);
              onClose?.();
            }}
            onSubmitted={() => {
              onSubmitted?.();
              if (!pk) {
                setIsOpened(false);
                onClose?.();
              }
            }}
            onPurged={onPurged}
            onOpenHistory={onOpenHistory}
          />
        </ModalDraggable>
      ) : null}
    </>
  );
};

export default AssetMachineItemEditButton;
