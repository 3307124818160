export const nameValidator = {
  regex: /^[а-яА-ЯёЁӘәҒғҚқӨөҰұҺһІіa-zA-Z0-9_.:' \-#\(\)]+$/,
  message:
    "%1 field should only contain letters, numbers, spaces and special characters: :.'-_#()",
};

export const macAddressValidator = {
  regex: /^([0-9A-Fa-f]{2}[:-]){5}[0-9A-Fa-f]{2}$/,
  message: 'Please provide a valid %1',
};

export const maxLengthValidator = {
  message: 'Please provide a valid %1',
};

export const timeValidator = {
  regex: /^\d{2}:\d{2}:00$/,
  message: 'Invalid hour format',
};

export const maxProximityId = 4294967295;
export const maxNetworkId = 8388607;
export const maxPersonIdLength = 30;
export const maxNameLength = 50;
export const maxDescriptionLength = 255;
