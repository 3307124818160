import {AlarmOutlined} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import {closeSnackbar, enqueueSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {AlarmLogNode} from '../../interfaces/AlarmLogNode';
import {EventSummaryResent} from '../../interfaces/Event';
import reduxActions from '../../redux/actions';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  item?: EventSummaryResent;
  onSubmitted?: () => void;
}

const AlarmSimpleAcknowledgePopup = ({item, onSubmitted}: Props) => {
  const reduxDispatch = useAppDispatch();
  /**********/
  /* submit */
  /**********/

  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const submitAck = async () => {
    setSubmittedInProgress(true);
    try {
      const requestBody = {
        ids: {
          alarm: [item?.id],
        },
      };
      const endpoint = `${apiBaseUrl}/event/acknowledge`;
      await API.patch<AlarmLogNode>(endpoint, requestBody);
      const message = `Event was successfully updated!`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
      reduxDispatch(reduxActions.assets.fetchAlarms);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  return (
    <Box p={3}>
      <Box>
        <Box display="flex" alignItems="center">
          <AlarmOutlined
            sx={{
              fontSize: '32px',
              marginRight: '10px',
            }}
            color={
              item?.type === 703
                ? 'error'
                : item?.type === 704
                  ? 'warning'
                  : 'disabled'
            }
          />
          <Box fontSize="16px" lineHeight="25px">
            {item?.type === 703 ? 'Alarm' : 'Warning'} activated on "
            {item?.name}" at {item?.date}.
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <LoadingButton
            variant="contained"
            type="button"
            sx={{
              fontSize: '12px',
            }}
            onClick={() => submitAck()}
            loading={submittedInProgress}
          >
            Acknowledge
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AlarmSimpleAcknowledgePopup;
