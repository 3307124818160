export const getIsWifiEnabledVisible = (isCommtrac: boolean | null) => {
  return !!isCommtrac;
};

export const getIsMacAddressVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !!wifiEnabled;
};

export const getIsMinVoltageVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !!wifiEnabled;
};

export const getIsMaxVoltageVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !!wifiEnabled;
};

export const getIsCommtracExternalIdVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !wifiEnabled;
};

export const getIsReportFreqIdVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !wifiEnabled;
};

export const getIsEmployeeMc2FlagVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !wifiEnabled;
};

export const getIsEmployeeAtestVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !wifiEnabled;
};

export const getIsEmployeeSensorNumberVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null,
  atest: 0 | 1 | null
) => {
  return !!isCommtrac && !wifiEnabled && !!atest;
};

export const getIsEmployeeHasMotionSensorVisible = (
  isCommtrac: boolean | null,
  wifiEnabled: boolean | null
) => {
  return !!isCommtrac && !wifiEnabled;
};

export const getIsProximityFieldsVisible = (
  assign_to_machine: boolean | null,
  is_proximity_enabled: boolean,
  proximity_enabled: boolean | undefined
) => {
  return proximity_enabled && !assign_to_machine && is_proximity_enabled;
};

export const getIsHazardAIFieldsVisible = (
  hazard_ai_enabled: boolean | undefined
) => {
  return hazard_ai_enabled;
};

export enum MINER_EVENT_TYPES {
  MINER_ALARM = 201,
  MINER_NO_LOCATION = 202,
  MINER_LOW_BATTERY = 203,
  MINER_NEW_MESSAGE = 204,
  MINER_EMERGENCY_RESPONSE = 205,
  MINER_TEXT_RESPONSE = 206,
  MINER_CHECK_IN = 207,
  MINER_CHECK_OUT = 208,
  MINER_NEW_MINER_DETECTED = 209,
  MINER_TOO_MANY_LOCATION_MESSAGES = 221,
  MINER_CONFIGURATION_FAILED = 222,
  MINER_LOW_BATTERY_CHAT_MESSAGE_UNACKED = 223,
  MINER_LOW_BATTERY_CHAT_MESSAGE_UNREAD = 224,
  MINER_LOW_BATTERY_CHAT_MESSAGE_READ = 225,
  MINER_INVALID_LAST_LOCATION = 226,
  MINER_CONFIGURATION_ACK = 232,
  MINER_NOT_MOVING_STATE = 233,
}

export enum ASSET_EVENT_TYPES {
  ASSET_NO_LOCATION = 301,
  ASSET_LOW_BATTERY = 302,
  ASSET_NEW_ASSET_DETECTED = 309,
  ASSET_TOO_MANY_LOCATION_MESSAGES = 321,
  ASSET_CONFIGURATION_FAILED = 322,
  ASSET_INVALID_LAST_LOCATION = 326,
  ASSET_CONFIGURATION_ACK = 332,
}

export const MINER_EVENT_SOUNDS: {
  [value in MINER_EVENT_TYPES]: string | null;
} = {
  [MINER_EVENT_TYPES.MINER_ALARM]: 'commtrac.sound_miner_alarm',
  [MINER_EVENT_TYPES.MINER_NO_LOCATION]: 'commtrac.sound_miner_no_location',
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY]: 'commtrac.sound_miner_low_battery',
  [MINER_EVENT_TYPES.MINER_NEW_MESSAGE]: 'commtrac.sound_miner_new_message',
  [MINER_EVENT_TYPES.MINER_EMERGENCY_RESPONSE]: null,
  [MINER_EVENT_TYPES.MINER_TEXT_RESPONSE]: null,
  [MINER_EVENT_TYPES.MINER_CHECK_IN]: null,
  [MINER_EVENT_TYPES.MINER_CHECK_OUT]: null,
  [MINER_EVENT_TYPES.MINER_NEW_MINER_DETECTED]:
    'commtrac.sound_miner_new_detected',
  [MINER_EVENT_TYPES.MINER_TOO_MANY_LOCATION_MESSAGES]:
    'commtrac.sound_miner_many_location',
  [MINER_EVENT_TYPES.MINER_CONFIGURATION_FAILED]:
    'commtrac.sound_miner_config_failed',
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY_CHAT_MESSAGE_UNACKED]: null,
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY_CHAT_MESSAGE_UNREAD]: null,
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY_CHAT_MESSAGE_READ]: null,
  [MINER_EVENT_TYPES.MINER_INVALID_LAST_LOCATION]:
    'commtrac.sound_miner_location_derived',
  [MINER_EVENT_TYPES.MINER_CONFIGURATION_ACK]: null,
  [MINER_EVENT_TYPES.MINER_NOT_MOVING_STATE]:
    'commtrac.sound_miner_not_moving_state',
};

export const ASSET_EVENT_SOUNDS: {
  [value in ASSET_EVENT_TYPES]: string | null;
} = {
  [ASSET_EVENT_TYPES.ASSET_NO_LOCATION]: 'commtrac.sound_asset_no_location',
  [ASSET_EVENT_TYPES.ASSET_LOW_BATTERY]: 'commtrac.sound_asset_low_battery',
  [ASSET_EVENT_TYPES.ASSET_NEW_ASSET_DETECTED]:
    'commtrac.sound_asset_new_detected',
  [ASSET_EVENT_TYPES.ASSET_TOO_MANY_LOCATION_MESSAGES]:
    'commtrac.sound_asset_many_location',
  [ASSET_EVENT_TYPES.ASSET_CONFIGURATION_FAILED]: null,
  [ASSET_EVENT_TYPES.ASSET_INVALID_LAST_LOCATION]:
    'commtrac.sound_asset_location_derived',
  [ASSET_EVENT_TYPES.ASSET_CONFIGURATION_ACK]: null,
};

export const MINER_EVENT_COLORS: {
  [value in MINER_EVENT_TYPES]: string | null;
} = {
  [MINER_EVENT_TYPES.MINER_ALARM]: 'commtrac-color.color_miner_node_alarm',
  [MINER_EVENT_TYPES.MINER_NO_LOCATION]:
    'commtrac-color.color_miner_node_no_location',
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY]:
    'commtrac-color.color_miner_node_low_battery',
  [MINER_EVENT_TYPES.MINER_NEW_MESSAGE]:
    'commtrac-color.color_miner_node_new_message',
  [MINER_EVENT_TYPES.MINER_EMERGENCY_RESPONSE]: null,
  [MINER_EVENT_TYPES.MINER_TEXT_RESPONSE]: null,
  [MINER_EVENT_TYPES.MINER_CHECK_IN]: null,
  [MINER_EVENT_TYPES.MINER_CHECK_OUT]: null,
  [MINER_EVENT_TYPES.MINER_NEW_MINER_DETECTED]:
    'commtrac-color.color_miner_node_new_detected',
  [MINER_EVENT_TYPES.MINER_TOO_MANY_LOCATION_MESSAGES]:
    'commtrac-color.color_miner_node_many_location',
  [MINER_EVENT_TYPES.MINER_CONFIGURATION_FAILED]: null,
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY_CHAT_MESSAGE_UNACKED]: null,
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY_CHAT_MESSAGE_UNREAD]: null,
  [MINER_EVENT_TYPES.MINER_LOW_BATTERY_CHAT_MESSAGE_READ]: null,
  [MINER_EVENT_TYPES.MINER_INVALID_LAST_LOCATION]:
    'commtrac-color.color_miner_node_location_derived',
  [MINER_EVENT_TYPES.MINER_CONFIGURATION_ACK]: null,
  [MINER_EVENT_TYPES.MINER_NOT_MOVING_STATE]:
    'commtrac-color.color_miner_not_moving_state',
};

export const ASSET_EVENT_COLORS: {
  [value in ASSET_EVENT_TYPES]: string | null;
} = {
  [ASSET_EVENT_TYPES.ASSET_NO_LOCATION]:
    'commtrac-color.color_asset_node_no_location',
  [ASSET_EVENT_TYPES.ASSET_LOW_BATTERY]:
    'commtrac-color.color_asset_node_low_battery',
  [ASSET_EVENT_TYPES.ASSET_NEW_ASSET_DETECTED]:
    'commtrac-color.color_asset_node_new_detected',
  [ASSET_EVENT_TYPES.ASSET_TOO_MANY_LOCATION_MESSAGES]:
    'commtrac-color.color_asset_node_many_location',
  [ASSET_EVENT_TYPES.ASSET_CONFIGURATION_FAILED]: null,
  [ASSET_EVENT_TYPES.ASSET_INVALID_LAST_LOCATION]:
    'commtrac-color.color_asset_node_location_derived',
  [ASSET_EVENT_TYPES.ASSET_CONFIGURATION_ACK]: null,
};

export const getTagsBatteryPercentage = (batteryVoltage: number) => {
  const value = batteryVoltage / 10;
  if (value >= 4.5) {
    return 100;
  } else if (value >= 4.4) {
    return 90;
  } else if (value >= 4.3) {
    return 80;
  } else if (value >= 4.2) {
    return 70;
  } else if (value >= 4) {
    return 60;
  } else if (value >= 3.9) {
    return 50;
  } else if (value >= 3.8) {
    return 40;
  } else if (value >= 3.7) {
    return 30;
  } else if (value >= 3.5) {
    return 20;
  } else if (value >= 3.1) {
    return 10;
  }
  return 0;
};

export const getCamBatteryPercentage = (batteryVoltage: number) => {
  const value = batteryVoltage / 10;
  if (value >= 4.1) {
    return 100;
  } else if (value >= 4.0) {
    return 80;
  } else if (value >= 3.8) {
    return 60;
  } else if (value >= 3.7) {
    return 40;
  } else if (value >= 3.6) {
    return 20;
  } else if (value >= 3.5) {
    return 10;
  }
  return 0;
};

export const getAtestBatteryPercentage = (batteryVoltage: number) => {
  const value = batteryVoltage / 10;
  if (value >= 4) {
    return 100;
  } else if (value >= 3.9) {
    return 80;
  } else if (value >= 3.7) {
    return 60;
  } else if (value >= 3.5) {
    return 40;
  } else if (value >= 3.3) {
    return 20;
  } else if (value >= 3.1) {
    return 10;
  }
  return 0;
};

export const getMc2BatteryPercentage = (batteryVoltage: number) => {
  const value = batteryVoltage / 10;
  if (value >= 4.1) {
    return 100;
  } else if (value >= 3.9) {
    return 80;
  } else if (value >= 3.7) {
    return 60;
  } else if (value >= 3.5) {
    return 40;
  } else if (value >= 3.4) {
    return 20;
  } else if (value >= 3.3) {
    return 10;
  }
  return 0;
};

export const getMinerBatteryPercentage = (batteryVoltage: number) => {
  const value = batteryVoltage / 10;
  if (value >= 5.1) {
    return 100;
  } else if (value >= 5) {
    return 80;
  } else if (value >= 4.9) {
    return 60;
  } else if (value >= 4.8) {
    return 40;
  } else if (value >= 4.7) {
    return 20;
  } else if (value >= 4.6) {
    return 10;
  }
  return 0;
};

export const getAssetBatteryPercentage = (batteryVoltage: number) => {
  const value = batteryVoltage / 10;
  if (value >= 6) {
    return 100;
  } else if (value >= 5.8) {
    return 90;
  } else if (value >= 5.7) {
    return 80;
  } else if (value >= 5.6) {
    return 70;
  } else if (value >= 5.4) {
    return 60;
  } else if (value >= 5.3) {
    return 50;
  } else if (value >= 5.1) {
    return 40;
  } else if (value >= 5) {
    return 30;
  } else if (value >= 4.7) {
    return 20;
  } else if (value >= 4.5) {
    return 10;
  }
  return 0;
};

export const getWifiBatteryPercentage = (
  batteryVoltage: number,
  minVoltage: number,
  maxVoltage: number
) => {
  return Math.round(
    ((batteryVoltage - minVoltage) / (maxVoltage - minVoltage)) * 100
  );
};

export enum EmployeeEmergencyResponse {
  OK = 'Employee OK',
  INJURED_BUT_OK = 'Employee injured, but OK',
  INJURED_AND_NEEDS_HELP = 'Employee injured and needs HELP',
}
