import {Box, Modal} from '@mui/material';

import SentroGatewayItemUpsert from './SentroGatewayItemUpsert';

interface Props {
  pk?: any;
  item?: any;
  prefetch?: boolean;
  onSubmitted?: () => void;
  onClose?: () => void;
  onPurged?: () => void;
  onOpenHistory?: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  overflow: 'auto',
  maxHeight: '100%',
};

const SentroGateWayItemUpsertModal: React.FC<Props> = ({
  onSubmitted,
  onClose,
}) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={style}>
        <SentroGatewayItemUpsert
          onCancel={onClose}
          onSubmitted={() => {
            onSubmitted?.();
            onClose?.();
          }}
        />
      </Box>
    </Modal>
  );
};

export default SentroGateWayItemUpsertModal;
