/* eslint-disable @typescript-eslint/no-unused-vars */
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Box, Button, IconButton, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import BeltNodeItemPurgeModal from '../../belt-node/BeltNodeItemPurgeModal';
import AccessControl from '../../common/AccessControl';
import { getHasPermission } from '../../common/AccessControl/utils';
import BeltNodeItemActivationModal from './BeltNodeItemActivationModal';
type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: BeltMonitoringNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onClose?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'beltHistoryReport' | 'beltLocationHistoryReport'
  ) => void;
}

const PurgeActionTypes = {
  purgeDevice: 'device',
  purgeData: 'data',
} as const;

type Action =
  | 'checkin'
  | 'activation'
  | 'purgeDevice'
  | 'purgeData'
  | 'purgeChat'
  | 'checkInOut';

const BeltNodeItemActionsButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
  onOpenHistory,
  onClose,
}: Props<T>) => {
  const me = useAppSelector(({app}) => app.me);
  const [action, setAction] = useState<Action>();
  const isPurgeAction = action === 'purgeDevice' || action === 'purgeData';
  const purgeType = isPurgeAction ? PurgeActionTypes[action] : null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [anchorElMore, setAnchorElMore] = useState<null | HTMLElement>(null);
  const menuOpenMore = Boolean(anchorElMore);

  return (
    <>
      <Box display="flex" justifyContent="start" gap={0.5}>
        <Box position="relative">
          <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
            History
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onBackdropClick={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                onOpenHistory?.(item?.id, 'beltHistoryReport');
                onClose?.();
              }}
            >
              View Belt History
            </MenuItem>
            <MenuItem
              onClick={() => {
                onOpenHistory?.(item?.id, 'beltLocationHistoryReport');
                onClose?.();
              }}
            >
              View Belt Location History
            </MenuItem>
          </Menu>
        </Box>
        {(me?.type_id !== 5 ||
          getHasPermission(me.permissions, ['post::/purge']) ||
          getHasPermission(me.permissions, ['patch::/belt/:id(\\d+)'])) && (
          <IconButton
            onClick={(event) => setAnchorElMore(event.currentTarget)}
            size="small"
            color="primary"
          >
            <MoreHorizIcon />
          </IconButton>
         )}
        <Menu
          anchorEl={anchorElMore}
          open={menuOpenMore}
          onBackdropClick={() => setAnchorElMore(null)}
        >
          <AccessControl permissions={['patch::/belt/:id(\\d+)']}>
            <MenuItem
              onClick={() => {
                setAction('activation');
                setAnchorEl(null);
              }}
            >
              {item?.status === 'active' ? (
                <>
                  <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                  DeActivate
                </>
              ) : (
                <>
                  <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                  Activate
                </>
              )}
            </MenuItem>
          </AccessControl>
          <AccessControl permissions={['post::/purge']}>
            <MenuItem
              onClick={() => {
                setAction('purgeDevice');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Device
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAction('purgeData');
                setAnchorEl(null);
              }}
            >
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Transaction
            </MenuItem>
          </AccessControl>
        </Menu>
      </Box>
      {action === 'activation' ? (
        <BeltNodeItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : purgeType ? (
        <BeltNodeItemPurgeModal
          item={item}
          action={purgeType}
          open={!!purgeType}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}
    </>
  );
};

export default BeltNodeItemActionsButton;
